import React, { Component } from "react";
import ImageList from '@mui/material/ImageList';
import Box from '@mui/material/Box';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { formatSizeUnits} from "../utils/function_conversion";
import img_drag_and_drop from "../icons/9UploadDoc/img_drag_and_drop.svg"
import file_added from "../icons/9UploadDoc/fichier_added.svg"
import poubelle_icon_svg from "../icons/9UploadDoc/poubelle.svg"
import red_not_valid_icon from "../icons/9UploadDoc/red_not_valid_icon.svg"
import valid_green_icon from "../icons/connected-badge.svg"
import "./upload-files.component.css"
import UserService from "../services/user.service";
import { style } from "@mui/system";
import { State_Upload } from "../types/common.type";
import { WindowSharp } from "@mui/icons-material";
import { ButtonIsLoading } from "./organisms/Button_is_loading";
import { docu_correspondance } from "../utils/docu_correspondance_utils";
import { UtilsAfterAnalyse } from "./organisms/utilsAfterAnalyse";
import { debuggingMessage } from "../utils/debug_function";

interface Props {
  key: number,
  text_button_next_step: string,
  parrentStepper: () => void,
  previousStep: () => void,
  step:number,
  document_type: string,
  set_step_to_zero: () => void, // le type à l'air compliqué, c'est plus simple de mettre () =>
  nouvelle_analyse: () => void,
  //reload_parent_component: () => JSX.Element
  refreshTheCounter: () => void,
};

interface StateOfUploadFiles {
  code: number,
  isAnalyseResponseLoading: boolean,
  acceptedFiles: File[],
  userWantToUploadAnOtherFile: boolean,
  progressInfos: string[],
  message_from_server_after_upload: string, // message du back
  certificate: string, 
  certificat_raw_base_64: string,
  analysisID: string,
  fileInfos: string[],
  score: string | number | null,

}

const list_text_button = [
  "Analyser",
  "Analyser",
  "Télécharger mon certificat",
  //<a download="certificat.pdf">"Télécharger mon certificat"</a>, // TODO: trigger downlaod avec la base 64 qui est remontée dans le front. <a download={}></a>
  "Effectuez une nouvelle analyse"
];

export const style_for_upload_doc : React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  backgroundColor: "#fff",
  //border: "solid",
  borderRadius: "2rem",
  marginBottom: "1.5rem",
  width: "100%",
  paddingLeft:"2rem",
  paddingRight: "2rem"
};

function message_after_analysis_to_display(input: string){

  let component_to_return;

}

export default class UploadFiles extends Component<Props, StateOfUploadFiles> {
  constructor(props : Props) {
    super(props);
    
    this.selectFiles = this.selectFiles.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.setState = this.setState.bind(this)
    this.checkerUserIntent = this.checkerUserIntent.bind(this)
    this.change_loading_status = this.change_loading_status.bind(this)
    this.changeSecondUploadFileBoolean = this.changeSecondUploadFileBoolean.bind(this) // userWantToUploadAnOtherFile
    this.state = {
      isAnalyseResponseLoading: true,
      code: 0, 
      acceptedFiles: [],
      progressInfos: [],
      // message_from_server_after_upload message du back. Ce message est mis par défaut à "L'analyse a échoué..." au cas au le serveur ne répond pas correctement.
      message_from_server_after_upload: "L'analyse a échouée. Pour plus d'informations, vous pouvez contacter le support", 
      certificate: "",
      certificat_raw_base_64: "",
      analysisID: "",
      fileInfos: [], 
      score: "",
      userWantToUploadAnOtherFile: false
    };
  }

  changeSecondUploadFileBoolean(input: boolean){
    this.setState({
      userWantToUploadAnOtherFile: input
    })
  }

  fileAcceptance(files: FileList){
    // factorised logic
    // function used when the user upload files;
    // it is used when the user:
    // - drag and drop 
    // - click on the drag and drop zone


    let filesAreCorrect = this.checkFilesBeforeAnalyse(files)
                    
    if (filesAreCorrect === true){

      debuggingMessage(undefined, " length debug a  " + this.state.acceptedFiles.length)

      let fileListTemp = [...this.state.acceptedFiles]

      debuggingMessage(undefined, " length debug b " + this.state.acceptedFiles.length)
      for (var i = 0, l = files.length; i < l; i++) {
        fileListTemp.push(files[i]);
      }
      debuggingMessage(undefined, " length debug c " + this.state.acceptedFiles.length)
      
      this.checkerUserIntent(files)
      debuggingMessage(undefined, " length debug d" + this.state.acceptedFiles.length)
      this.setState({
        progressInfos: [],
        acceptedFiles: fileListTemp
      })
      debuggingMessage(undefined, " length debug e" + this.state.acceptedFiles.length)

      if (this.state.userWantToUploadAnOtherFile){
        this.props.parrentStepper()
      }
      
      debuggingMessage(undefined, "select files. Not working function.")
      debuggingMessage(undefined, this.state.userWantToUploadAnOtherFile)
    }
  }
  checkerUserIntent(files : FileList){
    // this function check if a user will be asked to upload a second document.
    //  

    // If the user uploaded just one document, the second part can be missing 
    // ex for id_document.

    const listDocumentTypesToCheck : string[]= ["titre_de_sejour","id_document"]

    debuggingMessage(undefined, " function checkerUserIntent")

    if (listDocumentTypesToCheck.includes(this.props.document_type)){

      let utilsSum = files.length  //+ this.state.acceptedFiles.length
      
      debuggingMessage(undefined, " on est dans le cas du doc " + this.state.acceptedFiles?.length)
      debuggingMessage(undefined, " on est dans le cas du doc " + this.state.acceptedFiles)
      debuggingMessage(undefined, " on est dans le cas du doc utils files dynamic " + utilsSum.toString())
      debuggingMessage(undefined, " on est dans le cas du doc utils files state " + this.state.acceptedFiles.length.toString())

      if ( utilsSum + this.state.acceptedFiles.length === 1){

        debuggingMessage(undefined,"on est dans le cas du doc dans le if check 1")

        this.changeSecondUploadFileBoolean(true) // check to a boolean, like that, the user will be asked to upload the other
        // part of the document

        // in this case this.props.parrentStepper() 
        // will be called later in order to let the user
        // upload an other file inside the upload interface
        // if he agrees.

      }else{
        this.props.parrentStepper()
      }

    }else{
      this.props.parrentStepper()
    }

    return;
  }

  change_loading_status(code: number, message_input: string, certificate: string, certificat_raw_base_64: string, analysisID: string, score: string | number | null){
    //console.log("called")
    //console.log("debug here 77", message_input, certificate)
    this.setState({
      
      isAnalyseResponseLoading: false,
      code: code,
      message_from_server_after_upload: message_input,
      certificate: certificate,
      certificat_raw_base_64: certificat_raw_base_64,
      analysisID: analysisID,
      score: score
      
    })
  }

  selectFiles(event : React.ChangeEvent<HTMLInputElement>) {

    if (event.target.files === null){
      return;
    }

    this.fileAcceptance(event.target.files)
    // checking file(s) correctness. Needed function.

  }

  checkFilesBeforeAnalyse(files :FileList) { 
    // retourne true si la liste de fichiers est conforme. false sinon. // indique également le user pk les fichiers ne sont pas
    // conforme si c'est le cas.

    /* // ne fonctionne pas
    console.log("coucou debug", this.props.step, this.state.acceptedFiles)
    if (this.props.step === 1 && this.state.acceptedFiles != null){ // si l'utilisateur a déjà drop un fichier et en drag and drop un autre.
      console.log("ici") 
      this.setState({acceptedFiles : null})
      this.props.previousStep() 
    }*/

    //let already_files_before_upload = (this.state.acceptedFiles != null) ? true : false; 
    
    // files to add to the list of files
    var count: number = files.length + this.state.acceptedFiles.length; 
    //count += this.state.acceptedFiles

    if (count >= 3){
      alert(" Pour chaque analyse, au maximum deux fichiers peuvent être utilisées. ")
        this.setState({
          progressInfos: [],
          
        })
        return false;
    }

    // checking sum files size
    /*
    let sum_size_of_files: number = 0;

    for (var i1 = 0; i1 < files.length; i1++) {
      sum_size_of_files += files[i1].size;
    }

    if (sum_size_of_files > 5000000){
      alert("Le poids total des fichiers ne doit pas dépasser 5Mo")
      this.setState({
        progressInfos: [],
        acceptedFiles: null
      })
      return false;
    }
    */

    // && files[i].size <= 2000000
    for (var i2 = 0; i2 < files.length; i2++) {
      var current_extension_file = files[i2].name.split('.').pop();



      // checking that each file has a size superior to 100000 bytes
      if (!(files[i2].size >= 100000 && files[i2].size <= 5000000)){
      
        // c'est plus rapide de vérifier dans le front la taille du fichier
        // plutot que de vérifier dans le back dans la réponse de l'API resocom (c'est l'erreur 29 et 30) puis
        // faire remonter l'erreur dans le front.
        // alert ("Le fichier " + files[i].name + " n'a pas la bonne taille. Taille détectée: "+ files[i].size+ ".Il doit être compris entre 100ko et 2Mo.")
        
        alert ("Le fichier " + files[i2].name + " n'a pas la bonne taille. Il doit avoir une taille supérieure à 100ko et inférieure à 5Mo.")
        this.setState({
          progressInfos: [],
          acceptedFiles: []
        })
        return false;
      }
      
      if (current_extension_file === undefined ){
        break
      }
      if (!['pdf','png','jpg','jpeg'].includes(current_extension_file.toLowerCase())){
        
        alert("Veuillez choisir vos fichiers parmi les extensions suivantes 'PDF', 'PNG', 'JPG' ou 'JPEG'.")
        this.setState({
          progressInfos: [],
          acceptedFiles: []
        })
        return false;
        
      }
    }
    
    

    /*
    if (already_files_before_upload === true){
      this.props.parrentStepper()
    }*/
    
    return true;

  }
  
  nextStep(){

    if (list_text_button[this.props.step] === "Effectuez une nouvelle analyse"){
      
      this.props.nouvelle_analyse() // plus fluide que le rechargement complet de la page

    }

    /*
    if (this.props.step===1){ // le user a cliqué sur analyser. On demande au back d'analyser avant de augmenter 

      if (this.state.acceptedFiles != null){
          
        if (this.state.acceptedFiles.length > 0){
            
            //this.setState({isAnalyseResponseLoading: true})

            UserService.postUploadFiles(this.props.document_type, this.state.acceptedFiles).then((response)=> {

            console.log("response du back : ", response)
            this.setState({isAnalyseResponseLoading: false})
          }).catch((error) => {console.log("error",error)

          this.setState({isAnalyseResponseLoading: false})})

        }
      }
    }*/

    // lancer l'analyse ici avec axios. multipart file post.

    this.props.parrentStepper()

    //window.alert("il faut coder l'upload des images");
  }

  getListImageWithUploadStatus(){

    var lenFiles = 0
    var local_list_component : JSX.Element[] | undefined 
    var component_to_return : JSX.Element | undefined 
    local_list_component = undefined
    let local_acceptedFiles = this.state.acceptedFiles
    if (local_acceptedFiles.length !== 0){ // we can't use local_acceptedFiles.map, so I build local_list_component to map it after
      lenFiles = local_acceptedFiles.length
      local_list_component = []
      
      for (var j: number=0; j< lenFiles; j++){
        var local_size:string = ""
        local_size = formatSizeUnits(local_acceptedFiles[j].size)
        
        local_list_component.push(<div className="file_view_when_upload" style={style_for_upload_doc}>
          <img alt="status upload image" src={file_added}></img> 
          <div style={{display : "flex", flexDirection:"column", paddingTop: "1rem"}}>
             <p style={{paddingTop: "1rem", width: "15rem"}}>{(local_acceptedFiles[j].name.length > 17) ? local_acceptedFiles[j].name.substring(0,25) + "..." + local_acceptedFiles[j].name.split('.')[local_acceptedFiles[j].name.split('.').length - 1] : local_acceptedFiles[j].name }</p> 
             <p style={{color: "#8390b3"}}>{local_size}</p></div> <img alt="poubelle icon" 
             onClick={ (j === 0) ? ()=>{

              if (local_acceptedFiles != null){
                this.setState({
                  acceptedFiles: []
                })
                this.changeSecondUploadFileBoolean(false)
                if (local_acceptedFiles.length > 1){
                  /*
                  let fileList = new FileList();
                  fileList[0]= local_acceptedFiles.item(1)! // ! symbol pas risqué car j'ai testé la non nullité avec local_acceptedFiles.length > 1
                  */
                  this.props.set_step_to_zero() // c'est dur de coder les cas où ya deux fichiers mais on en supprime 1 seul, j'ai pas réussi

                }else{
                  this.props.set_step_to_zero() // c'est dur de coder les cas où ya deux fichiers mais on en supprime 1 seul, j'ai pas réussi
                  
                }
                
              }}
              : ()=>{
                  if (local_acceptedFiles != null){
                    this.setState({
                      acceptedFiles: []
                    })
                    this.changeSecondUploadFileBoolean(false)
                    if (local_acceptedFiles.length > 1){
                      /*
                      let fileList = new FileList(); // Le new fait planter le TypeScript.
                      // code issue de https://github.com/jsdom/jsdom/issues/1272
                      fileList[0]= local_acceptedFiles.item(0)! // ! symbol pas risqué car j'ai testé la non nullité avec local_acceptedFiles.length > 1
                      */
                      this.props.set_step_to_zero() // c'est dur de coder les cas où ya deux fichiers mais on en supprime 1 seul, j'ai pas réussi
                      
                    }else{
                      this.props.set_step_to_zero() // c'est dur de coder les cas où ya deux fichiers mais on en supprime 1 seul, j'ai pas réussi
                      //this.setState({acceptedFiles : null}) 
                    }
                  }
                }
              }  
             src={poubelle_icon_svg}></img> 
             </div>
             )
       
      component_to_return = <>{local_list_component.map(element => element) }</>  
    }

    return <div id="div_uploaded_file" style={{paddingTop:"3rem", paddingLeft: "1rem", paddingRight: "1rem",paddingBottom:"2rem",display:"flex", justifyContent: "space-between" ,flexDirection:"column"}}> {component_to_return} </div>
    }
    else{
      return <></>
    }
  }

  getListImagesWithThumbnails(){ // à améliorer pour avoir le certificat (3eme etape), 
    // ça renvoie les thumbnails avec les noms des images ect..

    var lenFiles = 0
    var local_list_component : [string, string ,JSX.Element][] | undefined 
    var component_to_return : JSX.Element | undefined 

    let local_acceptedFiles = this.state.acceptedFiles

    if (local_acceptedFiles !== null){ // we can't use local_acceptedFiles.map, so I build local_list_component to map it after
      lenFiles = local_acceptedFiles.length
      local_list_component = []
      
      for (var j: number=0; j< lenFiles; j++){
        var local_size:string = ""
        local_size = formatSizeUnits(local_acceptedFiles[j].size)

        if (local_acceptedFiles[j].name.endsWith(".pdf"))
        {
          local_list_component.push([local_acceptedFiles[j].name, "Size: " + local_size, <embed style={{width: "100%", height: "26rem"}} src={URL.createObjectURL(local_acceptedFiles[j])} />])
        }
        else
        {
          local_list_component.push([local_acceptedFiles[j].name, "Size: " + local_size, <img src={URL.createObjectURL(local_acceptedFiles[j])} />])
        }
      }

      component_to_return = <div id="thumbnails_div_container">
      
        {local_list_component.map((item) => 

            {

              return <div><div className="background_thumbnail">{item[2]}</div><div style={{height: "2rem"}}></div></div>
            
            }
          
        )}

    </div>
    
    }

    //this.props.parrentStepper() // 

    return <React.Fragment>
      {
      (this.state.certificat_raw_base_64 !== "L'analyse a bien été réalisée, cependant pour une raison technique, le certificat n'a pas pu être généré. Veuillez contacter le support." ) ? 
      <div>
    
      <p>
      
      <UtilsAfterAnalyse message_from_server_after_upload={this.state.message_from_server_after_upload} 
      document_type={this.props.document_type} 
      score={this.state.score}
      code={this.state.code}
      refreshTheCounter={this.props.refreshTheCounter}
      />
      
      </p>

      </div>
            : 
      <p>L&rsquo;analyse a bien été réalisée, cependant pour une raison technique, le certificat n&rsquo;a pas pu être généré.</p>
    }
      
      {component_to_return}
      </React.Fragment>
    //return <div>{lenFiles}</div>
  }

  render() {

    const {acceptedFiles} = this.state;

    //debuggingMessage(undefined, "test ici debug upload-files component")

    return (
      
      <div className="upload_component">
        <div className="div_select_field_and_button" style={{paddingBottom:'2rem'}}> 
              {((this.props.step === 2) && this.state.isAnalyseResponseLoading) ? 
            
            // Loading Button during loading.
            <ButtonIsLoading 
            document_type={this.props.document_type} 
            acceptedFiles={this.state.acceptedFiles} 
            change_parent_loading_status={this.change_loading_status} 
            loading_state={this.state.isAnalyseResponseLoading} 
            component_to_return_if_not_loading={<>is not loading</>}
            >
            </ButtonIsLoading>
            : 
            <>
            {(this.props.step < 1) ? // <1 car quand on a upload un fichier, il ne faut pas que le user puisse en upload un autre (ça fait bugger l'application.)
              <div className="label_container" style={{ width:'80%',paddingBottom:'2rem'}}>
                {(this.state.userWantToUploadAnOtherFile) ?
                  <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
                  {this.getListImageWithUploadStatus()}
                    <div style={{display:"flex", flexDirection:"column"}}>
                      Souhaitez vous ajouter l'autre face de votre document ? 
                      (ex: verso)
                      <br></br>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", paddingTop:"2rem"}}>
                          <button className="btn btn-sm" style={{border: "solid"}}
                          onClick={() => 
                          {
                            this.changeSecondUploadFileBoolean(false) 
                            
                            // cela provoque l'affichage de la zone d'analyse.

                            // TODO changer le texte de la zone d'analyse en :
                            // "Veuillez ajouter la seconde partie de votre document. "

                            // to do réafficher la zone d'analyse 
                            // avec le message ajouter votre deuxième document
                            //this.props.parrentStepper()
                            
                            return;
                          }} 
                          
                          >
                            Oui
                            </button>
                            <div style={{width:'2rem'}}></div>
                            <button className="btn btn-sm" style={{border: "solid"}}

                          onClick={() => 
                            {
                              this.changeSecondUploadFileBoolean(false) 
                              // cela provoque l'affichage de la zone d'analyse.
                              // to do réafficher la zone d'analyse 
                              // avec le message ajouter votre deuxième document
                              
                              this.props.parrentStepper()
                              
                              return;
                            }} 
                          >
                            Non
                          </button>
                        </div>
                      

                    </div>
                  </div>
                  
                  :
                  <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
                    {this.getListImageWithUploadStatus()}
                    <label 
                  id="drag_label_id" 
                  draggable={true} 
                  onDragOver={(event)=> {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                  onDragEnter={(event) => { 
                    // modification du css en fonction du mouvement du curseur.
                    document.getElementById("drag_label_id")!.style.borderColor = "#0061d1";
                    event.stopPropagation(); 
                  }} 
                  /*
                  onDragExit= {(event) => { // fonction qui ne fonctionne pas pour l'instant
                    document.getElementById("drag_label_id")!.style.borderColor = "#fff";
                    event.stopPropagation();
                  }} */
                  onDrop={(event)=> {

                    event.stopPropagation();
                    event.preventDefault();
                    var dt = event.dataTransfer;
                    var files = dt.files; // https://jsbin.com/hiqasek/edit?html,js,output code trouvé ici

                    this.fileAcceptance(files)
         
                  }}

                  style={{
                  display:'flex',
                  flexDirection:'column',
                  alignItems: 'center',
                  paddingTop: '3rem',
                  border:'dashed 5px #000', 
                  backgroundColor:'white',
                  borderColor:'#8390b3', 
                  height:'100%', 
                  borderRadius:'1.8rem'
                  }}>

                  <div style={{paddingBottom:"2rem"}}><img alt="drag_and_drop_image" src={img_drag_and_drop} style={{width:"3.8rem", height:"4.2rem"}}></img></div>
                  
                  <label htmlFor="input_file" className="btn" >
                    {
                      (this.state.acceptedFiles.length === 1)?
                      <p id="text_info_user_drop_files" style={{color:'#4a9cea', fontSize:'1.4rem'}}>
                        Ajouter le verso de votre document. <br></br>PDF, PNG ou JPG jusqu'à 5 Mo 
                      </p> 
                      :
                      <p id="text_info_user_drop_files" style={{color:'#4a9cea', fontSize:'1.4rem'}}>
                      Ajouter votre fichier recto-verso ou deux fichiers simultanément. <br></br>PDF, PNG ou JPG jusqu'à 5 Mo 
                      </p> 
                      
                    }
                    
                  </label>

                  <input id="input_file" type="file" multiple onChange={this.selectFiles} accept=".jpg, .jpeg, .png, .pdf" style={{visibility:'hidden'}}/>

                  </label>
                  </div>
                  
                }
                
              </div> 
            :
            <>
            </>
            }
            
            {(this.props.step === 1) ? this.getListImageWithUploadStatus() : <></>}
            {(this.props.step === 2) ? this.getListImagesWithThumbnails() : <></>}
            
            { 
              (this.state.userWantToUploadAnOtherFile) ? 
              <></>
              :
                (
                (this.props.step === 2 && this.state.certificat_raw_base_64 !== "L'analyse a bien été réalisée, cependant pour une raison technique, le certificat n'a pas pu être généré. Veuillez contacter le support." && !(this.state.message_from_server_after_upload === "Analysis successful.")) ?
                  <div>
                    <button
                    className="btn btn-success btn-sm" 
                    disabled={acceptedFiles.length===0}
                    onClick={this.props.nouvelle_analyse}
                    
                    style={{paddingTop: '2rem', paddingBottom: '2rem', color: "#0061d1" ,backgroundColor: '#dadee8', border:(!acceptedFiles) ? "none":"solid" /*, color: (!acceptedFiles) ? "#8390b3":"#0061d1"*/}}
                  >
                    {list_text_button[this.props.step+1] }
                  </button>
                  </div>
                  :
                  <div>
                    <button
                  className="btn btn-success btn-sm" 
                  disabled={acceptedFiles.length===0}
                  onClick={this.nextStep}
                  
                  style={{paddingTop: '2rem', paddingBottom: '2rem', 
                  color:!(this.props.step === 2 && this.state.certificat_raw_base_64 !== "" && this.state.certificat_raw_base_64!== "error certificat, pas de certificat trouvé") ? "#0061d1": '#9af7d0',
                  backgroundColor:!(this.props.step === 2 && this.state.certificat_raw_base_64 !== "" && this.state.certificat_raw_base_64!== "error certificat, pas de certificat trouvé") ? '#dadee8':'#9af7d0', border:(!acceptedFiles) ? "none":"solid" /*, color: (!acceptedFiles) ? "#8390b3":"#0061d1"*/}}
                >
                  {
                  !(this.props.step === 2 && this.state.certificat_raw_base_64 !== "" && this.state.certificat_raw_base_64!== "error certificat, pas de certificat trouvé") 
                  ? 
                  list_text_button[this.props.step] 
                  :
                  <a href={"data:image/pdf;base64," + this.state.certificat_raw_base_64} download="certificat.pdf"> Télécharger mon certificat </a>
                  }
                </button></div>
                  
                  )
                }   
            </>}
        </div>
      </div>
    );
  }
}
