import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
    
    "Ajout terminé, vous pouvez commencer l'analyse",
    "Analyse en cours",
    "Votre certificat a bien été téléchargé, il est également disponible dans votre espace personnel."
];

type Props = {
    step: number; // step equals to 0 : it's when the user didn't load any document.
    // step to 1 the documents has been uploaded.
    // step 2 the user clicked on process after step 1, the server has 
  };

export const ProgressBarComponent : React.FunctionComponent<Props> = (props: Props) => {
    return (
        <React.Fragment>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

        <h1 id="indicateur_step_id" style={{ width:"50%"}}> {(props.step === 0) ? "Ajouter":steps[props.step -1]}</h1>
        
      <Stepper style={{width: "70%"}} activeStep={props.step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>

    </Box>
        </React.Fragment>
    )
}