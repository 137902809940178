import React, { Dispatch, SetStateAction, useState } from "react";
import { useEffect, useRef } from 'react';

import { packageComponentProps } from "../types/common.type";
import "./4QokkaAbonnementV2.component.css"
import i18n from "../i18n";
import { KycQokkaPubComponent } from "./organisms/KYC_Qokka_pub";
import { Box, Button, CircularProgress, Hidden } from "@mui/material";
import { WhyQokkaComponent } from "./organisms/WhyQokka";
import productService, { ProductBackOptionType, ProductBackSubscriptionType, ProductBackExtraType } from "../services/product.service";
import { routes } from "../utils/routes";
import { emitKeypressEvents } from "readline";
import authService from "../services/auth.service";
import clsx from "clsx";
import { connected } from "process";
import { QokkaCallPost } from "../services/auth-header";

const TopIcon = (props:{color:string}) => {
  return <svg width="100%" height="100%" preserveAspectRatio="none" version="1.1" viewBox="0 0 37.189 26.43" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(24.732 -50.55)"><path d="m10.287 50.577c-3.1993 0.0085-6.8153-7.94e-4 -9.1913-0.02716-0.86501 0.0099-1.9032 0.01728-3.0335 0.02257 2.9743 1.7046 4.8966 4.869 4.88 8.3792-0.01199 2.5259-0.877 4.7544-2.8363 6.2382-1.6133 1.2217-2.4342 2.8268-3.1108 4.6359-0.75742 2.0249-1.6048 4.0128-2.4126 6.0173-0.17462 0.0067-0.34854 0.01342-0.52282 0.02011-1.0128-2.2807-1.9142-4.6161-3.0787-6.8227-0.74683-1.4146-1.7124-2.8215-2.8758-3.9151-3.2681-3.0723-4.2845-7.468-2.371-11.25 0.70979-1.403 1.8087-2.5277 3.1203-3.3133-0.27975-0.0024-0.54892-0.0046-0.80716-0.0071-3.0903 0.02998-7.9421 0.03246-11.43 0.0088-2.7524 6.6788-1.2887 14.811 4.3251 20.478 7.4958 7.5671 19.214 7.9516 26.329 0.8636 5.7157-5.6941 6.6816-14.265 3.0152-21.329" 
      fill={props.color}/></g></svg>
}

type OfferComponentPropsType = {
  offer: ProductBackOptionType | ProductBackSubscriptionType
  connected: boolean,
  paddingWave?: string,
  promo: boolean,
  setErrorMessage: Dispatch<SetStateAction<string | null>>
}

const OfferComponent: React.FunctionComponent<OfferComponentPropsType> = (props: OfferComponentPropsType) => {
  const [hover, setHover] = useState(false)

  const hoverMoreButtonStyle = {
    backgroundColor: props.offer.color['link-hover'],
    borderRadius: "0 0 30px 30px"
  }
    
  const normalMoreButtonStyle = {
    backgroundColor: props.offer.color.link,
    borderRadius: "0 0 30px 30px",
  }

  const onMouseEnter = () => {
      setHover(true)
  }
  
  const onMouseLeave = () => {
      setHover(false)
  }

  let price = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(props.offer.price / 100)  
  let reduction = props.offer.promo ? props.offer["promo-type"] == 'percent' ? Math.round(props.offer.price * props.offer.promo / 100) : props.offer.price : undefined
  let reductionDisplay = reduction ? props.offer["promo-type"] == 'percent' ? '-'+props.offer.promo+'%': new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(reduction) : undefined
  let priceWithReduction = reduction ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format((props.offer.price - reduction) / 100) : undefined
  let unit = props.offer.type == 'option' ? "/l&rsquo;unité" : "/mois"
  let pricePerConsultation = props.offer.consultations > 1 ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format((props.offer.price - (reduction ? reduction : 0)) / (props.offer.consultations * 100)) : undefined

  let bgColors = [ 'transparent', 'transparent', 'transparent']

  if (props.offer["wave-position"] == 'in-text')
    bgColors[0] = bgColors[1] = bgColors[2] = props.offer.color.background;
  else if (props.offer["wave-position"] == 'before-button')
    bgColors[1] = bgColors[2] = props.offer.color.background;
  else
    bgColors[2] = props.offer.color.background;
  
  return <div className={"offer " + props.offer.type}  style={{ border: props.offer.isCurrent ? "10px dotted #ffe470" : ""}}><div className="offer-content">
    <div className="block-top">
      { props.offer.promo && <div className="promoContainer"><div className="promoBanner"><span>{reductionDisplay}</span></div></div>}
      <div className="topIcon"><TopIcon color={props.offer.color.background} /></div>
      <span className="name">{props.offer.name}</span>
      <span className="consultations" style={{backgroundColor: props.offer.color.background }}>{props.offer.consultations} consultation{props.offer.consultations > 1 ?'s':''}</span>
      <p className="description" dangerouslySetInnerHTML={{ __html: props.offer["description-txt1"] }}></p>
    </div>
    <div className="block-middle">      
      { props.offer["wave-position"] === "in-text" && <div className="wave" dangerouslySetInnerHTML={{ __html: props.offer["wave-design"].replace(/#fff/g, props.offer.color.background)}} /> }
    </div>
    <div className="block-bottom">
      <div className="bg-container" style={{backgroundColor: bgColors[0]}}>
        { props.offer["description-txt2"] && <p id="description-txt2" className="description" dangerouslySetInnerHTML={{ __html: props.offer["description-txt2"] }}></p>}
      </div>
      { props.offer["wave-position"] === "before-button" && <div className="wave" dangerouslySetInnerHTML={{ __html: props.offer["wave-design"].replace(/#fff/g, props.offer.color.background)}} /> }
      <div className="bg-container" style={{backgroundColor: bgColors[1]}}>
        { props.offer.isCurrent ? <div className="medium current-subscription">Votre abonnement actuel</div> : <Button variant="contained" className={clsx(["btn", "medium", props.promo && !props.offer.promo && "promo-padding"])} onClick={() => {
/*          if (props.offer.type !== 'option')
            alert('todo')
          else*/
          if (!props.connected)
          {
            window.location.href= baseRouteUrl + routes['register'].path + "?productId=" + props.offer.id
          }
          else
          {
            productService.payProduct(props.offer.id)
              .then(
                (resp) => {
                  let queryString = "id="+resp.data.stripePaymentIntentId+
                    "&product_type="+resp.data.productType+
                    "&product_consultations="+resp.data.productConsultations+
                    "&product_name="+resp.data.productName+
                    "&product_color="+resp.data.productColor.replace('#', '%23')+
                    "&product_price="+resp.data.productPrice+
                    "&pm="+resp.data.pm+
                    "&orderId="+resp.data.orderId
            
                  if (resp.data.productPromo)
                  {
                    queryString = queryString + "&product_promo="+resp.data.productPromo+
                        "&product_promo_type="+resp.data.productPromoType
                  }
                  let route = baseRouteUrl +"/payment_connected?" + queryString
                  window.location.href = route
                }
              )
              .catch(
                (err) => {
                  console.log('Error trying to generate payment'+err)
                  props.setErrorMessage(err)
                }
              )
              
          }
        }}>Choisir {props.offer.type == 'option' ? "cette option" : "cet abonnement"}</Button> }
      </div>
      { props.offer["wave-position"] === "after-button" && <div className="wave" dangerouslySetInnerHTML={{ __html: props.offer["wave-design"].replace(/#fff/g, props.offer.color.background)}} /> }
      <div className="bg-container" style={{backgroundColor: bgColors[2], borderRadius: "0 0 30px 30px" }}>
        { props.paddingWave && <div className="wave padding-wave" dangerouslySetInnerHTML={{ __html: props.paddingWave.replace(/#fff/g, 'transparent')}} /> }
        { props.offer.promo && <span className="stroken" style={{color: props.offer.color["gray-price"]}} dangerouslySetInnerHTML={{ __html: price+unit }} /> }
        <span className="price"><span className="number">{ props.offer.promo ? priceWithReduction : price }</span><span className="unit" style={{color: props.offer.color["gray-price"]}} dangerouslySetInnerHTML={{ __html: unit}}></span></span>
        <span className="comment">{props.offer.type == 'option' ? "Sans engagement" : "Pas de dépassement possible"}</span>
        { pricePerConsultation ? <span className="pricePerConsultation">{pricePerConsultation}/consultation</span> : <span className="pricePerConsultation">&nbsp;</span> }
        { /* <Button className="more" style={ hover ? hoverMoreButtonStyle : normalMoreButtonStyle } onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={() => { alert("todo") }}>En savoir plus</Button>         */ }
      </div>
    </div>
  </div></div>
}

type ExtraComponentPropsType = {
  offer: ProductBackExtraType,
  connected: boolean,
  promo: boolean
  setErrorMessage: Dispatch<SetStateAction<string | null>>
}

const ExtraComponent: React.FunctionComponent<ExtraComponentPropsType> = (props: ExtraComponentPropsType) => {
  let price = props.offer.price ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(props.offer.price / 100) : undefined
  let unit = "/l&rsquo;unité"
  let pricePerConsultation = (props.offer.price && props.offer.consultations && (props.offer.consultations > 1)) ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format((props.offer.price) / (props.offer.consultations * 100)) : undefined

  return <div className={"extra " + props.offer.type} style={{backgroundColor: props.offer.color.background}}>
    <div className="block-top">
      <div className="topIcon"><TopIcon color='#fff' /></div>
      <span className="name">{props.offer.name}</span>
      { (props.connected && props.offer.consultations) ? <span className="consultations" style={{color: props.offer.color.background }}>{props.offer.consultations} consultation{props.offer.consultations > 1 ?'s':''}</span> :
      <span className="reserved" style={{color: props.offer.color.background}}>Réservé aux abonnés</span> 
      }    
      <p className="description" dangerouslySetInnerHTML={{ __html: props.offer["description"] }}></p>
    </div>
    <div className="block-bottom">
      { props.connected && <Button variant="contained" className={clsx(["btn", "medium", props.promo && "promo-padding"])} onClick={() => {
          if (!props.connected)
          {
            window.location.href= baseRouteUrl + routes['register'].path + "?productId=" + props.offer.id
          }
          else
          {
            productService.payProduct(props.offer.id)
              .then(
                (resp) => {
                    let queryString = "id="+resp.data.stripePaymentIntentId+
                  "&product_type="+resp.data.productType+
                  "&product_consultations="+resp.data.productConsultations+
                  "&product_name="+resp.data.productName+
                  "&product_color="+resp.data.productColor.replace('#', '%23')+
                  "&product_price="+resp.data.productPrice+
                  "&pm="+resp.data.pm+
                  "&orderId="+resp.data.orderId
            
                  if (resp.data.productPromo)
                  {
                    queryString = queryString + "&product_promo="+resp.data.productPromo+
                        "&product_promo_type="+resp.data.productPromoType
                  }
                  let route = baseRouteUrl +"/payment_connected?" + queryString
                  window.location.href = route
                }
              )
              .catch(
                (err) => {
                  console.log('Error trying to generate payment'+err)
                  props.setErrorMessage(err)
                }
              )
          }
        }}>Ajouter un extra</Button> }
      { props.connected && <span className="price"><span className="number">{ price }</span><span className="unit" /*style={{color: props.offer.color["gray-price"]}}*/ dangerouslySetInnerHTML={{ __html: unit}}></span></span> }
      { props.connected && <span className="comment">Pas de dépassement possible<br/>Pas de modification de l&rsquo;abonnement</span> }
      { props.connected && (pricePerConsultation ? <span className="pricePerConsultation">{pricePerConsultation}/consultation</span> : <span className="pricePerConsultation">&nbsp;</span>) }
    </div>
  </div>
}

const baseRouteUrl = "/" + i18n.language;

const AvailablePlans: React.FunctionComponent = () => {
  const [ products, setProducts] = useState<(ProductBackOptionType | ProductBackSubscriptionType | ProductBackExtraType)[] | undefined>(undefined);
  const [ errorMessage, setErrorMessage ] = useState<string|null>(null);
  const errorStr = "Une erreur est survenue en récupérant la liste des produits"
  const user = authService.getCurrentUser()

  useEffect(() => {
    let interval:NodeJS.Timeout | undefined = undefined;
    const productResponse = productService.getProducts()
    .then(
      (response) => {
        if (response.status == 200)
        {
          Promise.resolve()
            .then(() => { setProducts(response.data.products); return response.data })
            .then((responseData) => {
              interval = setInterval(() => {
              productService.getProducts()
              .then((resp2) => {
                if (resp2.status == 200)
                {
                  if (responseData.products && !productService.arrayEquals(responseData.products, resp2.data.products))
                  {
                    window.location.reload();
                  }
                }
                else
                    window.location.reload();
              })
              .catch(() => { window.location.reload() })
            }, 5000) })
        }
        else
        {
          console.log(response)
          setErrorMessage(errorStr);
        }
      }
    )
    .catch((err) => {
      console.log(err)
      setErrorMessage(errorStr);
    })    
    return () => { interval && clearInterval(interval) };
  }, [])

  let offers:(ProductBackOptionType | ProductBackSubscriptionType)[] = []
  let extras:(ProductBackExtraType)[] = []
  
  let i = 0

 products?.forEach((product) => {
    if (product.type == "option")
      offers[i++] = product as ProductBackOptionType
    else if (product.type == "subscription")
      offers[i++] = product as ProductBackSubscriptionType
  })
  i = 0
  products?.forEach((product) => {
    if (product.type == "extra")
      extras[i++] = product as ProductBackExtraType
  })

 if (products && !products.length && !errorMessage)
    setErrorMessage("Aucun produit trouvé dans le catalogue.");
  
  let promo = false
  let currentSubscription = false
  
  const offers_size = offers ? offers.length : 0

  for (let i = 0; i < offers_size; i++)
  {
    if (offers[i].promo)
      promo = true;      
    if (offers[i].isCurrent)
      currentSubscription = true
  }

  return (
    <div className={clsx(["offer-choice", user && "offer-choice-connected"])}>
      <div className="text-container">
        { user ? <h1>Changez votre formule<br />ou<br />Ajoutez un extra QOKKA</h1> : <h1>Choisissez votre formule QOKKA</h1> }
        { !user && <p className="subtitle">Nous proposons plusieurs formules adaptées à vos besoins.</p> }
        { user ?
        <>
        <p>Vous souhaitez changer votre formule d&rsquo;abonnement, ou vous avez épuisez vos consultations et souhaitez prendre un extra&nbsp;?<br/><span className="medium">Vous êtes au bon endroit&nbsp;!</span></p>
        <p><span className="medium">Vous pouvez à tout moment modifier votre forfait, ou compléter votre crédit de consultations.</span><br/>
        Chez QOKKA, nous avons à c&oelig;ur de satisfaire nos clients.</p>
        </>
         : <>
        <p>Vous avez la possibilité de changer de formule en cours d&rsquo;abonnement.</p>
        <p>Toutes les formules donnent accès au même niveau de support. </p>
        <p><span className="medium">Si les formules proposées ne correspondent pas à vos besoins, nous pouvons vous proposer des offres sur-mesure et sur devis. (<a style={{color:"black"}}href="mailto:contact@qokka.fr">contact@qokka.fr</a>)</span></p>
        </>
        }
      </div>
      { errorMessage ? <div className="text-container2 Mui-error">{errorMessage}</div> :
        !products ? <div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div> :
      <>
        { currentSubscription && <div className="horizontal-selector">
          { 
            offers?.map((el, index) => (<div className="current-offer-bubble-container2">{ el.isCurrent && <><div className="current-offer-bubble" style={{backgroundColor: el.color.background}}>            
              <span className="text medium">Votre forfait actuel</span>
              <span className="name medium">{el.name}</span>
               <span className="consultations" style={{backgroundColor: el.color.background }}>{el.consultations} consultation{el.consultations > 1 ?'s':''}</span>
            </div>
            <div className="current-offer-bubble-arrow" style={{backgroundColor: el.color.background}}></div></> }
            </div>))
          }
          <div className="current-offer-bubble-extra-padding"></div>
        </div> }
        <div className="offers">          
          { offers?.map((el, index) => (<>
            { el.isCurrent && <div className="current-offer-bubble-container"><div className="current-offer-bubble" style={{backgroundColor: el.color.background}}>
              <span className="text medium">Votre forfait actuel</span>
              <span className="name medium">{el.name}</span>
               <span className="consultations" style={{backgroundColor: el.color.background }}>{el.consultations} consultation{el.consultations > 1 ?'s':''}</span>
            </div>
            <div className="current-offer-bubble-arrow" style={{backgroundColor: el.color.background}}></div>
            </div>
             }
            <OfferComponent offer={el} connected={user !== null} paddingWave={(index && !user) ? offers[0]["wave-design"] : undefined} promo={promo} setErrorMessage={setErrorMessage}/></>
          )) }
          { user && extras?.map(el => (<ExtraComponent offer={el} connected={true} promo={promo} setErrorMessage={setErrorMessage}/>)) }
        </div>
        { !user && <div className="extras">
          { extras?.map(el => (<ExtraComponent offer={el} connected={false} promo={promo} setErrorMessage={setErrorMessage} />)) }
        </div> }
      </> }
      { !user && <>
      {/*<KycQokkaPubComponent button_title="La solution Qokka" link_button="/home"></KycQokkaPubComponent>*/}
      
      <WhyQokkaComponent />
      </> }
    </div>
  );

}

export default AvailablePlans;