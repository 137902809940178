export const TRANSLATIONS_EN = {
    menu_welcome:"QOKKA solution",
    menu_products: "Products",
    menu_contact_us: "Contact us",
    menu_contact_us_connected: "Contact",
    menu_files: "Analyse your document",
    menu_admin: "Admin Tools",
    menu_user: "User",
    menu_sign_up: "Sign up",
    menu_log_in: "Log in",
    menu_log_out: "Log out",
    menu_upload: "Add a document",
    menu_history: "History",
    menu_my_account: "My account",
    menu_bill_history: "Bill history",
    register_infos: "Fill in your informations",
    register_email: "Email",
    register_password: "Password",
    register_company_name: "Company name",
    register_company_address: "Company address",
    register_siren_number: "SIREN number (optional)",
    register_subject_to_VAT: "Subject to VAT",
    register_intra_community_VAT_number: "Intra-community VAT number",
    register_lastname: "Lastname",
    register_firstname: "Firstname",
    register_receive1: "Yes, I would like to receive information from Qokka. To learn more about our privacy policies, see our cookie ",
    register_receive2: "statement",
    register_receive3: ".",
    register_sign_up: "Pay"
   };