import { EventBusy } from "@mui/icons-material";
import { rejects } from "assert";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import eventBus from "../common/EventBus";
import authService from "./auth.service";

function updateToken(promise: Promise<AxiosResponse<any>>) {
  return promise.then((response: AxiosResponse<any>) => {
    if (response.headers.token)
      localStorage.setItem("token", response.headers.token)


    return Promise.resolve(response)
  })
  .catch(err =>
  {
    if (localStorage.getItem("token"))
    {
      if (err.response && err.response.status == 401)
      {
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        window.location.reload()
        return Promise.resolve(err)
      }
    }

    return Promise.reject(err)
  })
}

function AddLastSidReceived(config?: AxiosRequestConfig)
{
  let sid = localStorage.getItem("sid")

  if (sid)
  {
    config = config ? config : {}

    if (config.headers === undefined)
    {
      config.headers = {}
    }
    config.headers = { 
      Cookie: 'connect.sid=' + sid,
      ...config.headers
    }
  }
  return config
}

export function QokkaCallGet(url: string, config?: AxiosRequestConfig) : Promise<AxiosResponse<any>>
{  
  return updateToken(axios.get(url, AddLastSidReceived(config)))
}

export function QokkaCallPost(url: string, data?: any, config?: AxiosRequestConfig) : Promise<AxiosResponse<any>>
{
  return updateToken(axios.post(url, data, AddLastSidReceived(config)))
}

export default function authHeader(/*multipart_form_data : boolean, form_datas : any*/)
{
  const token = localStorage.getItem("token");
  let headers = {}

  if (token !== null)
  {
/*    if (multipart_form_data ===true && typeof(form_datas) != undefined)
    {
      headers = { "Authorization": 'Bearer ' + token , "Content-Type": `multipart/form-data; boundary=${form_datas._boundary}` }; // for Node.js Express back-end
    }
    else
    {*/
      headers = { "Authorization": 'Bearer ' + token };
//    }
  }

  return headers
}

