import i18n from "../i18n";
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import UserService from "../services/user.service";
import QokkaPasswordInput from "./atoms/QokkaPasswordInput.component";
import { Button } from "@mui/material";
import { donnees_bruts } from "../utils/constants";
import "./17QokkaCreerNouveauMotdepasse.component.css"
import QokkaTextInput from "./atoms/QokkaTextInput.component";


const baseRouteUrl = "/" + i18n.language;


const validationSchema = Yup.object().shape({

    currentPassword: Yup.string().required("Ce champ est requis !"),
    newPassword: Yup.string()
      .required("Ce champ est requis !") 
      .test(
        "len",
        "Le mot de passe doit avoir entre 6 et 40 caractères.",
        (val: any) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40 
      ).test(
        "isValidPass", 
        "Doit contenir au moins une\nminuscule et une majuscule.", 
        (value: any) => { // code issue de https://stackoverflow.com/questions/65657804/how-to-validate-a-password-with-yup-when-minimum-amount-of-conditions-met
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);

          //const hasNumber = /[0-9]/.test(value);
          //const hasSymbole = /[!@#%&]/.test(value);
          let validConditions = 0;
          const numberOfMustBeValidConditions = 2; 
          const conditions = [hasLowerCase, hasUpperCase ] //, hasNumber, hasSymbole];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );
          if (validConditions >= numberOfMustBeValidConditions) {
            return true;
          }
          return false;
        })
      ,

      newPasswordConfirmation: Yup.string().required("Ce champ est requis !").oneOf([Yup.ref('newPassword')], 'Passwords must be the same')
      /*
      .test("samePassword","Les mots de passe doivent être les mêmes.",
      (value: any)=> {
        console.log()
        console.log(value)
        return true
      })*/
          // cette ligne faisait bugger le handleSubmit 
      // .oneOf([Yup.ref('newPassword').toString(), null], "Les mots de passe ne sont pas les mêmes.")
      
  });

interface PostData {

    currentPassword: string,
    newPassword:string,
    newPasswordConfirmation:string, 
    messageFromServer:string,
    successful:boolean
}
const ChangePasswordComponent: React.FunctionComponent = () => {
    const [formValues, setChangePasswordComponentValues] = useState<PostData>({
        currentPassword: "",
        newPassword:"",
        newPasswordConfirmation:"", 
        messageFromServer:"",
        successful:false
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword:"",
            newPasswordConfirmation:"", 
        },
        validationSchema: validationSchema,
        
        onSubmit: (values) => {
          const { currentPassword, newPassword, newPasswordConfirmation} = values;
          console.log("debug values to send here",values)
          setChangePasswordComponentValues({
            ...formValues
          });
          UserService.change_password(
            currentPassword,
            newPassword
          ).then(
            response => {
                console.log("debug response",response)
                setChangePasswordComponentValues({
                ...formValues,
                messageFromServer: response.data.message,
                successful: true,
              });
    //          window.location.href = baseRouteUrl +"/payment"; // après l'inscription, redirection vers le paiement.
              window.location.href = baseRouteUrl + "/mdp-reinitialised-ok"; // après l'inscription, redirection vers le paiement.
            },
            
            error => {
                console.log("debug error",error)
              if (error.response === undefined){
                // cas rencontré lorsque le back ne tourne pas. 
                // 
                let resMessage;
                resMessage = "Erreur de connection au serveur.\n Veuillez réessayer, si le problème persiste, vous pouvez contacter le support."
                setChangePasswordComponentValues({
                  ...formValues,
                  successful: false,
                  messageFromServer: resMessage,
                });
                return;
    
              }
              //console.log(error.response.data.toString())
              let resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              //console.log(resMessage)
              setChangePasswordComponentValues({
                ...formValues,
                successful: false,
                messageFromServer: resMessage,
              });
    
              /*
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              setFormValues({
                ...formValues,
                successful: false,
                message: resMessage,
              });*/
    
            }
          );      
        }
      });
    return <div className="createNewPassword">

        <div className="background_div"> 
        <h2>Modifier mon 
          <br />
      
        mot de passe</h2>
        
        </div>

        <div className="utils_div">
        <form onSubmit={formik.handleSubmit}>

              {/*
               
               <QokkaTextInput name="currentPassword" label="Mot de passe actuel" placeholder="XXXXXXXX" requiredDisplay={true} formik={formik} />
               <QokkaTextInput name="newPassword" label="Nouveau mot de passe" placeholder="XXXXXXXX" requiredDisplay={true} formik={formik} />
               <QokkaTextInput name="confirmNewPassword" label="Confirmer votre nouveau mot de passe" placeholder="XXXXXXXX" requiredDisplay={true} formik={formik} />
                       
              */}

              <QokkaPasswordInput label_value="Mot de passe actuel" form_entity="currentPassword" confirm={false} requiredDisplay={true} formik={formik} />
                
              <QokkaPasswordInput label_value="Nouveau mot de passe" form_entity="newPassword" confirm={false} requiredDisplay={true} formik={formik}/>
              {/** Je ne met pas les confirmations dans un premier temps. (confirm={false} donc) */}
              <QokkaPasswordInput label_value="Confirmer votre nouveau mot de passe" form_entity="newPasswordConfirmation" confirm={false} requiredDisplay={true} formik={formik}/>
              {formValues.messageFromServer ? <div className="error-message">{formValues.messageFromServer}</div> : null}
              <div style = {{height: "3rem"}}>

              </div>

              <Button className="pay btn" color="primary" variant="contained" type="submit">Valider</Button>

        </form>

        <a href={baseRouteUrl+ "/my-account"}><span style = {{textDecoration: "underline"}}>Annuler</span></a>
        
        </div>


    </div>
}


export default ChangePasswordComponent;