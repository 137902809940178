export const debuggingMessage = function (debug: boolean =false, message: any) {
    // by default the parameter debug is equal to true.
    // when exporting the project, the debug parameter can be equal to false.
    // in that way, the logs won't appear in production.

    if (debug === true){
        console.log(message)
    }
    
    return;
}
