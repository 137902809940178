import { TextField } from "@mui/material"
import { time } from "console";
import { FunctionComponent, ReactElement } from "react"
import './QokkaInput.component.css';
import {Element as Elt } from 'react-scroll'

type QokkaTextInputProps = {
    name: string
    default_value?: string
    label: any
    placeholder: string | undefined
    formik: any
    requiredDisplay: boolean
    multiline?: boolean
    rows?: number
    style?: React.CSSProperties | undefined
}

const defaultProps: QokkaTextInputProps = {
    name: "",
    default_value: undefined, 
    label: undefined,
    placeholder: undefined,
    formik: undefined,
    requiredDisplay: false,
    multiline: false,
    rows: 3,
    style: {height:"auto !important"}
}

const QokkaTextInput : FunctionComponent<QokkaTextInputProps> = props => {

    

    return <>
        <Elt name={props.name + '-anchor'} className="anchor" />
        <TextField
            fullWidth
            label={props.label}
            placeholder={props.placeholder}
            id={props.name}
            name={props.name}
            variant="filled"
            value={(props.formik.values[props.name] == "") ? props.default_value : props.formik.values[props.name]}
            onChange={props.formik.handleChange}
            error={props.formik.touched[props.name] && Boolean(props.formik.errors[props.name])}
            helperText={props.formik.touched[props.name] && props.formik.errors[props.name]}
            InputProps = {{ disableUnderline: true }}
            InputLabelProps={{ shrink: true, required: props.requiredDisplay , disableAnimation: true }}
            multiline={props.multiline}
            rows={props.rows}
            style={props.style}
          />
    </>
}
QokkaTextInput.defaultProps = defaultProps;

export default QokkaTextInput;