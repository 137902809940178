import './WhyQokka.css'

import WhoImmotech from "../../icons/who-immotech.svg"
import WhoAssurtech from "../../icons/who-assurtech.svg"
import WhoHealthtech from "../../icons/who-healthtech.svg"
import WhoFintech from "../../icons/who-fintech.svg"

import { WhoTile } from './WhoTile'

export const WhyQokkaComponent : React.FunctionComponent = () => {
    return <div className="why-block">

        <div className='whyQokkaDivText'>

        <div className='spacerDiv'>
        <h1>Pourquoi QOKKA&nbsp;?</h1>
        </div>
        
        <p>QOKKA, une solution digitale simple, rapide, efficace et qui a fait ses preuves.
        </p>
        <p>
        RESOCOM, soci&eacute;t&eacute; experte depuis près de 25 ans dans la connaissance client auprès des
         &eacute;tablissements bancaires, a cr&eacute;&eacute; QOKKA pour mettre à la disposition&nbsp;<span style={{
            textDecoration: "underline",
            fontWeight: "bold"
         }}>&nbsp;de toutes 
         les entreprises</span>&nbsp;ses outils les plus perfectionn&eacute;s pour d&eacute;tecter les faux documents
          d&rsquo;identit&eacute; [Carte d&rsquo;identit&eacute;, passeports, titres de s&eacute;jour qu&rsquo;ils soient français 
          ou europ&eacute;ens].</p>
          <p>Les malfaiteurs profitent de la digitalisation : le taux de fraude à l&rsquo;identit&eacute; en ligne est ainsi pass&eacute; de 6,6 % en
             2019 à 8,5&nbsp;% en 2020 (source&nbsp;<a style={{color:"white"}} href="https://onfido.com/fr/company/about/">Onfido</a>).</p>
            <p>Tous les secteurs d&rsquo;activit&eacute;s sont touch&eacute;s par la fraude à l&rsquo;identit&eacute;.</p>
            <p>Pour exemple, l&rsquo;utilisation de faux documents repr&eacute;sente d&eacute;sormais 37% des fraudes dans le secteur de l&rsquo;immobilier (&eacute;tude YOUSE OPINION).</p>
            <p>QOKKA facilite la vie des entreprises en assurant un retour sur investissement rapide en limitant le risque de fraude.</p>
        </div>
        
        <div className='forWhoQokkaDiv'>
            <h1>QOKKA<br/>pour qui&nbsp;?</h1>
            <div className='flexWhoTiles'>
            <div className='responsiveFlexer'>
            <WhoTile image={<img alt="IMMOTECH" src={WhoImmotech}/>}
                title="IMMOBILIER"
                points={[ <li>Contrôle et authentification du locataire / acheteur / prestataire</li>
                ]} />
            <WhoTile image={<img alt="ASSURTECH" src={WhoAssurtech}/>}
                title="ASSURANCE"
                points={[
                <li>D&eacute;tection des fraudeurs</li>,
                <li>Gain de temps pour contrôler l'identité des bénéficiaires</li>,
                <li>Lutte contre la fraude à l'assurance</li>]} />
            </div>
            <div className='responsiveFlexer'>
            <WhoTile image={<img alt="HEALTHTECH" src={WhoHealthtech}/>}
                title="RECRUTEMENT"
                points={[
                <li>Authentification automatisée de l'identité des candidats</li>,
                <li>Aucune perte de temps dans le processus de contrôle</li>,
                ]}/>
            <WhoTile image={<img alt="FINTECH" src={WhoFintech}/>}
                title="FINANCE, CONSEILS ET SERVICES CONFORMITE..."
                points={[
                <li>Maîtrise du risque réputationnel <br></br> Lutte contre le blanchiment
                d'argent et le financement du terrorisme</li>,
                ]} />
            </div>
            </div>
        </div>
    
    </div>
}