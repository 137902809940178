import React, { useState } from "react";
import { Button,  TextField, AppBar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import i18n from "../i18n";
import LogoQOKKA from "../icons/logoQOKKA.svg";
import './3QokkaContactNoConnect.component.css';
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthService from "../services/auth.service";
import { time } from "console";
import SelectInput from "@mui/material/Select/SelectInput";
import QokkaTextInput from "./atoms/QokkaTextInput.component";
import { height } from "@mui/system";
import letter_svg from "../icons/letter_mail2.svg"
/*
interface RouterProps {
  history: string;
}*/

const baseRouteUrl = "/" + i18n.language;

//type Props = RouteComponentProps<RouterProps>;

type State = {
  lastname:string,
  firstname:string,
  email: string,
  phone: string,
  message: string,
  loading: boolean,
  message_from_server: string
};
const validationSchema = Yup.object().shape({
  email: Yup.string()
  .email("Cette adresse email n'est pas valide.")
  .required("Ce champ est requis !"),
  lastname: Yup.string()
  .required("Ce champ est requis !"),
  firstname: Yup.string()
  .required("Ce champ est requis !"),
  message: Yup.string()
  .required("Ce champ est requis !"),
});

const ContactNoConnect: React.FunctionComponent = () => {

  const [formState, setFormState] = useState<State>({
    lastname: "",
    firstname: "",
    email: "",
    phone: "",
    message: "",
    loading: false,
    message_from_server: ""
  });

  const HandleMessage = (formEntries: { lastname: string; firstname: string; email: string; phone:string; message:string }) => {

    const {lastname,firstname,email,phone,message} = formEntries;
    
    AuthService.contact(lastname,firstname,email,phone,message).then(
      (response) => {
        let utils_string = response.data
        //window.location.reload(); // cette ligne fait recharger la page mais du coup le state se réinitialise et je n'ai pas message_from_server.
        setFormState({lastname: "",
          firstname: "",
          email: "",
          phone: "",
          message: "",
          loading: false,
          message_from_server: utils_string}
        );
      },
    );
  }

  const formik = useFormik({
    initialValues: {
      lastname: "",
      firstname: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    
    onSubmit: (values) => {
      HandleMessage(values);
    }
  });

  return (


    <div className="ContactNoConnectComponent">

    <div className="info-block">

        <div className="changing-background">
           <h1>Nous sommes à votre écoute&nbsp;!</h1>
        </div>
        
        
        <div className="utils1"><p className="text-align-left">Vous avez une question ou souhaitez une démonstration de notre produit&nbsp;? </p>
        
        <p className="text-align-left">N&rsquo;hésitez pas à compléter ce formulaire, nous serons là pour vous répondre.</p>
        
        </div>
        </div>
    
    <div className="main_container">
    
    <form onSubmit={formik.handleSubmit}>
         {/**
             * 
            <p>Nom*</p>
            <TextField
              fullWidth         
              id="lastname"
              name="lastname"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
            <p>Prénom*</p>
            <TextField
              fullWidth         
              id="firstname"
              name="firstname"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              error={formik.touched.firstname && Boolean(formik.errors.firstname)}
              helperText={formik.touched.firstname && formik.errors.firstname}
            />
             *
             */}
             
        <QokkaTextInput name="lastname" label="Nom" placeholder="ex&nbsp;: Dupont" requiredDisplay={true} formik={formik} />
        
        <QokkaTextInput name="firstname" label="Prénom" placeholder="ex&nbsp;: Paul" requiredDisplay={true} formik={formik} />

        <QokkaTextInput name="email" label="Email" placeholder="ex&nbsp;: paul.dupont@mail.com" requiredDisplay={true} formik={formik} />

        <QokkaTextInput name="phone" label="Téléphone (optionnel)" placeholder="+XX XXXXXXXXX" requiredDisplay={false} formik={formik} />
       
        <QokkaTextInput name="message" label="Message" multiline={true} placeholder="Bonjour, c'est toujours simple et c'est gratuit !" requiredDisplay={true} formik={formik} />
        
        <div className="center">
        {(formState.message_from_server !== "") && (
                <div className="form-group">
                  <div className="message_from_server">
                    {formState.message_from_server}
                  </div>
                </div>
              )}

          <br></br>
        <Button className="pay btn" color="primary" variant="contained" type="submit">Envoyer</Button>
        <br></br>
        {
          /** <Button color="primary" variant="contained" type="submit">
          Envoyer
        </Button>
        */
        }
        
        </div>

        
        </form>

        <div className="div_lettre_image_et_text">
          <div className="container_image_lettre"><img src={letter_svg}></img></div>
          
          <p>Email du support</p>
          <a href="mailto:support@qokka.fr">support@qokka.fr</a>

        </div>
        
      </div>
    </div>


    
  );

}

export default ContactNoConnect;