import { Button } from "@mui/material";
import LogoQOKKA from "../../icons/logoQOKKA.svg";
import i18n from "../../i18n";
import './KYC_Qokka_pub.css';
import red_not_valid_icon from "../../icons/9UploadDoc/red_not_valid_icon.svg"
import valid_green_icon from "../../icons/connected-badge.svg"
import not_recognised_orange_icon from "../../icons/9UploadDoc/non_reconnu.svg"
import {docu_correspondance} from "../../utils/docu_correspondance_utils";
const baseRouteUrl = "/" + i18n.language;

export interface UtilsAfterAnalyseProps{
    document_type: string,
    message_from_server_after_upload: string,
    score: number | string | null,
    code:number,
    refreshTheCounter: () => void,
}

export const UtilsAfterAnalyse : React.FunctionComponent<UtilsAfterAnalyseProps> = (props: UtilsAfterAnalyseProps) => {

    console.log(props.score, "score from UtilsAfterAnalyse")

    props.refreshTheCounter();

    var component_to_return = <div id="container_result_analysis">
    <img src={red_not_valid_icon} ></img>
    <h3 id="h3_message_result_after_analysis"> Votre {docu_correspondance(props.document_type)} n&rsquo;a pas été correctement analysé{props.document_type === "id_document"&&"e"}{props.code != 4243 ? ", veuillez contacter le support.":", veuillez racheter des crédits de consulation."}</h3>
    <p dangerouslySetInnerHTML={{__html: props.message_from_server_after_upload}}></p>
  </div>;
     
     
     
     switch (props.score) {

        case 100:

            component_to_return = <div id="container_result_analysis">
            <img src={valid_green_icon} ></img>
            <h3 id="h3_message_result_after_analysis">Votre {docu_correspondance(props.document_type)} est conforme</h3>
            </div>
            
          break;

        case -100:

            component_to_return = <div id="container_result_analysis">
                <img src={red_not_valid_icon} ></img>
                <h3 id="h3_message_result_after_analysis">Votre {docu_correspondance(props.document_type)} n&rsquo;est pas conforme</h3>
          
            </div>
            break;

        case 0: // 
            component_to_return = <div id="container_result_analysis">
            <img src={not_recognised_orange_icon} ></img>
            <h3 id="h3_message_result_after_analysis">Votre {docu_correspondance(props.document_type)} n&rsquo;a pas été reconnu{props.document_type === "id_document"&&"e"}</h3>
          
          </div> 
          break; 
    /*
    switch (props.message_from_server_after_upload) {
        case "Analysis successful.":
            if (props.score === 100) {

            component_to_return = <div id="container_result_analysis">
            <img src={valid_green_icon} ></img>
            <h3 id="h3_message_result_after_analysis"> Votre {docu_correspondance(props.document_type)} est conforme</h3>
          
            </div>}
            else {
                component_to_return = <div id="container_result_analysis">
                <img src={red_not_valid_icon} ></img>
                <h3 id="h3_message_result_after_analysis"> Votre {docu_correspondance(props.document_type)} n'est pas conforme</h3>
          
            </div>
            }
          break;

        case "No Model detected for the given data": // 
            component_to_return = <div id="container_result_analysis">
            <img src={not_recognised_orange_icon} ></img>
            <h3 id="h3_message_result_after_analysis"> Votre {docu_correspondance(props.document_type)} n'a pas été reconnu</h3>
          
          </div>
          break; */

        
    }
      

    return <div> {component_to_return} </div>

}