
import { donnees_bruts, utils_list_package } from "../utils/constants"
import i18n from "../i18n";
import UserService from "../services/user.service";
import icon_svg_bleu_utils from "../icons/15Resiliation/icon_bleu_utils.svg"
import { useEffect, useState } from "react";
import "./15QokkaResiliation.component.css"
import { Button, CircularProgress } from "@mui/material";
import userService from "../services/user.service";
// #desired_element { z-index: 99 !important; }

const baseRouteUrl = "/" + i18n.language;

export const ResiliationComponent: React.FunctionComponent = () => {
    const [resiliating, setResiliating] = useState(false)
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [fetchingData, setFetchingData] = useState({
        user : {
            firstname: "",
            lastname: "",
            forfait_actuel: "",
            credit_mensuel_du_forfait: 0,
            credit_utilises_du_mois: 0,
            Ouverture_du_compte: "",
            Date_de_renouvellement: "",
            extra_credits_used: 0,
            extra_credits_available: 0,
            backgroundColor: "",
            Cancel_at_period_end: false,
        }
    })

    const OnResiliate = async () => {
        if (!resiliating)
        {
            setResiliating(true)

            const res = await userService.resiliatiatePackage().then((res) => {
                setResiliating(false)

                if (res.status == 200)
                {
                    let queryString = "subscription="+fetchingData.user.forfait_actuel
                    window.location.href = baseRouteUrl + "/confirm-end-package?" + queryString
                }
                else
                    window.location.href = baseRouteUrl + "/my-account"
            })
            .catch (() =>
            {
                setResiliating(false)
                setMessage("Erreur lors de la résiliation. Merci de contacter notre support.")
            })
        }
    }

    const OnCancelResiliation = async () => {
        if (!resiliating)
        {
            setResiliating(true)

            const res = await userService.cancelResiliatiation().then(() => {
                setResiliating(false)
                window.location.href = baseRouteUrl + "/my-account"
            })
            .catch (() =>
            {
                setResiliating(false)                
                setMessage("Erreur lors de l&rsquo;annulation de la résiliation. Merci de contacter notre support.")
            })
        }
    }

    useEffect(()=>{
        UserService.fetch_1_light3().then((response: any) => {
            setFetchingData({
                user : {
                    firstname: response.data.user.firstname,
                    lastname: response.data.user.lastname,
                    forfait_actuel: response.data.user.forfait_actuel,
                    credit_mensuel_du_forfait: response.data.user.credit_mensuel_du_forfait,
                    credit_utilises_du_mois: response.data.user.credit_utilises_du_mois,
                    Ouverture_du_compte: response.data.user.Ouverture_du_compte,
                    Date_de_renouvellement: response.data.user.Date_de_renouvellement,
                    extra_credits_used: response.data.user.extra_credits_used,
                    extra_credits_available: response.data.user.extra_credits_available,
                    backgroundColor: response.data.user.backgroundColor,
                    Cancel_at_period_end: response.data.user.Cancel_at_period_end,
                }
            })
            setLoading(false)
        }).catch(
            (error) => {
                console.log(error)
                setMessage(error.toString())
                setLoading(false)
            }
        )
    }
    ,
    [])
    
    let planRemaining = fetchingData.user.credit_mensuel_du_forfait ? fetchingData.user.credit_mensuel_du_forfait - fetchingData.user.credit_utilises_du_mois : undefined
    let plan=<></>

    if (fetchingData.user.forfait_actuel)
    {
        if (planRemaining == 0)
        {
            plan = <div className="used_credit" style={{backgroundColor: fetchingData.user.backgroundColor}}>
                Aucune consultation restante<p style={{margin: 0, fontWeight: 'normal', textTransform: "none"}}>résiliation effective immédiatement</p>
            </div>
        }
        else if (planRemaining == 1)
        {
            plan = <div className="used_credit" style={{backgroundColor: fetchingData.user.backgroundColor}}>
                {planRemaining} consultation restante<p style={{margin: 0, fontWeight: 'normal', textTransform: "none"}}>utilisables jusqu&rsquo;au {fetchingData.user.Date_de_renouvellement}{!fetchingData.user.Cancel_at_period_end && " en cas de résiliation" }</p>
            </div>
        }
        else
        {
            plan = <div className="used_credit" style={{backgroundColor: fetchingData.user.backgroundColor}}>
                {planRemaining} consultations restantes<p style={{margin: 0, fontWeight: 'normal', textTransform: "none"}}>utilisables jusqu&rsquo;au {fetchingData.user.Date_de_renouvellement}{!fetchingData.user.Cancel_at_period_end && " en cas de résiliation" }</p>
            </div>
        }
    }

    let extraRemaining = fetchingData.user.extra_credits_available ? fetchingData.user.extra_credits_available - fetchingData.user.extra_credits_used : undefined
    let extra=<></>

    if (extraRemaining)
    {
        if (extraRemaining == 1)
        {
            extra = <div className="extra_credit">
                {extraRemaining} consultation extra restante<p style={{margin: 0, fontWeight: 'normal', textTransform: "none"}}>(conservée après résiliation)</p>
            </div>
        }
        else
        {
            extra = <div className="extra_credit">
                {extraRemaining} consultations extra restantes<p style={{margin: 0, fontWeight: 'normal', textTransform: "none"}}>(conservées après résiliation)</p>
            </div>
        }
    }

    if (loading)
    {
        return <div className="resiliation"><div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div></div>
    }
    if (message !== "")
    {
        return <div className="resiliation"><p className="ErrMessage Mui-error" dangerouslySetInnerHTML={{__html: message }} /></div>
    }
    return <div className="resiliation">

        <p className="hello medium">Bonjour {fetchingData.user.firstname} {fetchingData.user.lastname}</p>
        
        {/** <img alt="background image"></img>*/}
        
        <div className="background_div"> 
        { fetchingData.user.Cancel_at_period_end ? <h1>Annuler la résiliation de mon abonnement</h1>: <h1>Résilier mon abonnement</h1>}
        </div>
            <div className="current_package_details">
            { fetchingData.user.forfait_actuel ? <>
            <h2 className="current_package_text medium">Votre forfait actuel</h2>
            <p className="current_package medium">{fetchingData.user.forfait_actuel}</p>
            </> : <h2 className="current_package_text medium">Aucun forfait en cours</h2> }                            

            {plan}
            {extra}
            
            <div className="ul_div_container">
                
            <ul className="ul_dates_account">
                <li><span style={{textTransform: "uppercase"}}>Ouverture du compte</span> le&nbsp;{fetchingData.user.Ouverture_du_compte}</li>
                { fetchingData.user.forfait_actuel && <li><span style={{textTransform: "uppercase"}}>{fetchingData.user.Cancel_at_period_end ? "Fin de forfait" : "Date de renouvellement" }</span> le&nbsp;{fetchingData.user.Date_de_renouvellement}</li> }
            </ul>
            </div>
        </div>
        { fetchingData.user.Cancel_at_period_end && <div className="cancel_container"><Button variant="contained" className="btn medium resiliate" onClick={OnCancelResiliation}>{ resiliating ? <><CircularProgress sx={{color: "rgb(96, 101, 113) !important"}} /><span style={{width:"10px"}} /></> : <span style={{width:"50px"}} />}Annuler la résiliation de mon abonnement<span style={{width:"50px", height: "40px"}} /></Button></div> }
        { fetchingData.user.forfait_actuel && !fetchingData.user.Cancel_at_period_end && <div className="under_div_stats">
            <h2 className="subtitle medium">Conditions de résiliation</h2>

            <div className="conditions_container">
                <ul>
                    <li>Après validation de la résiliation, votre abonnement se terminera à la date de renouvellement.</li>
                    <li>Votre solde de consultations restantes, restera actif juqu&rsquo;à la veille de la date de renouvellement, passé cette date votre solde ne sera plus utilisable.</li>
                </ul>
                <ul>
                    <li>A partir de la date de renouvellement, s&rsquo;il vous reste du crédit, il sera perdu.</li>
                    <li>Si votre solde de consultations est vide, la résiliation se fait directement à la validation de cette action.</li>
                </ul>
            </div>
            <Button variant="contained" className="btn medium resiliate" onClick={OnResiliate}>{ resiliating ? <><CircularProgress sx={{color: "rgb(96, 101, 113) !important"}} /><span style={{width:"10px"}} /></> : <span style={{width:"50px"}}></span>}Résilier mon abonnement<span style={{width:"50px", height: "40px"}} /></Button>
        </div> }

    </div>

}