import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation } from 'react-router-dom';
import i18n from "./i18n.js"
import LogoQOKKA from "./icons/logoQOKKA.svg"
import MenuIcon from "./icons/menu.svg"
import UserIcon from "./icons/user.svg"
import CloseIcon from "./icons/close.svg"
import PreviousIcon from "./icons/previous.svg"
import ConnectedBadgeIcon from "./icons/connected-badge.svg"
import AddDocumentIcon from "./icons/add_document.svg"
import "./ResponsiveAppBar.css"
import { url } from 'inspector';
import {pages, pagesConnected, pagesConnected2} from './utils/constants'
import { useTheme } from '@emotion/react';
import { Fab, Icon, MenuList, Popover, Slide, SlideProps } from '@mui/material';
import UserService from "./services/user.service";
import clsx from 'clsx';
import { routes } from '../src/utils/routes';
import { flexbox } from '@mui/system';
import eventBus from './common/EventBus';
import authService from './services/auth.service';

type SettingsMenuItem = {
  style: "contained" | "outlined" | "text" | undefined,
  urlKey: string,
}

const settings_connected: { [key: string]: SettingsMenuItem } = {
  "menu_modify_package": { "style": "contained", "urlKey": "change_package"},
  "menu_log_out": { "style": "outlined", "urlKey": "home"} // 
};

const settings_not_connected: { [key: string]: SettingsMenuItem } = {
  "menu_sign_up": { "style": "contained", "urlKey": "packages"},
  "menu_log_in": { "style": "outlined", "urlKey": "login"}
};

const SlideTransition = function slideTransition(props:SlideProps) {
  return <Slide direction="left" {...props} />;
};

interface PreviousCallbackType {
  (): void;
}

type ResponsiveAppBarPropsType = {
  windowWidth:number,
  locationKey:string,
  connected: boolean,
  previousCallback?:PreviousCallbackType,
  hideMenu?: boolean
}

const ResponsiveAppBar: React.FunctionComponent<ResponsiveAppBarPropsType> = (props:ResponsiveAppBarPropsType) => {
//  const { ref, width, height } = useResizeObserver<HTMLDivElement>();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElConnectedNav, setAnchorElConnectedNav] = React.useState(null);

  const handleOpenNavMenu = (e:any) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenConnectedNavMenu = (e:any) => {
    setAnchorElConnectedNav(e.currentTarget);
  };

  const handleCloseConnectedNavMenu = () => {
    setAnchorElConnectedNav(null);
    console.log("handleCloseConnectedNavMenu")
  };

  const handleCloseConnectedNavMenuReloadUploadVersion = () => {
    setAnchorElConnectedNav(null);
    console.log("Reloading upload version before if");
    if (window.location.pathname.includes("upload")) { // if we are on the upload page, we need to refresh the page
      window.location.reload()
      console.log("Reloading upload version");
    }
  };

  React.useEffect(() => {
    // Close the menu when not displayed anymore
    if(props.windowWidth >= 1400) {
      setAnchorElNav(null);
    }
  }, [props.windowWidth]);

  const baseRouteUrl = "/" + i18n.language;
  var userMenuIndex = 0;

  // Fonctionne si la page se recharge ce qui n'est pas toujours le cas.
  var context = window.location.href
  var theme = useTheme();
  const connected = props.connected;

  const displayName = authService.getDisplayName();

  return (
  <>
    <AppBar position='sticky' sx={{backgroundColor: '#fff',
     boxShadow: '0px 3px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 8px 10px 0px rgba(0,0,0,0.12)', px: '1rem', py: '1rem' }}>
        <Toolbar disableGutters sx={{flexWrap: 'wrap', paddingRight: props.hideMenu && props.previousCallback ? "calc(55px + 0.5rem)" : 0 }}>
       {
          props.previousCallback && <Tooltip title="Précédent"><Box id="previous" sx={{ flexGrow: 0, display: 'flex', marginRight: '0.5rem' }}>
              <IconButton onClick={props.previousCallback} size="large" sx={{p: 0, width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}>
                <img alt="previous" src={PreviousIcon} style={{width:"20px"}} />
              </IconButton>              
          </Box></Tooltip>
        }
          <Box sx={{ flexGrow: props.hideMenu ? 1 : 0, my: "0.5rem", textAlign: "center" }}>
            <Link to={ baseRouteUrl + "/"}>
                <img alt="Logo QOKKA" src={LogoQOKKA} width="200px"/>
            </Link>
          </Box>
          { !props.hideMenu && !connected && <Box id="menu-pages" sx={{ pt: 2, pl: 1, display: { xs: 'none', md: 'flex', flexGrow: 1, justifyContent: 'space-around' } }}>           
            {
              Object.entries(pages).map(page => {
                const [key, value] = page
                return <Button component={Link} to={baseRouteUrl + routes[value].path}
                    className={clsx((value === props.locationKey) && "selected-menu")}
                    key={key}
                    onClick={handleCloseNavMenu}
                    sx={{ m: '0px !important', p: 0, color: '#383e4d', display: 'block', fontSize: '18px', textTransform: 'none' }}
                >
                    {i18n.t(key)}
                </Button>
              })
            }
            </Box>
          }
          {
            !props.hideMenu && connected && <Box id={connected ? "menu-pages-connected" : "menu-pages"}
              sx={{ pt: 2, pl: 1, display: { xs: 'none', md: 'flex', flexGrow: 1, justifyContent: 'flex-end' } }}>           
            {
              Object.entries(pagesConnected).map(page => {
                const [key, value] = page
                if (value == 'upload')
                {
                  return <Button id="upload-button-large" variant="contained" component={Link} to={baseRouteUrl + routes[value].path}
                      key={key}
                      onClick={handleCloseConnectedNavMenuReloadUploadVersion}
                      startIcon={<img id="plus-icon" alt="Plus icon" src={AddDocumentIcon}></img>}
                  >
                    {i18n.t(key)}
                  </Button>
                }
              })
            }
            </Box>
          }
          { !props.hideMenu && <Box id={connected ? "navbar-warp-connected" : "navbar-warp"} sx={{ display: 'flex', flexGrow: { xs:1, md:0 }, flexWrap: 'nowrap' }}>
            { !connected && <Box id="menu-user" sx={{ pt: 2, pl: 1, display: { xs: 'none', lg: 'flex', flexGrow: 1, justifyContent: 'space-around' } }}>
            {
              Object.entries(settings_not_connected).map((page) => {
                  const [key, value] = page
                  return <Button variant={value.style} component={Link} to={baseRouteUrl + routes[value.urlKey].path}
                      className="menu-btn"
                      key={key}
                      onClick={handleCloseNavMenu}
                      sx={{ ml: '10px !important', p: 0, color: '#383e4d', display: 'block', fontSize: '18px', textTransform: 'none' }}
                  >
                      {i18n.t(key)}
                  </Button>
                  })
            }
            </Box> }
            { connected && props.windowWidth < 900 && <Tooltip title="Ajouter un document"><Box sx={{ display: 'flex', width: 55, justifyContent: 'center' }}><Button id="upload-button-small" variant="contained" component={Link} to={baseRouteUrl + routes['upload'].path}
                      key='upload'
                      onClick={handleCloseConnectedNavMenuReloadUploadVersion}
                      startIcon={<img id="plus-icon" alt="Plus icon" src={AddDocumentIcon}></img>}
                      sx={{padding: 0}}
                  >
            </Button></Box></Tooltip> }
            <Box id="icon-user" sx={{ flexGrow: 0, display: { xs: 'flex', lg: connected ? 'flex' : 'none'} }}>
              { connected && <Tooltip title="Menu">
                  <IconButton size="large" sx={{ p: 0, width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}
                    onClick={handleOpenConnectedNavMenu}
                  >
                    <>
                      <img alt="login" src={UserIcon} style={{width:"30px"}} />
                      <img alt="connected" src={ConnectedBadgeIcon} style={{position: "absolute", width: "23px", right: "3px", bottom: "7px"}} />
                    </>
                  </IconButton>
                </Tooltip>
              }
              {
                !connected && <IconButton size="large" sx={{ p: 0, width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}>
                  <img alt="login" src={UserIcon} style={{width:"30px"}} />
                </IconButton>
              }
            </Box>
            {
              !connected && <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' }}}>
                <Tooltip title="Menu"><IconButton
                  size="large"
                  aria-label="not-connected-menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ p: '0', width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}
                >
                  <img alt="menu" src={MenuIcon} style={{width:"45px"}} />
                </IconButton></Tooltip>
                <Menu
                  sx={{ mt: '45px', display: { xs: 'none', md: 'flex', lg: 'none'} }}
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 30,
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                >
                  {
                  Object.entries(settings_not_connected).map((page) => {
                    const [key, value] = page

                    return <Button component={Link} to={baseRouteUrl + routes[value.urlKey].path}
                        key={key}
                        onClick={handleCloseNavMenu}
                        sx={{ m: '0px !important', p: 0, color: '#383e4d', display: 'block', fontSize: '18px', textTransform: 'none' }}
                    >
                        {i18n.t(key)}
                    </Button>
                    })
                  }
                </Menu>
              </Box>
            }
          </Box> }
        </Toolbar>
    </AppBar>
    { !connected &&
      <Popover 
        id="menu-mobile-appbar"
        sx={{display: { xs: 'flex', md: 'none' }}}
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 30,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={SlideTransition}
        transitionDuration={250}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
      >
        <AppBar position='sticky' sx={{backgroundColor: '#fff',
          boxShadow: '0px 3px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 8px 10px 0px rgba(0,0,0,0.12)', px: '1rem', py: '1rem' }}>
            <Toolbar disableGutters sx={{
              flexWrap: 'wrap'}}>
              <Box sx={{ flexGrow: 0, my: "0.5rem" }}>
                <Link to={ baseRouteUrl + "/"}>
                    <img alt="Logo QOKKA" src={LogoQOKKA} width="200px"/>
                </Link>
              </Box>          
              <Box sx={{ pt: 2, pl: 1, display: { xs: 'none', md: 'flex', flexGrow: 1, justifyContent: 'space-around' } }}>
                {
                Object.entries(pages).map(page => {
                    const [key, value] = page
                    return <Button component={Link} to={baseRouteUrl+routes[value].path}
                        className={clsx((value === props.locationKey) && "selected-menu")}
                        key={key}
                        onClick={handleCloseNavMenu}
                        sx={{ m: '0px !important', p: 0, color: '#383e4d', display: 'block', fontSize: '18px', textTransform: 'none' }}
                    >
                        {i18n.t(key)}
                    </Button>
                })
                }
              </Box>
              <Box id="navbar-warp" sx={{ display: { xs: 'flex', flexGrow: 1, flexWrap: 'nowrap' } }}>
                <Box id="icon-user" sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none'} }}>
                  <IconButton size="large" sx={{ p: 0, width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}>
                    <img alt="login" src={UserIcon} style={{width:"30px"}} />
                  </IconButton>              
                </Box>
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' }}}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleCloseNavMenu}
                    color="inherit"
                    sx={{ p: '0', width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}
                  >
                    <img alt="menu" src={CloseIcon} style={{width:"30px"}} />
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
        </AppBar>
        <div className='menu-container'>
          <MenuList className="mobile-menu" sx={{display: { xs: "flex", md: "none" }, padding: 0, left: 0, flexDirection: "column"}}>
            {
              Object.entries(pages).map(page => {
                  const [key, value] = page
                  return <Button className={clsx(["mobile-menu-button", (value === props.locationKey) && "selected-menu"])}
                  sx={{ margin: "0px !important", padding: 0 }} component={Link} to={baseRouteUrl + routes[value].path} onClick={handleCloseNavMenu}>{i18n.t(key)}</Button>
                  })
            }
          </MenuList>
          <Box id="menu-user" sx={{ pt: 2, pl: 1, display: { xs: 'flex', md: 'none' } }}>
          { 
            Object.entries(settings_not_connected).map((page) => {
                const [key, value] = page
                return <Button className="menu-btn" variant={value.style} component={Link} to={baseRouteUrl + routes[value.urlKey].path}
                    key={key}
                    onClick={handleCloseNavMenu}
                    sx={{ ml: '10px !important', p: 0, color: '#383e4d', display: 'block', fontSize: '18px', textTransform: 'none' }}
                >
                    {i18n.t(key)}
                </Button>
                })
          }
          </Box>
        </div>
      </Popover>
    }
    { connected &&
      <Popover 
        id="menu-connected-appbar"
        anchorEl={anchorElConnectedNav}
        anchorOrigin={{
          vertical: 30,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={SlideTransition}
        transitionDuration={250}
        open={Boolean(anchorElConnectedNav)}
        onClose={handleCloseConnectedNavMenu}
      >
      <AppBar position='sticky' sx={{backgroundColor: '#fff',
        display: { xs: 'flex', md: 'none' },
        boxShadow: '0px 3px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 8px 10px 0px rgba(0,0,0,0.12)', px: '1rem', py: '1rem' }}>
          <Toolbar disableGutters sx={{
            flexWrap: 'wrap'}}>
            <Box sx={{ flexGrow: 0, my: "0.5rem" }}>
              <Link to={ baseRouteUrl + "/"}>
                  <img alt="Logo QOKKA" src={LogoQOKKA} width="200px"/>
              </Link>
            </Box>          
            <Box id="navbar-warp-connected" sx={{ display: { xs: 'flex', flexGrow: 1, flexWrap: 'nowrap' } }}>
              { connected && <Tooltip title="Ajouter un document"><Box sx={{ display: 'flex', width: 55, justifyContent: 'center' }}>
                  <Button id="upload-button-small" variant="contained" component={Link} to={baseRouteUrl + routes['upload'].path}
                      key='upload'
                      onClick={handleCloseConnectedNavMenuReloadUploadVersion}
                      startIcon={<img id="plus-icon" alt="Plus icon" src={AddDocumentIcon}></img>}
                      sx={{padding: 0}}
                  >
                </Button></Box></Tooltip> }
              <Box id="icon-user" sx={{ flexGrow: 0, display: { xs: 'flex', lg: connected ? 'flex' : 'none'} }}>
                <IconButton size="large" sx={{ p: 0, width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}
                  onClick={handleOpenConnectedNavMenu}
                >
                  <>
                    <img alt="login" src={UserIcon} style={{width:"30px"}} />
                    <img alt="connected" src={ConnectedBadgeIcon} style={{position: "absolute", width: "23px", right: "3px", bottom: "7px"}} />
                  </>
                </IconButton>
              </Box>
              <Box sx={{ flexGrow: 0, display: 'flex' }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleCloseConnectedNavMenu}
                  color="inherit"
                  sx={{ p: '0', width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}
                >
                  <img alt="menu" src={CloseIcon} style={{width:"30px"}} />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, width: '100%', position: 'absolute', padding: '1rem', justifyContent: 'flex-end'}}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleCloseConnectedNavMenu}
            color="inherit"
            sx={{ p: '1rem', width: '55px', height: '55px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}
          >
            <img alt="menu" src={CloseIcon} style={{width:"20px"}} />
          </IconButton>
      </Box>
      <div className='menu-title'>
        <div className='name-container'>
          <span className="sub-title medium">COMPTE PERSONNEL</span>
          <Tooltip title="Editer les informations du compte"><Button className="name mobile-menu-button medium"
               sx={{ margin: "0px !important", padding: 0 }} component={Link} to={baseRouteUrl + routes["edit_profile"].path} onClick={handleCloseConnectedNavMenu}>{displayName}</Button></Tooltip>
        </div>
        <div>
          <Box sx={{ flexGrow: 0, display: 'none' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleCloseConnectedNavMenu}
              color="inherit"
              sx={{ p: '0', width: '30px', height: '30px', color: '#0061d1 !important', "&:hover": { color: "#e3f0ff" } }}
            >
              <img alt="menu" src={CloseIcon} style={{width:"15px"}} />
            </IconButton>
          </Box>
        </div>        
      </div>
      <hr />
      <div className='menu-container'>
      <MenuList className="mobile-menu" sx={{display: "flex", padding: 0, left: 0, flexDirection: "column"}}>
        {
          Object.entries(pagesConnected2).map(page => {
              const [key, value] = page
              return <Button className={clsx(["mobile-menu-button", (value === props.locationKey) && "selected-menu"])}
               sx={{ margin: "0px !important", padding: 0 }} component={Link} to={baseRouteUrl + routes[value].path} onClick={handleCloseConnectedNavMenu}>{i18n.t(key)}</Button>
              })
        }
      </MenuList>
      <Box id="menu-user" sx={{ pt: 2, pl: 1, display: 'flex' }}>
      { 
        Object.entries(settings_connected).map((page) => {
            const [key, value] = page

            if (key == "menu_log_out")
            {
              return <Button className="menu-btn" variant={value.style} component={Link} to={baseRouteUrl + (routes[props.locationKey].noConnectionRequired ? routes[props.locationKey].path : routes[value.urlKey].path)}
                  key={key}
                  onClick={() => {
                     eventBus.dispatch("logout"); 
                     handleCloseConnectedNavMenu();                     
                  }}
                  sx={{ ml: '10px !important', p: 0, color: '#383e4d', display: 'block', fontSize: '18px', textTransform: 'none' }}
              >
                  {i18n.t(key)}
              </Button>
            }
            return <Button className="menu-btn" variant={value.style} component={Link} to={baseRouteUrl + routes[value.urlKey].path}
                key={key}
                onClick={handleCloseConnectedNavMenu}
                sx={{ ml: '10px !important', p: 0, color: '#383e4d', display: 'block', fontSize: '18px', textTransform: 'none' }}
            >
                {i18n.t(key)}
            </Button>
            })
      }
      </Box>
      </div>
      
  </Popover>
  }
</>
  );
};
export default ResponsiveAppBar;
