import axios from "axios";
import { userInfo } from "os";
import { Redirect } from "react-router-dom";
import eventBus from "../common/EventBus";
import config from "../config/config"
import IUser from "../types/user.type";
import authHeader, { QokkaCallGet, QokkaCallPost } from "./auth-header";
import userService from "./user.service";

const API_URL = config.ApiUrl();

class AuthService {
  login(email: string, password: string) {
    return QokkaCallPost(API_URL + "/auth/login", {
        email: email,
        password: password
      })
      .then(response => {
        if (response.status == 200) {
          let respData = response.data
          console.log("here 6",response)
          localStorage.setItem("token", respData.token)
          return respData.token;
        }
        else
        {
          localStorage.removeItem("token");
          return "";
        }
      }).catch(e => {

        console.log("here 7",e )
        console.log(e.response.data)
        
        throw e;

        // errors here often arise in the response due to the server (rose-api) being down
        // We are just catching the error and doing nothing with it.
        // It allows the user to navigate the app. 
      })
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  register(productId:string, email: string, password: string,companyName: string, country: string, city: string, zipCode: string, sirenNumber: string,
    subjectToVAT: boolean, intraCommunityVATNumber: string, lastName: string, firstName: string, receiveCom: boolean ) {
      return QokkaCallPost(API_URL + "/register/register", {
      productId: productId,
      password: password, 
      email: email, 
      firstName: firstName,
      lastName: lastName, 
      companyName: companyName,
      country: country,
      city: city,
      zipCode: zipCode, 
      sirenNumber: sirenNumber, 
      intraCommunityVATNumber: intraCommunityVATNumber, 
      subjectToVAT: subjectToVAT, 
      receiveCom: receiveCom
    });
  }

  reinitialisationPassword(email:string){
    return QokkaCallPost(API_URL + "/auth/getEmailWithLinkToChangePassword", {
      email: email 
    })
  }
  
  change_password_from_mail_link(password: string, user_id: string | null, token: string | null) {
    return QokkaCallPost(API_URL + "/auth/change_password_from_mail_link", {
      user_id: user_id,
      password: password,
      token: token
  })
}

  contact(lastName: string, firstName: string,email: string, phone: string,
    message : string) {
    return QokkaCallPost(API_URL + "/contact/send_contact_form", {
      lastName: lastName,
      firstName: firstName,
      email: email,
      phone: phone,
      message: message
    });
  }

  send_paiement_infos_to_back(
    emailUser: string,
    selectedPackage: string,
    paiementMethod: string,
    cardNumber: number | undefined,
    dateExpiration: Date | undefined,
    cvv: number | undefined,
    nameOfCardOwner: string,
    country: string){//

    console.log("paiement appel axios here")
    return QokkaCallPost(API_URL + "/pay/main_paiement_method", {
      emailUser: emailUser,
      selectedPackage: selectedPackage,
      paiementMethod: paiementMethod,
      cardNumber: cardNumber,
      dateExpiration: dateExpiration,
      cvv: cvv,
      nameOfCardOwner: nameOfCardOwner,
      country: country
    })
    ;
  }

  getCurrentUser() : IUser | null
  {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }

  getDisplayName() : string | undefined
  {
    let user = this.getCurrentUser();

    return user && user?.firstName && user?.lastName ? user?.firstName + ' ' + user?.lastName : undefined
  }

  checkToken() {
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + "/auth/check-token", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })      
  }
}

export default new AuthService();