import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import BackToTop from './icons/backToTop.svg'

function ScrollTop(props:any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event:any) => {
    var Scroll = require('react-scroll');
    var scroll = Scroll.animateScroll;
    
    scroll.scrollToTop({ smooth: true, duration: 500 })
  };

  return (
    <Zoom in={trigger} style={{display:"flex", flexDirection:"row-reverse"}}>
      <Box 
        id="scroll-to-top"       
        role="presentation"
        sx={{ display: 'block', position: 'sticky', bottom: "2rem", right: "2rem", marginBottom: "2rem", zIndex: 1050 }}
      >
        <Fab color="primary" size="small" aria-label="Retourner en haut" sx={{marginRight:"2rem", backgroundColor: "#66cde2", borderColor: "#66cde2 !important", "&:hover": { backgroundColor: "#80d9ec !important",
    boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%), 0px 2px 10px 2px #80d9ec !important;" } }} onClick={handleClick}>
          <img alt="Retourner en haut" src={BackToTop} style={{width: "40px"}}/>
        </Fab>
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ScrollTop;