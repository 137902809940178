import { StaticContext } from 'react-router';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import i18n from "../i18n"
import UserService from "../services/user.service";
import React, { ReactNode, useState } from 'react';
import { error } from 'console';
import authService from '../services/auth.service';
import { routes } from './routes';

const baseRouteUrl = "/" + i18n.language;

export interface PropsPrivateRoute{
    key: string,
    exact: boolean,
    path: string,
    component: any // c'est pas bien mais, le type suivant ne fonctionne pas
    // React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>> | undefined
}

export const PrivateRoute: React.FunctionComponent<PropsPrivateRoute> = (props : PropsPrivateRoute) => {
  const token = localStorage.getItem("token");

  if (token)
    return <Route key={props.key} exact={props.exact} path={props.path} component={props.component} />
  else
    return <Route key={props.key} exact={props.exact} path={props.path}><Redirect to={{ pathname: baseRouteUrl + routes["login"].path, state: { from: props.path } }} /></Route>
}
