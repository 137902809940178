import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";

import { AppBar, Box, Button } from "@mui/material";
import { Link } from 'react-router-dom';
import i18n from "../i18n";
import LogoQOKKA from "../icons/logoQOKKA.svg";
import qbg from '../icons/Qbg.svg';
import letter_mail from '../icons/letter_mail.svg';
import './26QokkaConfirmReinitialisation.component.css';

interface RouterProps {
  history: string;
}

const baseRouteUrl = "/" + i18n.language;

type Props = RouteComponentProps<RouterProps>;

type State = {
  email: string,
  password: string,
  loading: boolean,
  message: string
};

export default class NewMdp extends Component<Props, State> {


  render() {

    return (

     <div className="mdp_mail_sent">

     <div className="main_container">
     
     <div className="background_div"> 
        <h2 style={{fontWeight:"bold"}}>Réinitialiser votre mot de passe</h2>
        </div>
     <div>

      </div>
    
        <div className="merci_div">
            {/**j 'essaye de m'entrainer ici pour le style */}
            <img style={{paddingLeft:"1rem"}} id="image_letter" src={letter_mail}></img>
            <div style={{height:"1rem"}}></div>
            <div><h1 id="merci">Merci!</h1></div>
            <div style={{height:"1rem"}}></div>
            <h6>Un email va être envoyé sur votre boite de réception, 
                d&rsquo;ici quelques instants, afin de réinitialiser votre mot de passe.</h6>
            <div style={{height:"1rem"}}></div>
            <a id="connect_button" href={ baseRouteUrl + "/login"}> Se connecter</a>
            
        </div>

     </div>
     

     </div>
      
    );
  }
}