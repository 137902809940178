
import { Button, CircularProgress } from "@mui/material";
import { height } from "@mui/system";
import { useEffect, useState } from "react";
import i18n from "../i18n";
//import { donnees_bruts } from "../utils/constants";
import "./14QokkaMonCompte.component.css";
import visa_logo from "../icons/visa_logo.svg";
import mastercard_logo from "../icons/mastercard_logo.svg";
import cb_logo from "../icons/cb_logo.svg";
import UserService from "../services/user.service";
import { Message } from "@mui/icons-material";

const baseRouteUrl = "/" + i18n.language;

export const MyAccount: React.FunctionComponent = () => {
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [fetchingData, setFetchingData] = useState({
        user : {
            firstname: "",
            lastname: "",
            email: "",
            adresse: "",
            forfait_actuel: "",
            credit_mensuel_du_forfait: 0,
            credit_utilises_du_mois: 0,
            Ouverture_du_compte: "",
            Date_de_renouvellement: "",
            entreprise: "",
            siren_number: "",            
            extra_credits_used: 0,
            extra_credits_available: 0,
            backgroundColor: "",
            Cancel_at_period_end: false,
            paymentMethod: "",
            cardBrand: "",
            cardLast4: "",
            ibanLast4: "",
        }
    })

    useEffect(()=>{
        UserService.fetch_1().then((response: any) => {
            console.log("reponse fetch1", response)            
            setFetchingData({
                user : {
                    firstname: response.data.user.firstname,
                    lastname: response.data.user.lastname,
                    email: response.data.user.email,
                    adresse: response.data.user.adresse,
                    forfait_actuel: response.data.user.forfait_actuel,
                    credit_mensuel_du_forfait: response.data.user.credit_mensuel_du_forfait,
                    credit_utilises_du_mois: response.data.user.credit_utilises_du_mois,
                    Ouverture_du_compte: response.data.user.Ouverture_du_compte,
                    Date_de_renouvellement: response.data.user.Date_de_renouvellement,
                    entreprise: response.data.user.entreprise,
                    siren_number: response.data.user.siren_number,
                    extra_credits_used: response.data.user.extra_credits_used,
                    extra_credits_available: response.data.user.extra_credits_available,
                    backgroundColor: response.data.user.backgroundColor,
                    Cancel_at_period_end: response.data.user.Cancel_at_period_end,
                    paymentMethod: response.data.user.paymentMethod,
                    cardBrand: response.data.user.cardBrand,
                    cardLast4: response.data.user.cardLast4,
                    ibanLast4: response.data.user.ibanLast4
            }})
            setLoading(false)
        }).catch(
            (error) => {
                console.log(error)
                setMessage(error.toString())
                setLoading(false)
            }
        )
    
    }
    ,
    [])

    if (loading)
    {
        return <div className="qokkaMonCompte"><div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div></div>
    }
    if (message !== "")
    {
        return <div className="qokkaMonCompte"><p className="ErrMessage Mui-error" dangerouslySetInnerHTML={{__html: message }} /></div>
    }

    return <div className="qokkaMonCompte">

        <p className="hello medium">Bonjour {fetchingData.user.firstname} {fetchingData.user.lastname}</p>
        
        {/** <img alt="background image"></img>*/}
        
        <div className="background_div"> 
        <h1>Votre compte QOKKA</h1>
        </div>

        <div className="current_package_details">
            { fetchingData.user.forfait_actuel ? <>
            <h2 className="current_package_text medium">Votre forfait actuel</h2>
            <p className="current_package medium">{fetchingData.user.forfait_actuel}</p>
            </> : <h2 className="current_package_text medium">Aucun forfait en cours</h2> }                            

            { fetchingData.user.forfait_actuel && <div className="used_credit" style={{ backgroundColor: fetchingData.user.backgroundColor}}>{fetchingData.user.credit_utilises_du_mois } {fetchingData.user.credit_utilises_du_mois == 1 ? " consultation utilisée sur " :  " consultations utilisées sur "} {fetchingData.user.credit_mensuel_du_forfait}</div> }
            { fetchingData.user.extra_credits_available && <div className="extra_credit">{fetchingData.user.extra_credits_used } {fetchingData.user.extra_credits_used == 1 ? " consultation extra utilisée sur ": " consultations extra utilisées sur "} {fetchingData.user.extra_credits_available}</div> }
            
            <div className="ul_div_container">
                
            <ul className="ul_dates_account">
                <li><span style={{textTransform: "uppercase"}}>Ouverture du compte</span> le&nbsp;{fetchingData.user.Ouverture_du_compte}</li>
                { fetchingData.user.forfait_actuel && <li><span style={{textTransform: "uppercase"}}>{fetchingData.user.Cancel_at_period_end ? "Fin de forfait" : "Date de renouvellement" }</span> le&nbsp;{fetchingData.user.Date_de_renouvellement}</li> }
            </ul>
            </div>

            <Button variant="contained" className="btn medium" href={baseRouteUrl + "/change-package"}>{ fetchingData.user.forfait_actuel ? "Modifier mon forfait" : "Choisir un forfait" }</Button>
            { fetchingData.user.forfait_actuel &&  <a className="resiliate" style= {{color:"white", textDecoration:"underline"}} href={baseRouteUrl + "/terminate-current-package"}>{ fetchingData.user.Cancel_at_period_end ? "Annuler la résiliation de mon abonnement" : "Résilier mon abonnement" }</a> }

        </div>

        <div className="div_for_flex_display">

        <div className="div_coordonnees">
            <h2 className="subtitle medium">Vos coordonnées</h2>
            
            <p className="user-black medium">{fetchingData.user.firstname} {fetchingData.user.lastname}</p>
            <p className="user-black medium" style={{paddingBottom: "0.8rem"}}>{fetchingData.user.email}</p>
            <p><span style={{textTransform: "uppercase"}}>entreprise</span>&nbsp;{fetchingData.user.entreprise}</p>
            <p><span style={{textTransform: "uppercase"}}>N° SIREN:</span>&nbsp;{fetchingData.user.siren_number === null ? "Non renseigné" : fetchingData.user.siren_number}</p>
            <p style={{paddingBottom: "0.8rem"}}>{fetchingData.user.adresse}</p> 
            <div style={{textAlign:"center"}}><Button variant="contained" className="btn medium" href={baseRouteUrl + "/change-password"}>Modifier mon mot de passe</Button></div>
            
            
        <div style={{textAlign: "center", paddingTop: "0.8rem"}}>
        <a className="blue medium" href={baseRouteUrl + "/edit-profile"} style={{textDecoration:"underline"}}>Corriger mes coordonnées</a>
        </div></div>
        <div className="div_paiement_info" style={{backgroundColor: "#e3f0ff"}}>
            
            <h2 className="subtitle medium">Votre mode de paiement</h2>

            { (fetchingData.user.paymentMethod === "card") && <>
                {(fetchingData.user.cardBrand === "visa") && <img  style={{height:"4rem", width:"8rem"} } alt='logo visa' src={visa_logo}/> }
                {(fetchingData.user.cardBrand === "mastercard") && <img  style={{height:"4rem", width:"8rem"} } alt='logo mastercard' src={mastercard_logo}/> }
                {!((fetchingData.user.cardBrand === "visa") || (fetchingData.user.cardBrand === "mastercard")) && <img  style={{height:"4rem", width:"8rem"} } alt='logo cb' src={cb_logo}/> }
                <p style={{textTransform: "uppercase", paddingTop: "1.5rem"}}>Carte de crédit {fetchingData.user.cardBrand}</p>
                <p>**** **** **** {fetchingData.user.cardLast4}</p>
            </>}
            {
                (fetchingData.user.paymentMethod === undefined) && <p style={{textTransform: "uppercase"}}>Aucun moyen de paiement enregistré</p>
            }
            {
                (fetchingData.user.paymentMethod === "sepa_debit") && <><p style={{textTransform: "uppercase"}}>Prélèvement SEPA</p>
                <p>****************{fetchingData.user.ibanLast4}</p></>
            }
            <Button variant="contained" id="change-pm" className="btn medium" href={baseRouteUrl + "/modify-paiement-method"}>Modifier mon mode de paiement</Button>
        </div>

        </div>

    </div>
    
}