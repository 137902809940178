import { packageComponentProps } from "../types/common.type";

export const pages = { "menu_welcome": "home",
"menu_products": "packages",
"menu_contact_us": "contact" };

export const pagesConnected = { "menu_upload": "upload" }

export const pagesConnected2 = { "menu_history": "history",
  "menu_my_account": "myAccount",
  "menu_bill_history": "billHistory",
  "menu_contact_us_connected": "contact"
}

export const utils_list_package : packageComponentProps[] = [{
    name_package :  <p>Petit Qokka</p>,
    nb_consultations:  <p>1 Consultation</p>,
    description: <div className="formule1_text"><p>Vous ne savez pas combien de vérifications vous aurez besoin, testez ce petit format qui vous donne accès à tous les services pour découvrir la puissance de QOKKA</p></div> ,
    text_button: "Choisir cette option" ,
    price_per_unit_description:  <div className="prix_formule1"><p>4,9 &euro; <p className="text_gris">/ l&rsquo;unité</p></p> <p>Sans engagement</p></div>
  },
  {
    name_package :  <p>Medium Qokka</p>,
    nb_consultations:  <p>95 consultations</p>,
    description: <div className="formule2_text"><p>Vous avez besoin d&rsquo;un volume plus conséquent, disposez d&rsquo;un abonnement et profitez de tous nos services en toute tranquilité.</p></div> ,
    text_button: "Choisir cet abonnement" ,
    price_per_unit_description: <div className="prix_formule2"><p>400 &euro; <p className="text_gris">/ mois</p> <br></br>Pas de dépassement possible. <br></br>4,21 &euro; / consultation</p></div>
  },
  {
    name_package :  <p>Big Qokka</p>,
    nb_consultations:  <p>180 consultations</p>,
    description: <div><div className="formule3_text1"><p>Votre activité nécessite d&rsquo;un important volume de contrôle.</p></div> <div className="formule3_text2"><p>Disposez au mieux de nos services, Grâce à cette formule, vous pouvez travailler sans contraintes.</p></div></div> ,
    text_button: "Choisir cet abonnement" ,
    price_per_unit_description: <div className="prix_formule3"><p>700 &euro; <p className="text_gris">/ mois</p> <br></br>Pas de dépassement possible.<br></br> 3,99 &euro; / consultation</p></div>
  }

  ]

export const extra_qokka : JSX.Element = <div className="extraQokka_fils">
  <h1>EXTRA QOKKA</h1>
  <p className="text1">Réservé aux abonnés</p>
  <p>Dans le cadre d'un abonnement, si vous avez épuisé votre crédit de consultations, vous pourrez toujours ajouter un extra, à chaque fois que vous en avez besoin...</p>
</div>

export function utils_cases_document_upload(document_type:string){

  var string_to_return:string = "";

  return ;
  
}

export const donnees_bruts = { // fetch 1

  user : {
    firstname: "Paul",
    lastname: "Dupont",
    credit_restant: 175, // calculé à partir de ConsumptionController.getCreditNumberForOneUser(user.stripeCustomerId) du back
    forfait_actuel: "BIG QOKKA", // DAMIEN Help 
    credit_mensuel_du_forfait: 180, // DAMIEN Help 
    Ouverture_du_compte: "05/04/2022",
    Date_de_renouvellement: "05/05/2022", // NOTE pour Damien, il faut éditer la colonne updatedAt de la table user quand on fait une nouvelle inscription
    email: "paul.dupont@qokka_big_fan.com",
    entreprise: "ABC",
    siren_number: "123 123 123",
    adresse:"2 rue des fleurs 75001 PARIS",
    paiement_method: "VISA", // DAMIEN Help
    TVA:true, 
    total_des_consultations_generees_from_the_beginning: "5"
  }
};

export const exemple_list_from_server = [ // fetch 2
  // pour historique des facturations. Utilisé dans le composant 20.
  {   
       // exemple_list_from_server
      // faut recup une liste de ce style dans le serveur et faire un liste.map(el => <div><div> ) dans le front.

      date:<p>05/04/2022</p>, // DAMIEN Help
      montant:<p> 700 &euro; TTC</p>,// DAMIEN Help
      formule: <p>Big Qokka</p>,// DAMIEN Help
      status: <p style={{background: "#9af7d0", inlineSize: "fit-content", fontWeight: "200"}}>&nbsp;&nbsp;Payé&nbsp;&nbsp;</p>// DAMIEN Help

  },
  {
      date:<p>05/05/2022</p>,// DAMIEN Help
      montant:<p> 700 &euro; TTC</p>,// DAMIEN Help
      formule: <p>Big Qokka</p>,// DAMIEN Help
      status: <p style={{background: "#dadee8", inlineSize: "fit-content", fontWeight: "200"}}>&nbsp;&nbsp;En attente&nbsp;&nbsp;</p>// DAMIEN Help

  }
]

export const list_consulations_generees_from_beginning = [ // fetch_3

  {
   date: "05/04/2022",
   document_type: "carte d'identité française",
   result: "conforme" 
  },

  {
   date: "06/04/2022",
   document_type: "passport français",
   result: "non conforme" 
  },

  {
   date: "07/04/2022",
   document_type: "permis de conduire française",
   result: "non reconnu" 
  },
  
  {
   date: "07/04/2022",
   document_type: "carte d'identité française",
   result: "conforme" 
  },

  {
   date: "08/04/2022",
   document_type: "passport français",
   result: "non conforme" 
  }

]