
import React from "react";
import i18n from "../i18n";
import { donnees_bruts } from "../utils/constants";

const baseRouteUrl = "/" + i18n.language;

export const PolitiqueDeCookies: React.FunctionComponent = () => {

    return < div className="cgu" style= {{ display: "flex", flexDirection: "column", alignItems: "center"}}>

    <div style= {{
    width: "70%",
    backgroundColor: "#e3f0ff", 
    borderRadius: "3rem",
    marginTop: "4rem", 
    marginBottom: "4rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    textAlign: "center",
    paddingTop: "3rem",
    paddingBottom: "3rem",
    display: "flex", 
    flexDirection: "column", 

    }}>
    

    <h1><span style={{fontWeight: "bold"}}>Politique de Cookies du Logiciel QOKKA</span></h1>
    <div style= {{height: "1rem"}}></div>


    <p>Cette politique de cookies a été mise à jour pour la dernière fois le 14 juin 2022 et s’applique aux citoyens de l’Espace Économique Européen.</p>
    <div style= {{height: "0.1rem", backgroundColor: "black", width : "100%"}}></div>
    <div style= {{height: "1rem"}}></div>

    
    

    <div style= {{height: "1rem"}}></div>
    <h4><span style={{fontWeight: "bold"}}>1. Introduction</span></h4>
    <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Notre site web, <a href="https://www.qokka.fr/">https://www.qokka.fr/</a> (ci-après : « le site web ») utilise des 
        cookies et autres technologies liées (par simplification, toutes ces technologies sont désignées par le terme « cookies »). 
        Des cookies sont également placés par des tierces parties que nous avons engagées. 
        Dans le document ci-dessous, nous vous informons de l’utilisation des cookies sur notre site web.
    </p>

    <h4><span style={{fontWeight: "bold"}}>2. Que sont les cookies ? </span></h4>
    <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Un cookie est un petit fichier simple envoyé avec les pages de ce site web et stocké par votre navigateur sur le disque dur de votre ordinateur ou d’un autre appareil. 
        Les informations qui y sont stockées peuvent être renvoyées à nos serveurs ou aux serveurs des tierces parties concernées lors d’une visite ultérieure.
    </p>

    <h4><span style={{fontWeight: "bold"}}>3. Que sont les scripts ? </span></h4>
    <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Un script est un élément de code utilisé pour que notre site web fonctionne correctement et de manière interactive.
        Ce code est exécuté sur notre serveur ou sur votre appareil.
    </p>

    <h4><span style={{fontWeight: "bold"}}>4. Qu'est-ce qu'une balise invisible ? </span></h4>
    <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Une balise invisible (ou balise web) est un petit morceau de texte ou d’image invisible sur un site web, utilisé pour suivre le trafic sur un site web. 
        Pour ce faire, diverses données vous concernant sont stockées à l’aide de balises invisibles.
    </p>

    <h4><span style={{fontWeight: "bold"}}>5. Cookies </span></h4>
    <div style= {{height: "1rem"}}></div>

    <h5><span style={{fontWeight: "bold"}}>5.1 </span> <span style={{fontWeight: "bold", textDecoration: "underline"}}>Cookies techniques ou fonctionnels</span></h5>
    <div style= {{height: "1rem"}}></div>
    <p style= {{textAlign: "left"}}>Certains cookies assurent le fonctionnement correct de certaines parties du site web et la prise en compte de vos 
    préférences en tant qu’utilisateur. En plaçant des cookies fonctionnels, nous vous facilitons la visite de notre site web. 
    Ainsi, vous n’avez pas besoin de saisir à plusieurs reprises les mêmes informations lors de la visite de notre site web et, 
    par exemple, les éléments restent dans votre panier jusqu’à votre paiement. Nous pouvons placer ces cookies sans votre consentement.  
    
    </p>

    <h5><span style={{fontWeight: "bold"}}>5.2 </span> <span style={{fontWeight: "bold", textDecoration: "underline"}}>Cookies analytiques</span></h5>
    <div style= {{height: "1rem"}}></div>
    <p style= {{textAlign: "left"}}>Nous utilisons des cookies analytiques afin d’optimiser l’expérience des utilisateurs sur notre site web. 
    Avec ces cookies analytiques, nous obtenons des informations sur l’utilisation de notre site web. 
    Nous demandons votre permission pour placer des cookies analytiques.  
    
    </p>

    <h5><span style={{fontWeight: "bold"}}>5.3 </span> <span style={{fontWeight: "bold", textDecoration: "underline"}}>Cookies de marketing/suivi</span></h5>
    <div style= {{height: "1rem"}}></div>
    <p style= {{textAlign: "left"}}>Les cookies de marketing/suivi sont des cookies ou toute autre forme de stockage local, 
    utilisés pour créer des profils d’utilisateurs afin d’afficher de la publicité ou de suivre l’utilisateur sur ce site web 
    ou sur plusieurs sites web à des fins de marketing similaires. 
    
    </p>

    <h4><span style={{fontWeight: "bold"}}>6. Cookies placés</span></h4>
    <h5><span style={{fontWeight: "bold"}}></span> <span style={{fontWeight: "bold"}}>Stripe</span></h5>
    <p style= {{textAlign: "left"}}>
    Lorsque vous visitez notre site web pour la première fois, nous plaçons un cookie dont la seule utilité est de permettre 
    un affichage des prix gérés par notre prestataire de paiement (Stripe).
    </p>

    <h4><span style={{fontWeight: "bold"}}>7. Consentement</span></h4>
    <h5><span style={{fontWeight: "bold"}}></span> <span style={{fontWeight: "bold"}}>Stripe</span></h5>
    <p style={{textAlign: "left"}}>
    Lorsque vous visitez notre site web pour la première fois, nous vous montrerons une fenêtre contextuelle avec une explication sur les cookies. 
    Dès que vous cliquez sur « valider » vous nous autorisez à utiliser les catégories de cookies et d’extensions que vous avez sélectionnés dans la fenêtre 
    contextuelle, comme décrit dans la présente politique de cookies. Vous pouvez désactiver l’utilisation des cookies via votre navigateur, mais 
    veuillez noter que notre site web pourrait ne plus fonctionner correctement.
    </p>
    <div style= {{height: "1rem"}}></div>
    <h5><span style={{fontWeight: "bold"}}>7.1 </span> <span style={{fontWeight: "bold", textDecoration: "underline"}}>Gérez vos réglages de consentement</span></h5>
    <div style= {{height: "1rem"}}></div>
    <p>
    Fonctionnel
    </p>
    <p>
    Statistiques
    </p>
    <p>
    Marketing
    </p>


    <div style= {{height: "1rem"}}></div>
    <h4><span style={{fontWeight: "bold"}}>8. Vos droits concernant les données personnelles</span></h4>

    <div style= {{height: "1rem"}}></div>
    <p style= {{textAlign: "left"}}>Vous avez les droits suivants concernant vos données personnelles : 
        
        </p>
        <ul style= {{textAlign: "left"}}>
            <li>Vous avez le droit de savoir pourquoi vos données personnelles sont nécessaires, ce qui leur arrivera et combien de temps elles seront conservées.</li>
            <li>Droit d’accès : vous avez le droit d’accéder à vos données personnelles que nous connaissons.</li>
            <li>Droit de rectification : vous avez le droit à tout moment de compléter, corriger, faire supprimer ou bloquer vos données personnelles.</li>
            <li>Si vous nous donnez votre consentement pour le traitement de vos données, vous avez le droit de révoquer ce consentement et de faire supprimer vos données personnelles.</li>
            <li>Droit de transférer vos données : vous avez le droit de demander toutes vos données personnelles au responsable du traitement et de les transférer dans leur intégralité à un autre responsable du traitement.</li>
            <li>Droit d’opposition : vous pouvez vous opposer au traitement de vos données. Nous obtempérerons, à moins que certaines raisons ne justifient ce traitement.</li>
        
        </ul>
        <p style= {{textAlign: "left"}}>Pour exercer ces droits, veuillez nous contacter. Veuillez vous référer aux coordonnées au bas de cette politique de cookies. 
        Si vous avez une plainte concernant la façon dont nous traitons vos données, nous aimerions en être informés, mais vous avez 
        également le droit de déposer une plainte auprès de l’autorité de contrôle (l’autorité chargée de la protection des données, comme le CEPD).
        </p>


        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>9. Activer/désactiver et supprimier les cookies</span></h4>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Vous pouvez utiliser votre navigateur internet pour supprimer automatiquement ou manuellement les cookies. 
        Vous pouvez également spécifier que certains cookies ne peuvent pas être placés. Une autre option consiste à 
        modifier les réglages de votre navigateur internet afin que vous receviez un message à chaque fois qu’un cookie est placé. 
        Pour plus d’informations sur ces options, reportez-vous aux instructions de la section Aide de votre navigateur.

        </p>

        <p style= {{textAlign: "left"}}>
        Veuillez noter que notre site web peut ne pas marcher correctement si tous les cookies sont désactivés. 
        Si vous supprimez les cookies dans votre navigateur, ils seront de nouveau placés après votre consentement lorsque vous revisiterez nos sites web.
        </p>

        <h4><span style={{fontWeight: "bold"}}>10. Coordonnées</span></h4>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Pour des questions et/ou des commentaires sur notre politique en matière de cookies et cette déclaration, veuillez nous contacter en utilisant les coordonnées suivantes :
        </p>
        <p style= {{textAlign: "left"}}>Resocom</p>
        <p style= {{textAlign: "left"}}>6 rue Neuve Saint-Pierre</p>
        <p style= {{textAlign: "left"}}>75004 Paris</p>
        <p style= {{textAlign: "left"}}>France</p>
        <p style= {{textAlign: "left"}}>Site web : <a href="https://www.qokka.fr/">https://www.qokka.fr/</a></p>
        <p style= {{textAlign: "left"}}>E-mail: <a href="mailto:support@qokka.fr">support@qokka.fr</a></p>
        <p style= {{textAlign: "left"}}>Numéro de téléphone: 0179350415</p>

        <div style= {{height: "2rem"}}></div>

        <p >Cette politique de cookies a été synchronisée avec <a href="https://www.cookiedatabase.org/">cookiedatabase.org</a> le 14 juin 2022</p>

    </div>
    
</div>
    
    
}
