import { Component, StrictMode } from "react";
import UserService from "../services/user.service";
import React from "react";
import UploadFiles from "./upload-files.component";
import { ProgressBarComponent } from "./molecules/ProgressBarUpload.component";
import "./9QokkaUploadDoc.component.css"
import id_card from '../icons/9UploadDoc/Id_Card.svg'
import document from '../icons/9UploadDoc/document.svg'
import permis from '../icons/9UploadDoc/permis.svg'
import passport from '../icons/9UploadDoc/passport.svg'
import titre_de_sejour from '../icons/9UploadDoc/titre-de-sejour.svg'
import ConnectedBadgeIcon from "../icons/connected-badge.svg"
import { State_Upload } from "../types/common.type";
import { donnees_bruts } from "../utils/constants";
import { Button, CircularProgress } from "@mui/material";
import { routes } from '../utils/routes';
import i18n from "../i18n";
import { Link } from "react-router-dom";

const baseRouteUrl = "/" + i18n.language;

type Props = {
  step: number; // step equals to 0 : it's when the user didn't load any document.
  // step to 1 the documents has been uploaded.
  // step 2 the user clicked on process after step 1, the server has 
};

const utils_case :(input:string) => JSX.Element = function(input:string): JSX.Element {
  switch(input){
    case "id_document":
      return <p>Ajouter la pièce d&rsquo;identité <br></br> (recto-verso)</p>;
    case "passeport":
      return <p>Ajouter votre passeport</p>;
    case "titre_de_sejour":
      return <p>Ajouter votre titre de sejour <br></br> (recto-verso)</p>;
    default:
      return <p></p>;
  }
}

export default class LoadImage extends Component<Props, State_Upload> {
  timer: NodeJS.Timeout | undefined

  constructor(props: Props) {
    super(props);
    this.nextStep.bind(this)
    this.previousStep.bind(this)
    this.setState.bind(this)
    this.reSetState.bind(this)
    this.state = {
      firstSync: true,
      syncWillBeNeeded: true,
      step: 0,
      loading: true,
      message: "",
      content: "",
      type_document: undefined,
      text_button_next_step: "Analyser",
      childKey: 7, // number used to rerender a component. The UploadFiles has to be reload each time the user click to a different type of document.
      fetchedData: {
        user : {
          firstname: "",
          lastname: "",
          remaining_credits: 0,
        }
      }
    };
  }

  nouvelleAnalyse() {
    
    this.setState({
      firstSync: true,
      syncWillBeNeeded: true,
      type_document: undefined
    }) // déclanche le rechargement de la page.

  }

  nextStep() {
    if (this.state.step <4){
      this.setState({step: this.state.step+1})
      //this.setState({text_button_next_step: list_text_button[this.state.step]})
    }
  }

  previousStep() {
    if (this.state.step === 0){
      return;
      //this.setState({text_button_next_step: list_text_button[this.state.step]})
    }
    this.setState({step: this.state.step-1})
  }

  reSetState() {
    this.setState({step: 0})
    
  }

  refreshTheCounter() {
    if (this.state.firstSync || this.state.syncWillBeNeeded)
    {
      UserService.fetch_1_light2()
        .then((response: any) => {
            this.setState({
              firstSync: false,
              syncWillBeNeeded: false,
              fetchedData: {
                user : {
                  firstname: response.data.user.firstname,
                  lastname: response.data.user.lastname,
                  remaining_credits: response.data.user.remaining_credits,
                }
              },
              loading: false
            }, () => {
                if (this.state.fetchedData.user.remaining_credits === 0 && this.state.step === 0)
                {
                  this.timer = setTimeout(() => { window.location.href = baseRouteUrl + routes['change_package'].path; }, 8000)
                }
              })        
          })
        .catch(
            (error) => {              
              console.log(error)
              this.setState({
                firstSync: false,
                syncWillBeNeeded: false,
                message: "Erreur au chargement de la page.",
                loading: false
              })
            }
          )
        }
      }

  componentDidMount(){
    this.refreshTheCounter()
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  render() {
    console.log(this.state.fetchedData.user.remaining_credits)

    if (this.state.loading)
    {
        return <div className="qokkaMonCompte">
                  <div className="spinnerContainer">
                    <CircularProgress sx={{width: "100px!important", height: "100px!important"}} />
                  </div>
               </div>
    }
    if (this.state.message !== "")
    {
        return <div className="qokkaMonCompte"><p className="ErrMessage Mui-error" dangerouslySetInnerHTML={{__html: this.state.message }} /></div>
    }
    return (
      <StrictMode>
      <div className="upload_document">
        <div className="background_bleu_gradiant">
          <div className="single_div_for_flex"> 
              <div className="bienvenue_user">
                Bienvenue {this.state.fetchedData.user.firstname} {this.state.fetchedData.user.lastname}
              </div>

              <div className="credit_div"><span>&nbsp;&nbsp;Crédit | <b>{this.state.fetchedData.user.remaining_credits === null ? "0" : this.state.fetchedData.user.remaining_credits} CONSULTATIONS</b>&nbsp;&nbsp;</span></div>

              <h1 className="analyse_doc">Analyser <br></br> un document</h1>
              {(this.state.fetchedData.user.remaining_credits !== 0) ?
              <p className="choose_doc_type_text">Choisissez le type de votre document</p> : 
              <p className="choose_doc_type_text">Vous n&rsquo;avez plus de crédits de consultation pour faire des analyses.
              { 
              this.state.step === 0 && <><br />
              Vous allez être automatiquement redirigé dans 8s sur la page d&rsquo;achats...</> }</p>}
              { (this.state.fetchedData.user.remaining_credits === 0) && <Button className="btn" variant="contained" component={Link} to={baseRouteUrl + routes['change_package'].path}>
                  Achats de crédits
              </Button> }

          </div>
      
        </div>
      { !(this.state.fetchedData.user.remaining_credits === 0 && this.state.step === 0) && <>
        <div className="background-white-and-blue">
          <div className="type_document_choice">
            <div style={{border:'solid', borderColor: this.state.type_document==='id_document' ? "#4a9cea": "white"}} 
              onClick={()=>{this.setState(
                  {
                    firstSync: true,
                    syncWillBeNeeded: true,
                    type_document:"id_document",
                    childKey: this.state.childKey*7,
                    step: 0
                  });
              }}>
                  <img alt="identite_image" src={id_card}></img>
                  <p>IDENTITE</p>
                  {this.state.type_document==='id_document' ? <img className="connected_img" alt="connected_image" src={ConnectedBadgeIcon} ></img>: <></>}
                  
            </div>
            <div style={{border:'solid', borderColor: this.state.type_document==='passeport' ? "#4a9cea": "white"}} 
                  onClick={()=>{this.setState(
                      {
                        firstSync: true,
                        syncWillBeNeeded: true,
                        type_document:"passeport",
                        childKey: this.state.childKey*7,
                        step: 0
                      });
                  }}>
                <img alt="passeport" src={passport}></img>
                <p>PASSEPORT</p>
                {this.state.type_document==='passeport' ? <img className="connected_img" alt="connected_image" src={ConnectedBadgeIcon} ></img>: <></>}
                
            </div>
            <div style={{border:'solid', borderColor: this.state.type_document==='titre_de_sejour' ? "#4a9cea": "white"}} onClick={()=>{
                  this.setState(
                    {
                      firstSync: true,
                      syncWillBeNeeded: true,
                      type_document:"titre_de_sejour",
                      childKey: this.state.childKey*7,
                      step: 0
                    });
              }}><img alt="titre de sejour" src={titre_de_sejour}></img>
                {this.state.type_document==='titre_de_sejour' ? <p>TITRE DE...</p>:<p>TITRE DE SEJOUR</p> /* Condition sur this.state.type_document
                pour un meilleur rendu graphique car sinon, le texte TITRE DE SEJOUR sort de la border. */}

                {this.state.type_document==='titre_de_sejour' ? <img className="connected_img" alt="connected_image" src={ConnectedBadgeIcon} ></img>: <></>}
            </div>                  
          </div>
        </div>

          {(this.state.type_document !== undefined) ? <div className="text_container">
            <p>{utils_case(this.state.type_document)}</p></div>:<></>
          }
        
          <div className="container">          
            <div>
                {(this.state.type_document !== undefined) && <React.Fragment>
                  
                <ProgressBarComponent step={this.state.step}></ProgressBarComponent>
                
                <UploadFiles 
                key={this.state.childKey} 
                step={this.state.step} 
                text_button_next_step={this.state.text_button_next_step} 
                parrentStepper={this.nextStep.bind(this)}
                previousStep = {this.previousStep.bind(this)}
                document_type ={this.state.type_document}
                set_step_to_zero={this.reSetState.bind(this)}
                nouvelle_analyse={this.nouvelleAnalyse.bind(this)}
                refreshTheCounter={this.refreshTheCounter.bind(this)}

                ></UploadFiles></React.Fragment> /**Analyser**/ }
            </div>
          </div>
        </> }
      </div>
      </StrictMode>
    );
  }
}