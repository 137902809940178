import React, { Component, useState } from "react";
import { Switch, Route, Link, Redirect, matchPath, RouteComponentProps } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import AuthService from "./services/auth.service";
import IUser from "./types/user.type";
import EventBus from "./common/EventBus";
import i18n from "./i18n.js"
import ResponsiveAppBar from "./ResponsiveAppBar";
import ScrollTop from "./ScrollTop";
import { Button, Collapse, MenuList, Typography } from "@mui/material";

import LogoQOKKABlackWhite from "./icons/logoQOKKA_black_white.svg"
import LogoIDVERIF from "./icons/logo-idverif.svg"
import LogoRESOCOM from "./icons/RESOCOMLOGOiconText.svg"
import LogoCYBERFILES from "./icons/logo_cyberfilesv3-06.svg"
import { withRouter } from 'react-router-dom'

import { PrivateRoute } from "./utils/private_route";
import { GetLocationKey, routes, routeType } from "./utils/routes";
import productService from "./services/product.service";

const baseRouteUrl = "/" + i18n.language;

type Props = {};

type PromoType = {
    promo: number,
    promoType: string,
    endDate: number
}

type State = {
  currentUser: IUser | undefined,
  promoAvailable: PromoType | undefined,
  windowWidth: number,
  windowHeight: number,
}

type OfferCountersPropsType = {
  endDate: number,
}

type PromoBannerPropsType = {
  promoAvailable: PromoType,
  location?: string
}

const PromoBanner: React.FunctionComponent<PromoBannerPropsType> = (props: PromoBannerPropsType) => {
  const [durationSec, setDurationSec] = useState(0);

  React.useEffect(() => {
    setDurationSec(Math.max(-1, (Math.round(props.promoAvailable.endDate - new Date().getTime() / 1000))))

    const interval = setInterval(() => {
      const newDuration = Math.max(-1, (Math.round(props.promoAvailable.endDate - new Date().getTime() / 1000)));

      if (durationSec != newDuration)
      {
        setDurationSec(newDuration)
      }

    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  let durationS = durationSec

  if (durationS < 0)
  {
    return <></>
  }

  const durationDays = Math.floor(durationS / (24 * 60 * 60))
  durationS = durationS - durationDays * (24 * 60 * 60)
  const durationHours = Math.floor(durationS / (60 * 60))
  durationS = durationS - durationHours * (60 * 60)
  const durationMin = Math.floor(durationS / 60)
  durationS = durationS - durationMin * 60

  return(
    <div className="promo">
      <div className="promo-shape">
        <div className="main-container">
          <Typography className="offer-type">Offre&nbsp;limitée</Typography>
          <p className="medium">Bénéficiez de {props.promoAvailable.promo}% de réduction sur toutes les formules QOKKA&nbsp;!</p>
          { props.location !== 'packages' && <Button variant="contained" onClick={() => {window.location.href= baseRouteUrl + routes['packages'].path}}>J&rsquo;en&nbsp;profite</Button> }
        </div>
        { props.location === 'packages' && <p className="note">La promo s&rsquo;applique automatiquement à la transaction</p> }
      </div>
      <div className="offer-counters-container">
        <div className="offer-counters">
          <div className="offer-counter">
            <span className="offer-counter-number">{durationDays}</span>
            <span className="offer-counter-unit">Jours</span>
          </div>
          <div className="offer-counter">
            <span className="offer-counter-number">{durationHours}</span>
            <span className="offer-counter-unit">Heures</span>
          </div>
          <div className="offer-counter">
            <span className="offer-counter-number">{durationMin}</span>
            <span className="offer-counter-unit">Minutes</span>
          </div>
          <div className="offer-counter">
            <span className="offer-counter-number">{durationS}</span>
            <span className="offer-counter-unit">Secondes</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const App = withRouter(props => <_App {...props}/>);

class _App extends Component<Props, State> {
  previousLocationKey:string = "";
  previousLocationPath:string = "";
  intervalId:any;

  constructor(props:RouteComponentProps) {
    super(props);

    this.logOut = this.logOut.bind(this);
    this.logIn = this.logIn.bind(this);

    this.state = {
      currentUser: undefined,
      promoAvailable: undefined,
      windowWidth: 0,
      windowHeight: 0,
    };    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  fetchPromo() {
    productService.getPromo().then((resp) => {
      if (resp.status == 200)
      {
        console.log(resp.data)
        let _promo = resp.data

        let promo:PromoType = {
          promo: _promo.promo,
          promoType: _promo["promo-type"],
          endDate: _promo["end-date"]
        }

        this.setState({ promoAvailable: promo }, () => { this.intervalId = setInterval(() => {
          productService.getPromo().then((resp2) => {
            if (this.state.promoAvailable && resp2.status == 204)
            {
              
              this.setState({ promoAvailable: undefined })
            }
            else if (resp2.status == 200 && (!this.state.promoAvailable || (
              this.state.promoAvailable.promo !== resp2.data.promo ||
              this.state.promoAvailable.promoType !== resp2.data["promo-type"] ||
              this.state.promoAvailable.endDate !== resp2.data["end-date"]
            )))
            {

              this.setState({
                promoAvailable : { promo: resp2.data.promo,
                                   promoType: resp2.data["promo-type"],
                                   endDate: resp2.data["end-date"] }
                })
              }
              
            }
          )
        }, 5000) })
      }
    })
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }

    EventBus.on("logout", this.logOut);
    EventBus.on("login", this.logIn);    
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();
    this.fetchPromo();
  }

  componentWillUnmount() {
    EventBus.remove("logout", this.logOut);
    EventBus.remove("login", this.logIn);
    window.removeEventListener('resize', this.updateWindowDimensions);
    clearInterval(this.intervalId);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });    
  }

  logIn() {
    let user = AuthService.getCurrentUser()

    this.setState({
      currentUser: user ? user : undefined
    });
  }

  render() {
    const { currentUser, windowWidth, promoAvailable } = this.state;
    const path = (this.props as RouteComponentProps).location.pathname;
    const locationKey = GetLocationKey(path)
    const showTopPromo = routes[locationKey].showTopPromo

    const token = localStorage.getItem("token");

    //update the token at least on each page change and disconnect if expired
    if (this.previousLocationKey !== locationKey)
    {
      this.previousLocationKey = locationKey

      if (token)
      {
        AuthService.checkToken()
      }
    }
    
    if (this.previousLocationPath !== path)
    {
      this.previousLocationPath = path
      window.scrollTo(0, 0);      
    }

    let hideMenu = false
    let previousCallback = undefined;

    if (locationKey === 'register' || locationKey === 'payment' || locationKey === 'confirmed_payment')
    {
      hideMenu = true
      previousCallback = (() => { window.location.href=baseRouteUrl+routes['packages'].path })
    }
    if (locationKey === 'payment_connected' || locationKey === 'confirmed_payment_connected')
    {
      previousCallback = (() => { window.location.href=baseRouteUrl+routes['change_package'].path })
    }

    return (
      <div id={locationKey}>
        { (!(locationKey === 'packages' && windowWidth < 900)) && showTopPromo && promoAvailable && <PromoBanner promoAvailable={promoAvailable} location={locationKey}/> }
        <div className="extension" />
        <ResponsiveAppBar connected={currentUser !== undefined} windowWidth={windowWidth} locationKey={locationKey} hideMenu={hideMenu} previousCallback={previousCallback}/>
        { (locationKey === 'packages' && windowWidth < 900) && showTopPromo && promoAvailable && <PromoBanner promoAvailable={promoAvailable} location={locationKey}/> }
        <Switch>
          <Route key="home_redirection" exact path={["","/:someLanguage"]}>
            <Redirect to={baseRouteUrl + routes["home"].path}/>
          </Route>
          {
            Object.entries(routes).map(entry => {
               const [key, value]:[key:string, value:routeType] = entry;

               if (value.noConnectionRequired)
                  return <Route key={key} exact={value.exact} path={baseRouteUrl + value.path} component={value.component} location={ value.locationUsed ? (this.props as RouteComponentProps).location : undefined } />
                else
                {
                  if (currentUser)
                    return <Route key={key} exact={value.exact} path={baseRouteUrl + value.path} component={value.component} />
                  else
                    return <PrivateRoute key={key} exact={value.exact} path={baseRouteUrl + value.path} component={value.component} />
                }
            })
          }
        </Switch>
        <ScrollTop {...this.props} />
        { !hideMenu && <footer>

          <div className="flexerUtilsFooter">
          <div>
          <div id="logo"><img alt="logo QOKKA" src={LogoQOKKABlackWhite}/></div>

          <Button variant="outlined" id="subscribe-button" component={Link} to={baseRouteUrl + "/register"}>S&rsquo;inscrire</Button>
          
          <MenuList className="footer-menu">
            
            <Button component={Link} to={baseRouteUrl + "/contact"} sx={{display:"block"}}>Contactez QOKKA</Button>
            
            <Button component={Link} to={baseRouteUrl + "/cgv"} sx={{display:"block"}}>Conditions générales de ventes</Button>
            <Button component={Link} to={baseRouteUrl + "/cgu"} sx={{display:"block"}}>Conditions générales d'utilisation</Button>

            {/*<Button component={Link} to={baseRouteUrl + "/"} sx={{display:"block"}}>Références</Button>*/}
            {/*<Button component={Link} to={baseRouteUrl + "/mentions"} sx={{display:"block"}}>Mentions légales</Button>*/}
            {/*<Button component={Link} to={baseRouteUrl + "/cookies"} sx={{display:"block"}}>Politique de Cookies</Button>*/}
          </MenuList>
          </div>
          <div>
          <div className="partners" style={{paddingBottom: "1rem"}}>
            <p style={{fontWeight:"900", fontSize: "2rem",paddingLeft: "0.5rem"}}>Partenaires</p> 
            <div className="logoFlexDisplayer">
                <a href="https://idverif.com/home"><img alt="logo IDVERIF" src={LogoIDVERIF}></img></a>
                <a href="https://www.resocom.com/"><img alt="logo RESOCOM" src={LogoRESOCOM} ></img></a>
                <a href="https://cyberfiles.fr/"><img alt="logo CYBERFILES" src={LogoCYBERFILES}></img></a>
            </div>   
            <p className="contactPress"><a style={{color:"black", textDecoration: "underline", fontWeight: "bold"}}href="mailto:sosvictimes@idverif.com">Accompagnement des victimes</a></p>
            <p className="socialMedia">Réseaux sociaux</p>
            <div className="flexerSocialMedia" style={{display:"flex", flexDirection:"row"}}>
              <div style={{padding:"0 1rem 0 1rem"}}>
                <a href="https://www.facebook.com/QOKKA75">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                  </svg>
                </a>
              </div>
              <div style={{padding:"0 1rem 0 1rem"}}>
                <a href="https://www.linkedin.com/company/qokka-kyc-inc/">

                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <rect x="4" y="4" width="16" height="16" rx="2" />
                  <line x1="8" y1="11" x2="8" y2="16" />
                  <line x1="8" y1="8" x2="8" y2="8.01" />
                  <line x1="12" y1="16" x2="12" y2="11" />
                  <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                </svg>
                </a>
              </div>
              <div style={{padding:"0 1rem 0 1rem"}}>
                <a href="https://twitter.com/QokkaSolution">

                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
                </svg>
                </a>
              </div>
          </div>


        </div>
          </div>
          </div>
          <p className="copyright">&copy; Copyright <span className="medium">QOKKA</span> - Tous droits réservés 2022&nbsp;</p>
        </footer> }
      </div>
    );
  }
}

export default App;