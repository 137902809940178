import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import i18n from "../i18n";
import { Button, CircularProgress } from '@mui/material';
import React from "react";
import './6QokkaPaiement.component.css';
import queryString from "query-string"
import { routes } from "../utils/routes";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, StripeError } from "@stripe/stripe-js";
import config from "../config/config";
import PaymentStep2 from "../icons/payment-step2.svg";
import authService from "../services/auth.service";
import productService from "../services/product.service";
import { setupMaster } from "cluster";
import { connected } from "process";
import visa_logo from "../icons/visa_logo.svg";
import mastercard_logo from "../icons/mastercard_logo.svg";
import cb_logo from "../icons/cb_logo.svg";

const baseRouteUrl = "/" + i18n.language;

type PaymentComponentPropsType = {
  location: Location
}

type OfferComponentPropsType = {
  offer: {
    name: string,
    type: string,
    consultations: number,
    color: {
      background: string,
    },
    price: number,
    promo?: number,
    'promo-type'?: string,
  },
}

type OfferComponentPropsType2 = {
  offer: {
    name: string,
    type: string,
    consultations: number,
    color: {
      background: string,
    },
    price: number,
    promo?: number,
    'promo-type'?: string,
  },
  connected: boolean,
  pmSaved: boolean,
  orderId: string
}

const OfferComponent: React.FunctionComponent<OfferComponentPropsType> = (props: OfferComponentPropsType) => {
  let price = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(props.offer.price / 100)  
  let reduction = props.offer.promo ? props.offer["promo-type"] == 'percent' ? Math.round(props.offer.price * props.offer.promo / 100) : props.offer.price : undefined
  let priceWithReduction = reduction ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format((props.offer.price - reduction) / 100) : undefined
  let unit = (props.offer.type == 'option' || props.offer.type == 'extra') ? "/l&rsquo;unité" : "/mois"
  let pricePerConsultation = props.offer.consultations > 1 ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format((props.offer.price - (reduction ? reduction : 0)) / (props.offer.consultations * 100)) : undefined

  return <>
      <span className="name">{props.offer.name}</span>
      <span className="consultations" style={{backgroundColor: props.offer.color.background }}>{props.offer.consultations} consultation{props.offer.consultations > 1 ?'s':''}</span>
      { props.offer.promo && <span className="stroken" style={{color: '#8390b3'}} dangerouslySetInnerHTML={{ __html: price+unit }} /> }
      <span className="price"><span className="number">{ props.offer.promo ? priceWithReduction : price }</span><span className="unit" style={{color: '#8390b3'}} dangerouslySetInnerHTML={{ __html: unit}}></span></span>
      <span className="comment">{props.offer.type == 'option' ? "Sans engagement" : "Pas de dépassement possible"}</span>
      { pricePerConsultation ? <span className="pricePerConsultation">{pricePerConsultation}/consultation</span> : <span className="pricePerConsultation">&nbsp;</span> }
  </>
}

const stripePubKey = config.StripePublicKey()
const stripePromise = loadStripe(stripePubKey);

const PaymentForm: React.FunctionComponent<OfferComponentPropsType2> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string|null>(null);
  const [loading, setLoading] = useState(true)
  const [pm, setPm] = useState<{paymentMethod: string|undefined, cardBrand: string|undefined, cardLast4: string|undefined, ibanLast4: string|undefined}>({paymentMethod: undefined, cardBrand: undefined, cardLast4: undefined, ibanLast4: undefined})
  const stripe = useStripe();
  const elements = useElements();
  const queryStringForRedirect = '?product_name=' + props.offer.name + '&product_color=' + props.offer.color.background.replace('#', '%23') + '&product_consultations=' + props.offer.consultations

  const handleSubmit = async (event:any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

/*const stripeOutofBound = Stripe(STRIPE_API_KEY);
  await stripeOutofBound.handleCardPayment(
    intentData.client_secret,
  );*/
    if (props.connected && props.pmSaved)
    {
      try
      {
        const resp = await productService.payProductConfirm(props.orderId)

        console.log(JSON.stringify(resp))
        
        if (resp.data.error)
        {
          setErrorMessage(resp.data.error)
          return
        }
        window.location.href = window.location.origin + baseRouteUrl + routes['confirmed_payment_connected'].path + queryStringForRedirect
      }
      catch(err)
      {
          console.log(JSON.stringify(err))
      }
    }
    else
    {
      const {error}:{error:StripeError} = await stripe.confirmPayment({      
        elements,
        confirmParams: {
  /*        setup_future_usage: "off_session", pass it at paymentintent creation instead */
          return_url: window.location.origin + baseRouteUrl + routes['confirmed_payment'].path + queryStringForRedirect,
        },
      });


      if (error && error.message) {
        setErrorMessage(error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
  }

  useEffect(() => {
    if (props.connected && props.pmSaved)
      productService.getPaymentMethod().then((res) => {
        setLoading(false)
        setPm(res.data)})
      .catch((err) => {
        setLoading(false)
        console.log("Error in GetPaymentMethod"+err)
        setErrorMessage(err)
      })
    else
      setLoading(false)
  }, [])

  return <form onSubmit={handleSubmit}>
    {errorMessage && <div className="Mui-error">{errorMessage}</div>}
    { (props.connected && props.pmSaved) ? 
        (loading ? <div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div> :
        <>
          {(pm.paymentMethod === "card") && <>
            {(pm.cardBrand === "visa") && <img  style={{height:"4rem", width:"8rem"} } alt='logo visa' src={visa_logo}/> }
            {(pm.cardBrand === "mastercard") && <img  style={{height:"4rem", width:"8rem"} } alt='logo mastercard' src={mastercard_logo}/> }
            {!((pm.cardBrand === "visa") || (pm.cardBrand === "mastercard")) && <img  style={{height:"4rem", width:"8rem"} } alt='logo cb' src={cb_logo}/> }
            <p style={{textTransform: "uppercase", paddingTop: "1.5rem"}}>Carte de crédit {pm.cardBrand}</p>
            <p>**** **** **** {pm.cardLast4}</p>
            </>}
          {(pm.paymentMethod === undefined) && <p style={{textTransform: "uppercase"}}>Aucun moyen de paiement enregistré</p> /*should never go here */}
          {(pm.paymentMethod === "sepa_debit") && <><p style={{textTransform: "uppercase"}}>Prélèvement SEPA</p>
            <p>****************{pm.ibanLast4}</p>
            <p>Notez qu&rsquo;avec un prélèvement SEPA, le changement de forfait peut prendre quelques minutes avant d'être effectif, le temps que le paiement soit finalisé.</p>
          </>}
        </>)
    : <PaymentElement /> }
    <p style={{marginTop:"1rem"}}>En validant ma commande, je déclare avoir pris connaissance et accepté sans réserve, les <a href={baseRouteUrl + "/cgu"}><span style={{textDecoration:"underline"}}>conditions générales</span></a> et la <a href={baseRouteUrl + "/cookies"}><span style={{textDecoration:"underline"}}>politiques de cookies</span></a> QOKKA</p>
    { (!props.connected && (!stripe || !elements)) || (props.connected && loading) ? <></> : <Button className="pay btn" color="primary" variant="contained" type="submit">Valider le paiement</Button> }
  </form>
}

const PaymentComponent: React.FunctionComponent<PaymentComponentPropsType> = (props:PaymentComponentPropsType) => {  
  const queryStringParams = queryString.parse(props.location.search)

  const user = authService.getCurrentUser()

  let product = {
    type: queryStringParams["product_type"] as string,
    consultations: parseInt(queryStringParams["product_consultations"] as string),
    name: queryStringParams["product_name"] as string,
    color: {
      background: queryStringParams["product_color"] as string
    },
    price: parseInt(queryStringParams["product_price"] as string),
    promo: queryStringParams["product_promo"] ? parseInt(queryStringParams["product_promo"] as string) : undefined,
    'promo-type': queryStringParams["product_promo_type"] ? queryStringParams["product_promo_type"] as string : undefined
  }
 
  let pmSaved = parseInt(queryStringParams["pm"] as string) !== 0
  let orderId = queryStringParams['orderId'] as string

  const options = {
    clientSecret: queryStringParams['id'] as string,    
  }

  return (
    <div className="payment">
      <div className="step-image-container">
        <img alt="paiement step 2" src={PaymentStep2} />
      </div>
      <h1>{user ? (product.type =='extra' ? "Ajoutez un extra QOKKA" : "Changez votre formule") : "Obtenez notre solution QOKKA" }</h1>
      <div className="splitter">
        <div className="chosen-formula">
          <h2>{user ? ((product.type =='extra') ? "Votre extra" : "Votre nouvelle formule") : "Votre formule choisie"}</h2>
          <OfferComponent offer={product} />
        </div>
        <div className="right-panel">
          <div className="form">
            <h2>{ user ? "Vos coordonnées de paiement" : "Indiquez vos coordonnées de paiement"}</h2>
            <Elements options={options} stripe={stripePromise}>
              <PaymentForm offer={product} connected={user !== null} pmSaved={pmSaved} orderId={orderId}/>
            </Elements>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentComponent;
