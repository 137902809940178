import { Support } from "@mui/icons-material"
import { ComponentType } from "react"
import QokkaHomePromo from "../components/1QokkaHomePromo.component"
import Login from "../components/22QokkaLogin.component"
import NewMdp from "../components/26QokkaConfirmReinitialisation.component"
import AvailablePlans from "../components/4QokkaAbonnementV2.component"
import Register from "../components/5QokkaSignup.component"
import { ConfirmedPaiement } from "../components/7QokkaConfirmPaiement.component"
import LoadImage from "../components/9QokkaUploadDoc.component"
import BoardAdmin from "../components/board-admin.component"
import ChoixAbonnement from "../components/choixAbonnement.component"
import HistoriqueFacturaction from "../components/historiqueFacturaction.component"
import Resoscan from "../components/resoscan.component"
import Contact from "../components/3QokkaContactNoConnect.component";
import { ModifAboComponent } from "../components/10QokkaModifAboV2.component"
import { Historique } from "../components/13QokkaHistorique.component"
import { MyAccount } from "../components/14QokkaMonCompte.component"
import { EditProfileComponent } from "../components/18QokkaCorrigerCoordonnees.component"
import { ResiliationComponent } from "../components/15QokkaResiliation.component"
import { ConfirmResiliationComponent } from "../components/16QokkaConfirmResilitation.component"
import NewPasswordComponent from "../components/25QokkaNewMotdepasse.component"
import { ChangePaiementInfosComponent} from "../components/19QokkaModifierPaiement.component"
import { HistoriqueFacturationComponent } from "../components/20QokkaFacturation.component"
import { exact } from "prop-types"

import ChangePasswordComponent from "../components/17QokkaCreerNouveauMotdepasse.component" //"../components/25QokkaNewMotdepasse.component"
import GetMailAndChangePasswordComponent from "../components/24QokkaReinitialisation.component"
import { matchPath } from "react-router-dom"
import PaymentComponent from "../components/6QokkaPaiement.component"
import { CGU } from "../components/CGU"
import { CGV } from "../components/CGV"
import { PolitiqueDeCookies } from "../components/PolitiqueDeCookies"
import { MentionsLegales } from "../components/MentionsLegales"
import MdpReinitialisedOK from "../components/MdpReinitialisedOK"

export type routeType = {
  exact:boolean,
  path:string,
  component:ComponentType<any>,
  showTopPromo?: boolean,
  noConnectionRequired?: boolean,
  locationUsed?: boolean  
}
export type routeDictionary = { [id: string]: routeType }

export const routes: routeDictionary = {
  'home': {
    exact: true,
    path: "/home",
    component: QokkaHomePromo,
    showTopPromo: true,
    noConnectionRequired: true,
  },
  'login': {
    exact: true,
    path: "/login",
    component: Login,
    noConnectionRequired: true,
  },
  'register': {
    exact: true,
    path: "/register",
    component: Register,
    noConnectionRequired: true,
    locationUsed: true
  },
  'upload': {
    exact: true,
    path: "/upload",
    component: LoadImage
  },
  'change_package':{
      exact: true,
      path: "/change-package",
      showTopPromo: true,
      component: AvailablePlans
  },
  'edit_profile':{
    exact: true,
    path: "/edit-profile",
    component: EditProfileComponent
  },
  'history':{
    exact: true,
    path: "/history",
    component: Historique
  },
  'myAccount':{
    exact: true,
    path: "/my-account",
    component: MyAccount
  },
  'contact': {
    exact: false,
    path: "/contact",
    component: Contact,
    noConnectionRequired: true,
  },
  'billing': {
    exact: false,
    path: "/billing",
    component: HistoriqueFacturaction,
    noConnectionRequired: true,
  },
  'subscription': {
    exact: false,
    path: "/subscription",
    component: ChoixAbonnement    
  },
  'payment': {
    exact: false,
    path: "/payment",
    component: PaymentComponent,
    noConnectionRequired: true,
    locationUsed: true
  },
  'confirmed_payment': {
    exact: false,
    path: "/confirmed_payment",
    component: ConfirmedPaiement,
    noConnectionRequired: true,
    locationUsed: true
  },
  'payment_connected': {
    exact: false,
    path: "/payment_connected",
    component: PaymentComponent,
    locationUsed: true
  },
  'confirmed_payment_connected': {
    exact: false,
    path: "/confirmed_payment_connected",
    component: ConfirmedPaiement,
    locationUsed: true
  },
  'packages': {
    exact: false,
    path: "/packages",
    component: AvailablePlans,
    showTopPromo: true,
    noConnectionRequired: true,
  },
  'resiliation' :{
    exact: false,
    path: "/terminate-current-package",
    component: ResiliationComponent
  },
  'modifyPaimentMethod': {
    exact: false,
    path: "/modify-paiement-method",
    component: ChangePaiementInfosComponent
  },
  'billHistory':{
    exact:false,
    path: "/bill-history",
    component: HistoriqueFacturationComponent
  },
  'changePassword': {
    exact: false,
    path: "/change-password",
    component: ChangePasswordComponent // from a connected user
  },
  "notConnectChangePassword" : {
    exact: false,
    path: "/not-connect-change-password",
    component: GetMailAndChangePasswordComponent, // not connected user send mail
    noConnectionRequired: true,
  },
  "changePasswordFromMailLink" : {
    exact : false,
    path: "/change-password-from-mail-link",
    component: NewPasswordComponent, // new password when user not connected has clicked on the mail 
    noConnectionRequired: true,
  },
  'confirm-end-package':{
    exact: false,
    path: "/confirm-end-package",
    component: ConfirmResiliationComponent
  },
  'mentions':{
    exact: false,
    path: "/mentions",
    component: MentionsLegales,
    noConnectionRequired: true,
  },
  'cgu':{
    exact: false,
    path: "/cgu",
    component: CGU,
    noConnectionRequired: true,
  },
  'cgv':{
    exact: false,
    path: "/cgv",
    component: CGV,
    noConnectionRequired: true,
  },
  'cookies':{
    exact: false,
    path: "/cookies",
    component: PolitiqueDeCookies,
    noConnectionRequired: true,
  },
  'MdpReinitialisedOK':{
    exact: false,
    path: "/mdp-reinitialised-ok",
    component: MdpReinitialisedOK,
    noConnectionRequired: true,
  },
  'resoscan': {
    exact: false,
    path: "/resoscan",
    component: Resoscan
  },
  'new_password': { // from a disconnected user
    exact: false,
    path: "/new-password",
    component: NewMdp,
    noConnectionRequired: true,
  },
  'process': { // unused for the moment. maybe to use with upload.
    exact: false,
    path: "/process",
    component: LoadImage
  }
}

export const GetLocationKey = (locationPathname: string):string => {
  const keys = Object.keys(routes)
  const len = keys.length;
  
  for (let i = 0; i < len; i++)
  {
    if ((keys[i] === "home") && (matchPath(locationPathname, {
          exact: true,
          path: ["","/:ln","/:ln/home"]
      }) != null))
      return keys[i]
    else
    {
      let route = routes[keys[i]];

      if (matchPath(locationPathname, {
          exact: route.exact,
          path: "/:ln" + route.path
        }) != null)
        return keys[i]
    }
  }
  throw "Page not found in routes"
}