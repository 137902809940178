import styled from "@emotion/styled";
import { FilledInput, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel } from "@mui/material";
import { useState } from "react";
import PwdEye from "../../icons/pwdEye.svg";
import PwdNoEye from "../../icons/pwdNoEye.svg";
import './QokkaInput.component.css';
import clsx from 'clsx';
import {Element as Elt } from 'react-scroll'

export type passwordComponentProps = {
    confirm:boolean,
    label_value?: string,
    form_entity?: string, // Dans le cas ou on veut specifier le nom de l'entité dans le formulaire. Par défa
    formik:any,
    requiredDisplay:boolean
}

const QokkaPasswordInput: React.FunctionComponent<passwordComponentProps> = props => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const missing = props.confirm ? (props.formik.errors.passwordConfirmation === "Ce champ est requis !") : (props.formik.errors.password === "Ce champ est requis !")
    
    return <>
    {
    
    props.form_entity ?

    <>
    <Elt name={props.confirm ? 'passwordConfirmation-anchor' : 'password-anchor'} className='anchor' />
    <FormControl
        className={clsx(missing && "err-required")}
        variant="filled"
        fullWidth>
        <InputLabel
            id={props.confirm ? 'passwordConfirmation-label' : 'password-label'}
            htmlFor={props.confirm ? 'passwordConfirmation' : 'password'}
            disableAnimation={true}
            required={props.requiredDisplay}>{props.label_value ? props.label_value : (props.confirm ? "Confirmez votre mot de passe" : "Mot de passe") }
        </InputLabel>     
            
        <FilledInput
            id={props.form_entity}
            name={props.form_entity}
            type={showPassword ? 'text' : 'password'}
            disableUnderline={true}                                             
            placeholder="XXXXXXXXXXXX"                  
            value={props.formik.values[props.form_entity]}
            onChange={props.confirm ? props.formik.handleChange('passwordConfirmation') : props.formik.handleChange(props.form_entity)}
            error={props.confirm ? (props.formik.touched.passwordConfirmation && Boolean(props.formik.errors.passwordConfirmation)) : (props.formik.touched.password && Boolean(props.formik.errors.password))}
            endAdornment={                                  
            <InputAdornment position="end">                                                                                                                                 
                <IconButton 
                aria-label="toggle password visibility" 
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}                                                                                                                   
                edge="end"
                >
                {
                    showPassword ? <img src={PwdNoEye} width="40px" alt="Icône cacher le mot de passe" /> :
                    <img src={PwdEye} width="40px" alt="Icône montrer le mot de passe" />
                }
                </IconButton>
            </InputAdornment>
            }
        />
        <FormHelperText>
            { props.formik.errors[props.form_entity] }
        </FormHelperText>
    </FormControl>
    </>

    :
    
    <>
    <Elt name={props.confirm ? 'passwordConfirmation-anchor' : 'password-anchor'} className='anchor' />
    <FormControl
        className={clsx(missing && "err-required")}
        variant="filled"
        fullWidth>
        <InputLabel
            id={props.confirm ? 'passwordConfirmation-label' : 'password-label'}
            htmlFor={props.confirm ? 'passwordConfirmation' : 'password'}
            disableAnimation={true}
            required={props.requiredDisplay}>{props.label_value ? props.label_value : (props.confirm ? "Confirmez votre mot de passe" : "Mot de passe") }
        </InputLabel>     
            
        <FilledInput
            id={props.confirm ? 'passwordConfirmation' : 'password'}
            name={props.confirm ? 'passwordConfirmation' : 'password'}
            type={showPassword ? 'text' : 'password'}
            disableUnderline={true}              
            placeholder="XXXXXXXXXXXX"
            value={props.confirm ? props.formik.values.passwordConfirmation : props.formik.values.password}
            onChange={props.confirm ? props.formik.handleChange('passwordConfirmation') : props.formik.handleChange('password')}
            error={props.confirm ? (props.formik.touched.passwordConfirmation && Boolean(props.formik.errors.passwordConfirmation)) : (props.formik.touched.password && Boolean(props.formik.errors.password))}
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                >
                {
                    showPassword ? <img src={PwdNoEye} width="40px" alt="Icône cacher le mot de passe" /> :
                    <img src={PwdEye} width="40px" alt="Icône montrer le mot de passe" />
                }
                </IconButton>
            </InputAdornment>
            }
        />
        <FormHelperText>
            { props.confirm ? props.formik.errors.passwordConfirmation : props.formik.errors.password }
        </FormHelperText>
    </FormControl>
    </>
    }
    </>
}

export default QokkaPasswordInput;