import React from 'react';
import './SosVictimes.component.css';

function SosVictimesComponent() {
  return (
    <div className="sos-container">
      <div className="inner-container">
        <div>
          <img className="sos-image" src="/sticker-entreprise-engagee.png" alt="" />
        </div>
        <div className="sos-text">
          <p>
            Si vous êtes victime d'usurpation d'identité, si vous souhaitez être accompagnée, contactez-nous à l'adresse suivante :
          </p>
          <p><a className="sos-link" href="mailto:sosvictimes@idverif.com">sosvictimes@idverif.com</a></p>
        </div>
      </div>
    </div>
  );
}

export default SosVictimesComponent;
