import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import AuthService from "../services/auth.service";
import i18n from "../i18n";
import { Button, Collapse } from '@mui/material';
import { Link, useHistory, useLocation } from "react-router-dom";
import React from "react";
import './5QokkaSignup.component.css';
import LogoQOKKA from "../icons/logoQOKKA.svg";
import { truncateSync } from "fs";
import QokkaPasswordInput from "./atoms/QokkaPasswordInput.component";
import QokkaTextInput from "./atoms/QokkaTextInput.component";
import QokkaCheckboxInput from "./atoms/QokkaCheckboxInput.component";
import './22QokkaLogin.component.css';
import userService from "../services/user.service";
import IUser from "../types/user.type";
import { routes } from "../utils/routes";
import eventBus from "../common/EventBus";

const baseRouteUrl = "/" + i18n.language;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Cette adresse email n'est pas valide.")
    .required("Ce champ est requis !"),
  password: Yup.string().required("Ce champ est requis !")

});

interface PostData {
  email: string,
  password: string,
  loading: boolean,
  message: string,
  messageDetailed: string
}

const Login: React.FunctionComponent = () => {
  const [loginState, setLoginState] = useState<PostData>({
      email: "",
      password: "",
      loading: false,
      message: "",
      messageDetailed: ""
      
  });

  const history = useHistory();
  const location = useLocation<{ from: string }>();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    
    onSubmit: (values) => {
      const { email, password} = values;

      setLoginState({
        ...loginState,
        message: "",
        loading: false,
      });

      AuthService.login(
        email, 
        password, 
      ).then(
        token => {
          console.log("here 0", token)
          return token;
        },
        error => {
          let resMessage; 
          console.log("here 5",resMessage)
          console.log("here jpp", error)
          let messageDetailed;
          if (error.response?.status == 401)
          {
            resMessage = "Echec de l&rsquo;authentification. Si vous avez utilisé un prélèvement SEPA, veuillez retenter dans quelques minutes le temps que le paiement soit finalisé."
            if (error.response?.data?.message && 'string' === typeof error.response?.data?.message){
              messageDetailed = error.response?.data?.message
            }
            
          }
          else
          {
            resMessage = (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          }

          setLoginState({
            ...loginState,
            loading: false,
            message: resMessage,
            messageDetailed: messageDetailed
          });
          return Promise.reject(resMessage);
        }
      )
      .then((response) => {
        console.log("here 1",response)
        return Promise.resolve(userService.getUserInfo())
      })
      .then(userResponse => {
        const user:IUser = userResponse.data;
        console.log("here 2",userResponse)
        userService.setUserInfo(user)
        eventBus.dispatch("login")

        setLoginState({
            ...loginState,
            loading: false
        });
  
        let { from } = location.state || { from: baseRouteUrl + routes["myAccount"].path };
        history.replace(from);
        return ;
      })
      .catch()
      {   
        
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
    }
  });

  return (
    
    <div className="signin">
      <div className="info-block">
        <h1>Connectez-vous<br/>à QOKKA</h1>
        <p className="blue">Bonjour, ravi de vous retrouver&nbsp;!</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="error-message" dangerouslySetInnerHTML={{__html: loginState.message}}></div>
        <QokkaTextInput name="email" label="Email" placeholder="ex&nbsp;: paul.dupont@mail.com" requiredDisplay={true} formik={formik} />
        <QokkaPasswordInput confirm={false} requiredDisplay={true} formik={formik}/>
        {loginState.messageDetailed ? <div className="error-message">{loginState.messageDetailed} </div> : null}
        <Button className="connect medium btn" color="primary" variant="contained" type="submit">Se connecter</Button>
        <a className="medium" href={baseRouteUrl + "/not-connect-change-password"}>J&rsquo;ai oublié mon mot de passe</a> 
      </form>          
      <div className="login-text-en-bas">
        <p>Vous n&rsquo;êtes pas inscrit à QOKKA&nbsp;?</p>
        <a className="medium" href={baseRouteUrl + "/register"}>Inscrivez-vous</a> 
      </div>
      
    </div>
    
  );

};

export default Login;

