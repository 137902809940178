import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { TRANSLATIONS_FR } from "./translations/fr/translations";
import { TRANSLATIONS_EN } from "./translations/en/translations";

// https://stackoverflow.com/questions/57002844/react-and-i18n-translate-by-adding-the-locale-in-the-url

// pour mettre ce fichier en typeScript, on peut 
// étudier les codes ici https://github.com/i18next/react-i18next/tree/master/test/typescript
// cependant c'est un peu du luxe pour l'instant car les erreurs de types ne vont pas venir de i18n
// car on va faire un usage standard de i18n et son usage est assez indépendant du reste du code.
// donc je reporte ce travail à plus tard.
i18n
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      fr: {
        translation: TRANSLATIONS_FR
      }
    },
    whitelist: ['en', 'fr'],
    fallbackLng: ['fr'], // langage 
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: '.'
    }
  })

export default i18n