import i18n from "../i18n";
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import AuthService from "../services/auth.service";
import QokkaPasswordInput from "./atoms/QokkaPasswordInput.component";
import { Button } from "@mui/material";
import "./25QokkaNewMotdepasse.component.css"

const baseRouteUrl = "/" + i18n.language;


const validationSchema = Yup.object().shape({

    password: Yup.string()
      .required("Ce champ est requis !") 
      .test(
        "len",
        "Le mot de passe doit avoir entre 6 et 40 caractères.",
        (val: any) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40 
      ).test("isValidPass", "Doit contenir au moins une\nminuscule et une majuscule.", (value: any) => { // code issue de https://stackoverflow.com/questions/65657804/how-to-validate-a-password-with-yup-when-minimum-amount-of-conditions-met
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        //const hasNumber = /[0-9]/.test(value);
        //const hasSymbole = /[!@#%&]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 2; 
        const conditions = [hasLowerCase, hasUpperCase ] //, hasNumber, hasSymbole];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      })
      ,
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], "Les mots de passe ne sont pas les mêmes.").required("Ce champ est requis !"),

  });

interface PostData {

    password:string,
    passwordConfirmation:string, 
    messageFromServer:string,
    successful:boolean
}
const NewPasswordComponent: React.FunctionComponent = () => {
    const [formValues, setChangePasswordComponentValues] = useState<PostData>({
        password:"",
        passwordConfirmation:"", 
        messageFromServer:"",
        successful:false
    });
    const formik = useFormik({
        initialValues: {
            password:"",
            passwordConfirmation:"", 
        },
        validationSchema: validationSchema,
        
        onSubmit: (values) => {
          const { password, passwordConfirmation} = values;
          //console.log(values)
          //console.log(window.location.href)

          let params = (new URL(window.location.href)).searchParams;
          //console.log(params.get("id"),params.get("token"))
          setChangePasswordComponentValues({
            ...formValues
          });
          AuthService.change_password_from_mail_link(
            password,
            params.get("id"),
            params.get("token")
          ).then(
            response => {
                console.log("debug", response)
                setChangePasswordComponentValues({
                ...formValues,
                messageFromServer: response.data.message,
                successful: true,

              });
              window.location.href = baseRouteUrl +"/mdp-reinitialised-ok";
    //          window.location.href = baseRouteUrl +"/payment"; // après l'inscription, redirection vers le paiement.
            },
            
            error => {
              console.log("debug error",error)
              if (error.response === undefined){
                // cas rencontré lorsque le back ne tourne pas. 
                // 
                let resMessage;
                resMessage = "Erreur de connection au serveur.\n Veuillez réessayer, si le problème persiste, vous pouvez contacter le support."
                setChangePasswordComponentValues({
                  ...formValues,
                  successful: false,
                  messageFromServer: resMessage,
                });


                return;
    
              }
              //console.log(error.response.data.toString())
              let resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log(resMessage)
              if (error.response.data.toString() === "Email already exists"){
                resMessage = "Email already exists"
              }
              console.log(resMessage)
              setChangePasswordComponentValues({
                ...formValues,
                successful: false,
                messageFromServer: resMessage,
              });
            
    
              /*
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              setFormValues({
                ...formValues,
                successful: false,
                message: resMessage,
              });*/
    
            }
          );      
        }
      });
    return <div className="modifyPassword">

        {/* 
        Page dont l'utilisateur arrive lorsqu'il 
        clique sur un lien de mail pour réinitialiser 
        son mot de passe.
        */}
        
        <div className="background_div"> 

        <h2>Créer un nouveau <br/> mot de passe</h2>

        </div>
        
        <div className="utils_div">

        <form onSubmit={formik.handleSubmit}>
              <QokkaPasswordInput confirm={false} requiredDisplay={true} formik={formik}/>
              <QokkaPasswordInput confirm={true} requiredDisplay={true} formik={formik}/>
              {formValues.messageFromServer ? <div className="error-message">{formValues.messageFromServer}</div> : null}
              <div style={{ height: "3rem"}}></div>
              <Button style={{width:"100%"}} className="pay btn" color="primary" variant="contained" type="submit">Valider</Button>
        </form>

        </div>

    </div>

}


export default NewPasswordComponent;