import { Checkbox, FormControlLabel, Icon } from "@mui/material";
import { FunctionComponent } from "react";
import Checked from "../../icons/checked.svg";
import NotChecked from "../../icons/not_checked.svg";
import './QokkaInput.component.css';
import {Element as Elt } from 'react-scroll'

type QokkaCheckboxInputProps = {
    name: string
    default_value?: boolean
    label: any
    formik: any
}

const QokkaCheckboxInput: FunctionComponent<QokkaCheckboxInputProps> = props => {
    return <>
    <Elt name={props.name + '-anchor'} className="anchor" />
    <FormControlLabel
        control={<Checkbox icon={<img alt="Icône de case non cochée" width="25px" src={NotChecked} />}
            checkedIcon={<img alt="Icône de case cochée" width="25px" src={Checked} />} />}        
        name={props.name}
        label={props.label}
        onChange={props.formik.handleChange}
        checked={(props.formik.values[props.name] === undefined) ? props.default_value : props.formik.values[props.name] }
        value={props.formik.values[props.name]} />
    </>
}

export default QokkaCheckboxInput