import axios from 'axios';
import authHeader, { QokkaCallGet, QokkaCallPost } from './auth-header';
import config from "../config/config"
import IUser from '../types/user.type';

const API_URL = config.ApiUrl();

class UserService {
  /*getPublicContent() {    
    return axios.get(API_URL + '/content/public');
  }*/

  getUserInfo() {
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + '/content/user_info', { headers: authHeader(/*multipart_form_data_boolean, undefined*/) });
    // authHeader contient le token à envoyer au back.
  }

  setUserInfo(user:IUser|null) {
    if (!user)
      localStorage.removeItem("user")
    else
      localStorage.setItem("user", JSON.stringify(user))
  }

  // post upload files response contains the certificate if the analyse is successful
  postUploadFiles(document_type: string | undefined, filelist: File[] | null){ // File et non FileList car on va les envoyer un à la fois.

    if (document_type === undefined || filelist === null){
      var utils_promise = new Promise(() => "erreur site web composant upload document.")

      return utils_promise

    } // chantier pour envoyer les binaires du fichier.
    console.log("debug files",filelist)
    //let raw_data : string | ArrayBuffer | ReadableStream= "pas de data / buffer"; 

    //file.arrayBuffer().then((value)=> {raw_data = value}).catch(error => console.log("error arrayByffer", error))
    //raw_data = file.stream()
    const formData = new FormData();

    for (var j: number=0; j< filelist.length; j++){
      formData.append("fichier"+j.toString(), filelist[j], filelist[j].name)
    }
    
    /* 
    // utilisation de la méthode fetch ici.
    let myHeaders_for_fetch_method = new Headers()
    let utils_fetch_token : string = authHeader().Authorization === undefined ? "none": authHeader().Authorization!
    myHeaders_for_fetch_method.append('Authorization', utils_fetch_token)
    fetch(API_URL + "/analyse/process_file", {method: "post",body :formData, headers: myHeaders_for_fetch_method })*/
    // formData
    var multipart_form_data_boolean = false // 

    function blobToBase64(blob : Blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };
    
    /*
    // le plus rapide que j'ai trouvé est d'enchaîner les promesses pour l'envoie de deux images.

    function 2blobToBase64([blob1, blob2]: Blob[]) {
      return new Promise((resolve, _) => {
        const reader1 = new FileReader();
        const reader2 = new FileReader();

        reader1.onloadend = () => resolve([reader1.result, reader2.result]);
        reader1.readAsDataURL(blob1);
        reader2.readAsDataURL(blob2);
        
        //reader2.onloadend = () => resolve(reader2.result);
        
      });
    }*/

    let utilsReader = new FileReader()
    utilsReader.readAsDataURL(filelist[0])

    const promises = []

    promises[0] = blobToBase64(filelist[0])

    if (filelist.length === 2)
    {
      promises[1] = blobToBase64(filelist[1])
    }

    return Promise.all(promises)
      .then((results) => {
        if (results.length === 1)
        {
          return QokkaCallPost(API_URL + "/analyse/process_file", {document_type: document_type,number_image: 1,image: results[0]}, {headers: authHeader(/*multipart_form_data_boolean, formData*/) })
        }
        else if (results.length === 2)
        {
          return QokkaCallPost(API_URL + "/analyse/process_file", {document_type: document_type, number_image: 2, image1: results[0], image2: results[1]}, { headers: authHeader(/*multipart_form_data_boolean, formData*/) })
        }
        else
        {
          return Promise.reject("Invalid number of files")
        }
      })
      .catch((e) => { return Promise.reject(e)} )
      
    //return Promise.reject("aassssssssshhhhh")
    
    //console.log("here",result64); // https://stackoverflow.com/questions/18650168/convert-blob-to-base64
    //console.log(result64);
  }

  getCertificate(certificatekeyresocan : string){
    return QokkaCallPost(API_URL + "/analyse/getcertificate", {
      certificatekeyresocan: certificatekeyresocan, 
      
    }, { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }
/*  getAdminBoard() {
    return axios.get(API_URL + '/content/admin', { headers: authHeader() });
  }*/
  modify_info(email: string, lastName: string, firstName: string, companyName: string, sirenNumber: string, subject_to_VAT: boolean, country: string, city: string, zipCode: string) {

    console.log("debug modify_info", email, lastName, firstName, companyName, sirenNumber, subject_to_VAT, country, city, zipCode)
    return QokkaCallPost(API_URL + "/user_service/modify_info", {
        email: email, 
        lastName: lastName, 
        firstName: firstName, 
        companyName: companyName, 
        sirenNumber: sirenNumber,
        subject_to_VAT: subject_to_VAT,
        country: country,
        city: city,
        zipCode: zipCode

      }, { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  resiliatiatePackage(){
    return QokkaCallPost(API_URL + "/user_service/resiliate_package", {
    }, { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  cancelResiliatiation(){
    return QokkaCallPost(API_URL + "/user_service/cancel_resiliation", {
    }, { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  change_password(currentPassword: string, newPassword: string){
    
    return QokkaCallPost(API_URL + "/auth/change-password", {
      oldPassword: currentPassword,
      newPassword: newPassword

    }, { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
}

  fetch_1(){
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + "/content/fetch_1", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  fetch_1_light(){
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + "/content/fetch_1_light", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  fetch_1_light2(){
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + "/content/fetch_1_light2", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  fetch_1_light3(){
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + "/content/fetch_1_light3", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  fetch_1_light4(){
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + "/content/fetch_1_light4", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  fetch_2(){
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + "/content/fetch_2", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  fetch_3(){
    var multipart_form_data_boolean = false
    return QokkaCallGet(API_URL + "/content/fetch_3", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }

  getSetupIntent() {
    return QokkaCallGet(API_URL + "/pay/setup", { headers: authHeader(/*multipart_form_data_boolean, undefined*/) })
  }
/*
  
  postUploadFiles(file:any , onUploadProgress:any) { // TODO: change the Type of file and onUploadProgress

    let formData = new FormData();
    formData.append("file", file);
    return QokkaCallPost(API_URL + '/process/analyse', formData, { headers: authHeader(), onUploadProgress, })

  //

  }*/
  
}

export default new UserService();
