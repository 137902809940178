import React, { useState } from "react";
import { AppBar, Box, Button, TextField } from "@mui/material";
import { Link } from 'react-router-dom';
import i18n from "../i18n";
import LogoQOKKA from "../icons/logoQOKKA.svg";
import { Field, useFormik, FormikProvider } from "formik";
import AuthService from "../services/auth.service";
import * as Yup from "yup";
//import valid from 'card-validator'
import "./7QokkaConfirmPaiement.component.css"
import queryString from "query-string"
import { routes } from "../utils/routes";
import PaymentValid from "../icons/payment-valid.svg";
import PaymentInsertCB from "../icons/payment-insert-cb.svg";
import PaymentStep3 from "../icons/payment-step3.svg";
import authService from "../services/auth.service";
const baseRouteUrl = "/" + i18n.language;

type OfferComponentPropsType = {
    offer: {
        name: string,
        consultations: number,
        color: {
            background: string,
        }
    }
}

const OfferComponent: React.FunctionComponent<OfferComponentPropsType> = (props: OfferComponentPropsType) => {
  return <>
      <span className="name">{props.offer.name}</span>
      <span className="consultations" style={{backgroundColor: props.offer.color.background }}>{props.offer.consultations} consultation{props.offer.consultations > 1 ?'s':''}</span>
  </>
}

type PaymentConfirmedComponentPropsType = {
  location: Location
}

export const ConfirmedPaiement: React.FunctionComponent<PaymentConfirmedComponentPropsType> = (props) => {
    const queryStringParams = queryString.parse(props.location.search)
    const user = authService.getCurrentUser()

    let product = {
        consultations: parseInt(queryStringParams["product_consultations"] as string),
        name: queryStringParams["product_name"] as string,
        color: {
            background: queryStringParams["product_color"] as string
        }
    }

    return (
    <div className="payment-confirm">
      <div className="step-image-container">
        <img alt="paiement step 3" src={PaymentStep3} />
      </div>
      <h1>{ user ? "Paiement réussi" : "Bienvenue chez QOKKA" }</h1>
      <div className="splitter">
        <div className="chosen-formula">
          <h2>{ user ? "Votre achat" : "Votre formule choisie" }</h2>
          <OfferComponent offer={product}/>
        </div>
        <div className="right-panel">
          <div className="form">
            <img alt="validation" src={PaymentValid} width="60px" />
            <p className="medium confirm">Votre paiement a bien été validé&nbsp;!</p>
            <img alt="CB insérée" src={PaymentInsertCB} width="110px" />
          </div>
          <div className="message-container" style={{paddingTop: "1rem"}}>
            {!user && <p className="message">Nous sommes ravis de vous compter parmi nous&nbsp;!</p> }
            <Button className="btn add-document" color="primary" variant="contained" onClick={() => {
                window.location.href = baseRouteUrl + routes['upload'].path
            }}>Ajouter un document</Button>
          </div>
        </div>
      </div>
    </div>);
    
}