/*export default {
    apiUrl: process.env.REACT_APP_API_URL,
    stripePublicKey: process.env.REACT_APP_STRIPE_PUB_KEY ? process.env.REACT_APP_STRIPE_PUB_KEY ! 
}*/

class Config {
    ApiUrl() { return process.env.REACT_APP_API_URL }
    StripePublicKey() {
        if (!process.env.REACT_APP_STRIPE_PUB_KEY)
            return ""
        return process.env.REACT_APP_STRIPE_PUB_KEY
    }
}

export default new Config();