import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import './index.css';
import './fonts/Rubik/Rubik-VariableFont_wght.ttf';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: '#9af7d0',
    },
    secondary: {
      main: '#0061d1',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 900,
      lg: 1400,
      xl: 1500,
    },
  },
});

theme = createTheme(theme, {
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    [theme.breakpoints.up('lg')]: {
                        maxWidth: '100%',
                    },
                }
            }
        }
    }
})

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
