
import './WhoTile.css'

interface WhoTileProps {
    image: React.ReactNode,
    title: string  
    points: JSX.Element[]
}
  
export const WhoTile: React.FunctionComponent<WhoTileProps> = props =>
    <div className="who-tile">
        <div className="title">
        <div className="logoContainer">{props.image}</div>
        
        <h4>{props.title}</h4>
        </div>
        <ul>
        {
            props.points.map(point => (
            point
            ))
        }
        </ul>
    </div>