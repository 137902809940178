import { useFormikContext, FormikErrors } from "formik"
import { useEffect, useState } from "react"
import { scroller } from 'react-scroll';

export const getFieldErrorNames = (formikErrors:FormikErrors<string>) => {
  const transformObjectToDotNotation = (obj:any, prefix = "", result:string[] = []) => {
    Object.keys(obj).forEach(key => {
      const value:string = (obj[key] as string)
      if (!value) return

      const nextKey:string = prefix ? `${prefix}.${key}` : key

      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })

    return result
  }

  return transformObjectToDotNotation(formikErrors)
}

type ScrollToFieldErrorPropsType = {
    formik:any 
}

export const ScrollToFieldError = (props:ScrollToFieldErrorPropsType) => {
  useEffect(() => {
    if (props.formik.isValid) return
    const fieldErrorNames = getFieldErrorNames(props.formik.errors as FormikErrors<string>)
    if (fieldErrorNames.length <= 0) return

    console.log("Scroll to : " + fieldErrorNames[0])

    scroller.scrollTo(fieldErrorNames[0] + '-anchor', {
        duration: 250,
        smooth: 'easeInOutQuart',        
      });

    let label = document.querySelector<HTMLLabelElement>('#' + fieldErrorNames[0] + '-label')
    
    if (label)
        label.focus()
  }, [ props.formik.submitCount ])

  return null
}