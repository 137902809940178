import { UndoRounded, WindowSharp } from "@mui/icons-material";
import i18n from "../i18n";
import { donnees_bruts, list_consulations_generees_from_beginning } from "../utils/constants";
import "./13QokkaHistorique.component.css";
import valid_icon from "../icons/13HistoryComponent/valid_icon.svg";
import non_conforme_icon from "../icons/13HistoryComponent/non_conforme_icon.svg";
import non_reconnu_icon from "../icons/13HistoryComponent/non_reconnu.svg";
import { bool, string } from "prop-types";
import { useEffect, useLayoutEffect, useState } from "react";
import UserService from "../services/user.service";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Paper from "@mui/material/Paper";
const baseRouteUrl = "/" + i18n.language;


function src_image_depending_of_result(result: string){

    let icon_to_return;

    switch(result){
        case "conforme":
            icon_to_return = valid_icon
            break;

        case "non conforme":
            icon_to_return = non_conforme_icon
            break;

        case "non reconnu":
            icon_to_return = non_reconnu_icon
            break;

    }

    return icon_to_return;

}

export type singleConsultationType = {
    date: string,
    document_type: string,
    result: string,
    certificat_id: string | null
}

export const Historique: React.FunctionComponent = () => {

    const [stateFetch1, setStateFetch1] = useState({
        user : {
            firstname: "err_connect",
            lastname: "err_connect",
            total_consultations_generated: "err_connect",
            Ouverture_du_compte: "err_connect",
        }
    })

    const [stateFetch3, setStateFetch3] = useState({
    
        consultations : []
    
    })

    useEffect(() => {

        UserService.fetch_1_light().then((response: any) => {

            console.log("reponse fetch1", response)
            setStateFetch1({
                user : {
                    firstname: response.data.user.firstname,
                    lastname: response.data.user.lastname,
                    total_consultations_generated: response.data.user.total_consultations_generated,
                    Ouverture_du_compte: response.data.user.Ouverture_du_compte,
                }
            })
        }).catch(
            error => console.log("error from historique consultations fetch 1" ,error)
        )

    },[])

    useLayoutEffect(() => {
        UserService.fetch_3().then((response: any) => {
        
        console.log("reponse fetch3", response)
        
        setStateFetch3({
            consultations : response.data.consultations
        })
    }).catch(
        error => console.log( "error from historique consultations fetch 3" ,error)
    )

    },[])

    return <div className="historique">

        <h5 className="bonjour_user">Bonjour {stateFetch1.user.firstname} {stateFetch1.user.lastname}</h5>
        
        <div className="background_div"> 
        <h2>Historique de vos consultations</h2>
        </div>
        <div className="div_flex_helper">
        <div className="div_laws">
            &laquo;Conformément aux lois en vigueur, nous ne conservons pas les données, vous ne pouvez pas consulter un document analysé depuis cette page.&raquo;
        </div>

        <p style={{fontSize: "1.26rem", marginTop: "4rem", color: "#8390b3", fontWeight: 200}}> Ouverture du compte le {stateFetch1.user.Ouverture_du_compte}</p>
        

        <div className="blue_gradient_helper">


        <div className="total_consultations_div">
            <span style={{textTransform: "uppercase", paddingTop: "2.6rem"}}>Total <br/>
            consultations <br/>
            générées <br/></span>
            
            <p style={{textDecoration: "underline", color: "#4a9cea", fontSize: "3.5rem"}}>{stateFetch1.user.total_consultations_generated}</p>
        
        </div>
        </div>


        </div>
        
        <div className="consultations_div_container">

        {/*
        <div className="utils_div" style={{background: "white"}}>

        
        {list_consulations_generees_from_beginning.map(element => <div className="single_consultation">

            <div>
                <p><span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}>date</span></p>
                <p style={{color: "#0061d1"}}>{element.date}</p>
            </div>

            <div style={{paddingRight: "5px"}}>
                <p><span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}>type de doc</span></p>
                <p>{element.document_type}</p>
            </div>
            
            <div>
                <p><span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}>résultat</span></p>
                <p><span style={{textTransform: "uppercase"}}>{element.result}</span></p>
                <img alt="img result" src={src_image_depending_of_result(element.result)}></img>
            </div>

        </div>)}

        </div>
        
        */}
        
        {stateFetch3.consultations !== undefined ? 
        <TableContainer className="consultations_table" component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
            {stateFetch3.consultations.map((element : singleConsultationType) => (
                <TableRow
                //key={element.date} // key is needed ? there is no 
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                {/*
                <TableCell component="th" scope="row">
                {element.document_type}
            </TableCell>
                */}
                <TableCell component="th" scope="row">
                <div className="single_consultation">
                <div>
                    <p><span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}>date</span></p>
                    <p style={{color: "#0061d1"}}>{element.date}</p>
                </div>

                <div style={{paddingRight: "5px"}}>
                    <p><span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}>type de doc</span></p>
                    <p>{element.document_type}</p>
                </div>
                
                <div>
                    <p><span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}>résultat</span></p>
                    <p><span style={{textTransform: "uppercase"}}>{element.result}</span></p>
                    {((element.result === "conforme") || (element.result === "non conforme")) ?  // dans le cas non reconnu, il n'y a pas de certificat



                    <p >
                        <button id={element.certificat_id === null ? "" : element.certificat_id} // change id by certificate id
                        
                        //href={baseRouteUrl + "/history"}
                        
                        onClick={
                            async () => { 

                        // TODO here : call api to get the document with element.certificat_id

                        let certif;
                        if (element.certificat_id !== null) {

                        let response = await UserService.getCertificate(element.certificat_id).then((response: any) => {
                            certif = response.data.certificat;
                            console.log("certif", certif)
                            var a = document.createElement("a"); //Create <a>
                            // j'ai mis longtemps à trouver qu'il fallait rajouter .certificat_telechargement pour que le download fonctionne.
                            a.href = "data:image/pdf;base64," + certif.certificat_telechargeable; 


                            a.download = "certificat.pdf"; //File name Here
                            a.click(); //Downloaded file
                            //window.open("<img src='data:image/pdf;base64,  + certif'></img>" ) //baseRouteUrl + "/history/" + element.certificat_id, certif)

                            /*
                            certificateWindow?.document.write(
                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                    encodeURI(certif) + "'></iframe>"
                            )*/

                        }).catch((error: any) => {console.log(error)})

                        }                
                        
                        let this_button_tag: any = document.getElementById(element.certificat_id === null ? "" : element.certificat_id)
                        
                        this_button_tag.href = "#"

                    }} >Téléchargez votre certificat</button>
                    </p> 
                    : 
                    null
                    }
                    <img alt="img result" src={src_image_depending_of_result(element.result)}></img>
                </div>
                </div>
                </TableCell>
                {
                /*
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
                */
                }
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
        :
        <></>
        }
        

        </div>
        
    </div>

}