import { Button, CircularProgress } from "@mui/material";
import LogoQOKKA from "../../icons/logoQOKKA.svg";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import UserService from "../../services/user.service";

const baseRouteUrl = "/" + i18n.language;

export interface PropsButtonIsLoading{
    loading_state: boolean,
    component_to_return_if_not_loading: JSX.Element,
    change_parent_loading_status : (code: number, message_input: string, certificate: string, certificat_raw_base_64:string, analysisID: string, score: number | null | string) => void,
    document_type: string,
    acceptedFiles: File[] | null

}

export const ButtonIsLoading: React.FunctionComponent<PropsButtonIsLoading> = (props: PropsButtonIsLoading) => {

    const [state, setState] = useState({loadingState:props.loading_state,code:0, message:"",certificate:"", certificat_raw_base_64:"",analysisID:"", score: ""});

    useEffect(() => {

        UserService.postUploadFiles(props.document_type, props.acceptedFiles)
            .then((response: any)=> {
                console.log("from response","response du back :", response)

                if (response.data.code !== 0)
                {
                    let msg = response.data.message;

                    if (response.data.code == 33)
                    {
                        msg = "Ville, code postal ou pays de l&rsquo;utilisateur non reconnus"
                    }

                    setState({loadingState:false,
                        code: response.data.code,
                        message:msg,
                        certificate:"",
                        certificat_raw_base_64: "",
                        analysisID: "",
                        score: ""});
                    return ;
                }

                setState({loadingState:false,
                    code: response.data.code,
                    message:response.data.message,
                    certificate:response.data.certificate,
                    certificat_raw_base_64: response.data.certificat_base_64,
                    analysisID: response.data.analysisID,
                    score: response.data.score});
            })
            .catch(error => { 
                console.log("Error from useEffect ButtonIsLoading:" + error.toString())
                if (error.response.status === 404 && error.response.data === "no more credit")
                {
                    setState({loadingState:false,code: 4243, message:"Vous n'avez plus de crédits de consultation.", certificate:"", certificat_raw_base_64:"", analysisID:"" , score:""})
                    return
                }
                
                setState({loadingState:false,code: 4242, message: "Une erreur s'est produite lors de l'analyse.", certificate:"", certificat_raw_base_64:"", analysisID:"" , score:""});
            })          

    }, []);

    
    if (state.loadingState) {
        
        return <div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div>

    }
    console.log("debug here 45", state.message, state.certificate)
    console.log("debug here 46", state)
    props.change_parent_loading_status(state.code, state.message, state.certificate, state.certificat_raw_base_64,state.analysisID, state.score) //reload parent component. 

    return props.component_to_return_if_not_loading

}