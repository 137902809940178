
import React from "react";
import i18n from "../i18n";
import { donnees_bruts } from "../utils/constants";
import './CGU.css';

const baseRouteUrl = "/" + i18n.language;

export const CGU: React.FunctionComponent = () => {

    return < div className="cgu" style= {{ display: "flex", flexDirection: "column", alignItems: "center"}}>

        <div className="cguDiv">

        <h2><span style={{fontWeight: "bold"}}>Conditions Générales d&apos;Utilisation du Logiciel QOKKA</span></h2>



        <p>En vigueur depuis juin 2022</p>
        <div style= {{height: "0.1rem", backgroundColor: "black", width : "100%"}}></div>
        <div style= {{height: "1rem"}}></div>

        <p style= {{textAlign: "left"}}> Les présentes Conditions Générales d&apos;Utilisation (“CGU”) sont applicables à tout accès et à toute utilisation du
            Logiciel dénommé QOKKA (“le Logiciel”).
            Le Préambule fait partie intégrante des présentes CGU.</p>
        
        <div style= {{height: "1rem"}}></div>
        <h3 ><span style={{fontWeight: "bold", textDecoration: "underline"}}>Préambule</span></h3>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>L&apos;utilisation du Logiciel, qu&apos;il soit gratuit (Compte d&apos;Essai) ou payant (lorsque l&apos;Utilisateur dispose d&apos;un abonnement
        au Logiciel) est soumise au respect des présentes CGU par l&apos;Utilisateur.
        L&apos;Utilisateur confirme avoir lu, compris et accepté les présentes CGU. L&apos;utilisation du Logiciel implique également
        l&apos;acceptation des Conditions Générales de Vente (CGV), lors de la Souscription, par le Créateur du compte, un
        Administrateur ou un Décisionnaire.
        Ces Conditions Générales de Vente prévalent sur les présentes CGU. QOKKA se réserve le droit de modifier ou
        d&apos;adapter les présentes CGU à tout moment, en notifiant toute nouvelle version à l&apos;Utilisateur, qui s&apos;engage à
        prendre connaissance de ces modifications. </p>

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 1 — Accès au Logiciel</span></h4>
        <div style= {{height: "1rem"}}></div>
        <h5><span style={{fontWeight: "bold"}}>1.1 </span> <span style={{fontWeight: "bold", textDecoration: "underline"}}>Général</span></h5>
        <div style= {{height: "1rem"}}></div>
            <p style= {{textAlign: "left"}}>
            QOKKA ne permet l'accès au Logiciel qu'aux personnes spécifiquement autorisées par QOKKA et ses
Utilisateurs. QOKKA s’engage à mettre en œuvre tous les moyens permettant à l’Utilisateur un accès fiable et
rapide au Logiciel. Un service d’assistance, limité à l’utilisation du Logiciel dans le cadre d’un accès payant, est
disponible dans les termes de l’abonnement souscrit. 
            </p>
            <p style= {{textAlign: "left"}}>De son côté, l’Utilisateur reconnaît que le Logiciel constitue une plate-forme particulièrement complexe,
        notamment en termes de technologie informatique et s’engage donc à supporter les risques d’inadéquation
        ou d’indisponibilité du Logiciel. 
        </p>

        <p style= {{textAlign: "left"}}>QOKKA peut recommander des prérequis techniques ou certaines configurations pour utiliser le Logiciel.
        L’Utilisateur est responsable du suivi de ces prérequis techniques ou recommandations, et s’assure dans tous
        les cas que son équipement est compatible avec le Logiciel. L’Utilisateur est seul responsable de sa connexion
        à internet ainsi que de tous les frais afférents. 
        </p>

        <p style= {{textAlign: "left"}}>Il déclare comprendre que QOKKA ne peut pas être tenue responsable en cas d'interruption d’Internet, de
        virus affectant ses données et/ou logiciels, d’une utilisation abusive des mots de passe de Compte et, plus
        généralement, de tous les dommages causés par des tiers.  
        
        </p>

        <h5><span style={{fontWeight: "bold"}}>1.2 </span> <span style={{fontWeight: "bold", textDecoration: "underline"}}>Compte d'Essai</span></h5>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>QOKKA offre la possibilité de tester le service par l’utilisation d’un paiement à l’acte pour les utilisateurs
        souhaitant évaluer le Logiciel. Ce Compte d’essai est mis à disposition pendant une durée de trente (30) jours
        à compter de la mise en service et dans les conditions suivantes :  
        
        </p>
        <ul style= {{textAlign: "left"}}>
            <li>Chaque nouvel essai est de nouveau facturé sur une prestation à l’acte ;</li>
            <li>QOKKA peut, à la demande de l’Utilisateur, et sans y être obligée, permettre à l’Utilisateur d’utiliser le
            Compte d’essai pour une durée supplémentaire de 15 jours ;</li>
            <li>L’Utilisateur autorisé à utiliser un Compte d’essai reconnaît que l’accessibilité au Logiciel a pour seul objet
            de lui permettre de tester les fonctionnalités du Logiciel avant la souscription éventuelle d’un abonnement
            mensuel payant. Il s’interdit à ce titre de réaliser des traitements ou des prestations quelconques pour des
            tiers en utilisant son Compte d’essai ;</li>
            <li>La Responsabilité de QOKKA ne saurait être engagée à quel titre que ce soit du fait de l’utilisation du
            Logiciel à titre d’essai. QOKKA ne sera tenue à aucune indemnité d’aucune sorte en cas de
            dysfonctionnement du Logiciel, pendant toute la durée de l’essai. QOKKA se réserve la possibilité de
            supprimer sans préavis tout Compte d’essai ne respectant pas les dispositions qui précèdent ou toute autre
            disposition des présentes CGU. </li>
        </ul>

        <p style= {{textAlign: "left"}}>Dans l’hypothèse où l’essai n’est pas suivi par la Souscription d’un Compte, QOKKA pourra supprimer le Compte
        d’essai du Tiers autorisé et les données associées, dans un délai de douze (12) mois à compter de la fermeture du
        Compte d’essai.
 
        
        </p>

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 2 — Utilisation du Logiciel</span></h4>
        <div style= {{height: "1rem"}}></div>
 
        <p style= {{textAlign: "left"}}>L’Utilisateur est seul responsable de l’utilisation et de la mise en œuvre de moyens de sécurité, de protection et de
sauvegarde de ses équipements, de ses Contenus et logiciels. 
 
        </p>
        <p style= {{textAlign: "left"}}>L’Utilisateur s’engage à ne commettre aucun acte qui pourrait mettre en cause la sécurité de la plate-forme
logicielle et utiliser le Logiciel dans le respect des lois et règlements en vigueur. 

 
        </p>

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 3 — Responsabilités</span></h4>
        <div style= {{height: "1rem"}}></div>

        <p style= {{textAlign: "left"}}> QOKKA met en œuvre les mesures et moyens techniques nécessaires pour assurer la sécurité des connexions, des
contenus et des Données à Caractère Personnel de ses Utilisateurs. </p>
            
        <p style= {{textAlign: "left"}}> QOKKA fournit le Logiciel au terme d’une obligation de moyens.  </p>

        <p style= {{textAlign: "left"}}> QOKKA utilise des procédés et techniques conformes à l'état de l'art. Toutefois, en l'état actuel des connaissances,
les tests et les expériences ne peuvent couvrir toutes les utilisations possibles. QOKKA ne garantit pas que le
Logiciel fonctionnera sans interruption ou dysfonctionnement, ou sera exempt d’anomalies ou d’erreurs pouvant
être corrigées, ou qu'il est compatible avec du matériel ou une configuration autre que ceux expressément
approuvés par QOKKA.   </p>

    <p style= {{textAlign: "left"}}>L'Utilisateur est responsable de :  </p>
            
    <ul style= {{textAlign: "left"}}>
            <li>son accès et de son utilisation du Logiciel ; </li>
            <li>l'obtention et le maintien de tout l'équipement nécessaire pour accéder au Logiciel ; </li>
            <li>s’assurer que cet équipement est compatible avec le Logiciel. QOKKA se réserve le droit d'interrompre à
tout moment et temporairement l’accès au Logiciel </li>
            <li>pour effectuer une mise à jour, des interventions techniques de maintenance ou d'amélioration, qui
contribueront à leur bon fonctionnement ou pour réparer une panne : QOKKA fera ses meilleurs efforts
pour avertir l’Utilisateur de ces interruptions ;</li>

            <li>en cas d’indisponibilité des serveurs pour quelque motif que ce soit ; </li>
            <li>en cas d’impayés, de manquements aux CGU et CGV, d’atteintes par un tiers portées à la sécurité du
Logiciel et des Contenus hébergés par QOKKA. </li>
            <li>QOKKA ne sera tenue à aucune indemnité en cas d’interruption temporaire</li>
        </ul>

        <div style= {{height: "0.1rem", backgroundColor: "black", width : "100%"}}></div>
            <div style= {{height: "1rem"}}></div>
            <p>QOKKA – support@qokka.fr – 6 rue Neuve Saint-Pierre 75004 Paris – France</p>
            <p>Siret : 42493005500100 – Naf. 5829C – RCS MARSEILLE RESOCOM SAS au capital de 100’000 €</p>
            <p>N°TVA intracommunautaire : FR75424930055</p>
        </div>
        
       
        
    </div>
    
}