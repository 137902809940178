import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import AuthService from "../services/auth.service";
import i18n from "../i18n";
import { Button, CircularProgress, Collapse } from '@mui/material';
import { Link, RouteComponentProps } from "react-router-dom";
import React from "react";
import './5QokkaSignup.component.css';
import LogoQOKKA from "../icons/logoQOKKA.svg";
import { truncateSync } from "fs";
import QokkaPasswordInput from "./atoms/QokkaPasswordInput.component";
import QokkaTextInput from "./atoms/QokkaTextInput.component";
import QokkaCheckboxInput from "./atoms/QokkaCheckboxInput.component";
import productService, { ProductBackOptionType, ProductBackSubscriptionType } from "../services/product.service";
import queryString from "query-string"
import { routes } from "../utils/routes";
import PaymentStep1 from "../icons/payment-step1.svg";
import {Element as Elt, scroller, animateScroll} from 'react-scroll';
import { ScrollToFieldError } from '../utils/scrollFormik'

const baseRouteUrl = "/" + i18n.language;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Cette adresse email n'est pas valide.")
    .required("Ce champ est requis !"),
  password: Yup.string()
    .required("Ce champ est requis !") 
    .test(
      "len",
      "Le mot de passe doit avoir entre 6 et 40 caractères.",
      (val: any) =>
        val &&
        val.toString().length >= 6 &&
        val.toString().length <= 40 
    ).test("isValidPass", "Doit contenir au moins une\nminuscule et une majuscule.", (value: any) => { // code issue de https://stackoverflow.com/questions/65657804/how-to-validate-a-password-with-yup-when-minimum-amount-of-conditions-met
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value); 
      //const hasNumber = /[0-9]/.test(value); 
      //const hasSymbole = /[!@#%&]/.test(value); 
      let validConditions = 0;  
      const numberOfMustBeValidConditions = 2; 
      const conditions = [hasLowerCase, hasUpperCase ] //, hasNumber, hasSymbole];
      conditions.forEach((condition) =>
        condition ? validConditions++ : null
      );
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    })
    ,
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], "Les mots de passe ne sont pas les mêmes.").required("Ce champ est requis !"),
  company_name: Yup.string().required("Ce champ est requis !"),
  country: Yup.string().required("Ce champ est requis !"),
  city: Yup.string().required("Ce champ est requis !"),
  zipCode: Yup.string().required("Ce champ est requis !"),
  //intra_community_VAT_number: Yup.string().required("Ce champ est requis !"),
  lastname: Yup.string().required("Ce champ est requis !"),
  firstname: Yup.string().required("Ce champ est requis !"),
});

interface PostData {
  product_id: string,
  email: string,
  password: string,
  passwordConfirmation:string,
  company_name: string,
  country: string,
  city: string,
  zipCode: string,
  siren_number: string,
  subject_to_VAT: boolean,
  intra_community_VAT_number: string,
  lastname: string,
  firstname: string,
  receive: boolean,
  message: string,
  successful: boolean,  
}

type RegisterPropsType = {
  location: Location
}

type OfferComponentPropsType = {
  offer: ProductBackOptionType | ProductBackSubscriptionType
}

const OfferComponent: React.FunctionComponent<OfferComponentPropsType> = (props: OfferComponentPropsType) => {
  let price = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(props.offer.price / 100)  
  let reduction = props.offer.promo ? props.offer["promo-type"] == 'percent' ? Math.round(props.offer.price * props.offer.promo / 100) : props.offer.price : undefined
  let priceWithReduction = reduction ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format((props.offer.price - reduction) / 100) : undefined
  let unit = props.offer.type == 'option' ? "/l&rsquo;unité" : "/mois"
  let pricePerConsultation = props.offer.consultations > 1 ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format((props.offer.price - (reduction ? reduction : 0)) / (props.offer.consultations * 100)) : undefined

  return <>
      <span className="name">{props.offer.name}</span>
      <span className="consultations" style={{backgroundColor: props.offer.color.background }}>{props.offer.consultations} consultation{props.offer.consultations > 1 ?'s':''}</span>
      { props.offer.promo && <span className="stroken" style={{color: '#8390b3'}} dangerouslySetInnerHTML={{ __html: price+unit }} /> }
      <span className="price"><span className="number">{ props.offer.promo ? priceWithReduction : price }</span><span className="unit" style={{color: '#8390b3'}} dangerouslySetInnerHTML={{ __html: unit}}></span></span>
      <span className="comment">{props.offer.type == 'option' ? "Sans engagement" : "Pas de dépassement possible"}</span>
      { pricePerConsultation ? <span className="pricePerConsultation">{pricePerConsultation}/consultation</span> : <span className="pricePerConsultation">&nbsp;</span> }
  </>
}

const Register: React.FunctionComponent<RegisterPropsType> = (props:RegisterPropsType) => {
  const [formValues, setFormValues] = useState<PostData>({
      product_id: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      company_name: "",
      country: "",
      city: "",
      zipCode: "",
      siren_number: "",
      subject_to_VAT: true,
      intra_community_VAT_number: "",
      lastname: "",
      firstname: "",
      receive: false,
      message: "",
      successful: false,
  });
  
//searchParams.get("__firebase_request_key")
  const [ product, setProduct] = useState<ProductBackOptionType | ProductBackSubscriptionType | undefined>(undefined);
  const [ paying, setPaying] = useState<boolean>(false);

  const queryStringParams = queryString.parse(props.location.search)
  const [ errorMessage, setErrorMessage ] = useState<string|null>(null);
  const errorStr = "Une erreur est survenue en récupérant les informations du produit"

  useEffect(() => {
    let productId:string = ""

    if (!queryStringParams.productId)
    {
      // could redirect on an error page
      window.location.replace(baseRouteUrl + routes['packages'].path)
    }
    else
    {
      productId = queryStringParams.productId as string;
    }

    let interval:NodeJS.Timeout | undefined = undefined;
    const productResponse = productService.getProduct(productId)
    .then((response) => {
        if (response.status == 200)
        {
          if ((response.data.type !== "option") && (response.data.type !== "subscription"))
          {
            // could redirect on an error page
            window.location.replace(baseRouteUrl + routes['packages'].path)
          }

          Promise.resolve()
            .then(() => { setProduct(response.data); return response.data })
            .then((product) => {
              interval = setInterval(() => {
              productService.getProduct(productId).then((resp2) => {
                if (resp2.status == 200)
                {
                  if (product && !productService.equals(product, resp2.data))
                  {
                    window.location.reload();
                  }
                }
                else
                  window.location.reload();
              })
              .catch(() => {
                window.location.reload();
              })
            }, 5000) })
        }
        else
        {
          console.log(response)
          setErrorMessage(errorStr);
        }
      })
    .catch((err) => {
      console.log(err)
      setErrorMessage(errorStr);
    })
    return () => { interval && clearInterval(interval) };
  }, [])

  let productId:string = ""

  if (!queryStringParams.productId)
  {
    // could redirect on an error page
    window.location.replace(baseRouteUrl + routes['packages'].path)
  }
  else
  {
    productId = queryStringParams.productId as string;
  }

const formik = useFormik({
    initialValues: {
      product_id: productId,
      email: "",
      password: "",
      passwordConfirmation: "",
      company_name: "",
      country: "",
      city: "",
      zipCode: "",
      siren_number: "",
      subject_to_VAT: true,
      intra_community_VAT_number: "",
      lastname: "",
      firstname: "",
      receive: false,
      message: "",      
      successful: false,
    },
    validationSchema: validationSchema,
    
    onSubmit: (values) => {
      setPaying(true);
      const { product_id, email, password, company_name, country, city, zipCode, siren_number, subject_to_VAT, intra_community_VAT_number, lastname, firstname, receive } = values;
      setFormValues({
        ...formValues,
        message: "",
        successful: false,
      });
      AuthService.register(
        product_id,
        email, 
        password, 
        company_name, 
        country, 
        city, 
        zipCode,
        siren_number, 
        subject_to_VAT, 
        intra_community_VAT_number, 
        lastname, 
        firstname,
        receive
      ).then(
        response => {
          
          setFormValues({
            ...formValues,
            message: response.data.message,
            successful: true,
          });
          let queryString = "id="+response.data.stripePaymentIntentId+
                "&product_type="+response.data.productType+
                "&product_consultations="+response.data.productConsultations+
                "&product_name="+response.data.productName+
                "&product_color="+response.data.productColor.replace('#', '%23')+
                "&product_price="+response.data.productPrice;
          
          if (response.data.productPromo)
          {
            queryString = queryString + "&product_promo="+response.data.productPromo+
                "&product_promo_type="+response.data.productPromoType
          }
          let route = baseRouteUrl +"/payment?" + queryString
          window.location.href = route
        },
        
        error => {
          if (error.response === undefined){
            // cas rencontré lorsque le back ne tourne pas. 
            // 
            let resMessage;
            resMessage = `Erreur de connection au serveur.<br />Veuillez réessayer, si le problème persiste, vous pouvez contacter le support.`
            setFormValues({
              ...formValues,
              successful: false,
              message: resMessage,
            });
            return;

          }
          //console.log(error.response.data.toString())
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(resMessage)
          if (error.response.data.toString() === "Email already exists"){
            resMessage = `Cet email est déjà utilisé. Si c&rsquo;est votre compte, <a href=${baseRouteUrl + '/login'}>connectez-vous</a>.`
          }
          console.log(resMessage)
          setFormValues({
            ...formValues,
            successful: false,
            message: resMessage,
          });

          /*
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setFormValues({
            ...formValues,
            successful: false,
            message: resMessage,
          });*/

        }
      );      
    }
  });

  useEffect(() => {
    setPaying(false);
    if (formValues.message !== '')
    {
      scroller.scrollTo('ErrMessage', {
        duration: 250,
        smooth: 'easeInOutQuart',        
      })
    }
  }, [formValues.message])
  

  return (
    <div className="signup">
      <div className="step-image-container">
        <img alt="paiement step 1" src={PaymentStep1} />
      </div>
      <h1>Créez votre compte QOKKA</h1>
      <div className="splitter">
        <div className="chosen-formula">
          <h2>Votre formule choisie</h2>
          { errorMessage ? <div className="ErrMessage Mui-error">{errorMessage}</div> :
          !product ?  <div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div> :
            <OfferComponent offer={product}/>
          }
        </div>
        <div className="right-panel">
          <div className="form">
            <h2>Indiquez vos&nbsp;informations</h2>            
            <form onSubmit={(e) => {
                e.preventDefault()
                formValues.message = ''
                if (product)
                  formik.handleSubmit()
              }}>
              <Elt name="ErrMessage" className="anchor" />
              <p className="ErrMessage Mui-error" dangerouslySetInnerHTML={{__html: formValues.message }} />
              <ScrollToFieldError formik={formik}/>
              <QokkaTextInput name="email" label="Email" placeholder="ex&nbsp;: paul.dupont@mail.com" requiredDisplay={true} formik={formik} />
              <QokkaPasswordInput confirm={false} requiredDisplay={true} formik={formik}/>
              <QokkaPasswordInput confirm={true} requiredDisplay={true} formik={formik}/>
              <QokkaTextInput name="company_name" label="Nom de votre entreprise" placeholder="ex&nbsp;: qokka" requiredDisplay={true} formik={formik} />{/* <QokkaTextInput name="company_address" label="Adresse de votre entreprise" placeholder="ex&nbsp;: 3, rue des fleurs 75000 Paris FRANCE" requiredDisplay={true} formik={formik} multiline rows={3} />*/}
              
              <QokkaTextInput name="country" label="Pays de votre entreprise" placeholder="ex&nbsp;: France" requiredDisplay={true} formik={formik} />
              <QokkaTextInput name="city" label="Ville de votre entreprise" placeholder="ex&nbsp;: Paris" requiredDisplay={true} formik={formik}/>
              <QokkaTextInput name="zipCode" label="Code postal de votre entreprise" placeholder="ex&nbsp;: 75000" requiredDisplay={true} formik={formik} />
              
              <QokkaTextInput name="siren_number" label={(<>Numéro de SIREN <i>(optionnel)</i></>)} placeholder="ex&nbsp;: 362 521 879" requiredDisplay={false} formik={formik} />
              <QokkaCheckboxInput name="subject_to_VAT" label="Assujetti à la TVA" formik={formik} />
              <Collapse in={formik.values.subject_to_VAT}><QokkaTextInput name="intra_community_VAT_number" label="Numéro de TVA intracommunautaire" placeholder="ex&nbsp;: FR40123456824" requiredDisplay={false} formik={formik} /></Collapse>
              <QokkaTextInput name="lastname" label="Nom de l&rsquo;utilisateur" placeholder="ex&nbsp;: Dupont" requiredDisplay={true} formik={formik} />
              <QokkaTextInput name="firstname" label="Prénom de l&rsquo;utilisateur" placeholder="ex&nbsp;: Paul" requiredDisplay={true} formik={formik} />
              <QokkaCheckboxInput name="receive" label={
                  <div className="receive-label">
                    <span>Oui, je souhaite recevoir des informations de la part de Qokka. Pour en savoir plus sur nos politiques de confidentialité, consultez notre </span>
                    <Link to={'/' + i18n.language + '/cookies'}>déclaration</Link>
                    <span> en rapport avec les cookies.</span>
                  </div>
                } formik={formik} />
              <Button className="pay btn" color="primary" variant="contained" type="submit">{paying ? <><CircularProgress sx={{color: "rgb(96, 101, 113) !important"}} /><span style={{width:"10px"}} /></> : <span style={{width:"50px"}}></span>}Payer<span style={{width:"50px", height:"40px"}} /></Button>
            </form>          
          </div>
          <div className="already-an-account"><p>Vous avez déjà un compte&nbsp;?</p>
            <a href={baseRouteUrl + "/login"}>Connectez-vous</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
