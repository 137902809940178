export function formatSizeUnits(bytes: number){
  
    var result:string;
    if      (bytes >= 1073741824) { result = (bytes / 1073741824).toFixed(2).toString() + " GB"; }
    else if (bytes >= 1048576)    { result = (bytes / 1048576).toFixed(2).toString() + " MB"; }
    else if (bytes >= 1024)       { result = (bytes / 1024).toFixed(2).toString() + " KB"; }
    else if (bytes > 1)           { result = bytes.toString() + " bytes"; }
    else if (bytes === 1)          { result = bytes.toString() + " byte"; }
    else                          { result = "0 bytes"; }
  
    return result;

}
