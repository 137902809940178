import { PropaneSharp } from "@mui/icons-material";
import { Badge, Button, Card, Chip, Fab } from "@mui/material";
import { Component } from "react";
import UserService from "../services/user.service";
import ValidShield from '../icons/validShield.svg'

import AdvantageExp from '../icons/Bonhomme Experience.svg'
import AdvantageOperationel from '../icons/Bonhomme operationnel.svg'
import AdvantageInnovative from '../icons/Bonhomme Innovant.svg'
import AdvantageLegal from '../icons/Bonhomme reglementation.svg'
import AdvantageSafety from '../icons/Bonhomme securite.svg'

import LogoQOKKA from "../icons/logoQOKKA.svg"
import WhoImmotech from "../icons/who-immotech.svg"
import WhoAssurtech from "../icons/who-assurtech.svg"
import WhoHealthtech from "../icons/who-healthtech.svg"
import WhoFintech from "../icons/who-fintech.svg"

import Iso from "../icons/iso.svg"
import i18n from "../i18n";
import { KycQokkaPubComponent } from "./organisms/KYC_Qokka_pub";
import { WhyQokkaComponent } from "./organisms/WhyQokka";
import { WhoTile } from "./organisms/WhoTile"
import './1QokkaHomePromo.component.css';
import SosVictimesComponent from "./SosVictimes.component";

const baseRouteUrl = "/" + i18n.language;

interface AdvantageTileProps {
  image: React.ReactNode,
  title: string  
}

const AdvantageTile : React.FunctionComponent<AdvantageTileProps> = props =>
  <div className="advantage-tile">
    {props.image}
    <h1>{props.title}</h1>
    {props.children}
  </div>



interface IsoTileProps {
  index: number,
  isoNumber: string,
  title: string,
}

const IsoTile: React.FunctionComponent<IsoTileProps> = props =>
  <div className="iso-tile">
    <span className="iso-number" id={"iso-"+props.index}>{props.isoNumber}</span>
    <h2>{props.title}</h2>
    { props.children }
  </div>

type Props = {};

type State = {
  content: string;
}

export default class QokkaHomePromo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    return (
      <div className="home">
        <div className="blue-bg-block">
          <div className="try-curve">
                      <div className="btn-container">
              <div className="WhoIsWhoButtonStyle">
                <h2>&#202;tes&nbsp;vous&nbsp;s&ucirc;r de&nbsp;qui est&nbsp;qui&nbsp;&#63;</h2>
              </div>
          </div>
                    <h2 style={{paddingTop:"2rem", paddingBottom:"1rem"}}>Qokka r&eacute;pond à cette question en&nbsp;:</h2>
          <ul>
            <li style={{color:"white"}}>V&eacute;rifiant les documents d&rsquo;identit&eacute; (français et europ&eacute;ens)</li>
            <li style={{color:"white"}}>Qualifiant l&rsquo;image et son contenu</li>
            <li style={{color:"white"}}>V&eacute;rifiant la coh&eacute;rence des donn&eacute;es</li>
          </ul>
          <div className="btn-container">
            <Button variant="contained" className="btn" href={baseRouteUrl + "/packages"}>Essayez QOKKA</Button>
          </div>
          <SosVictimesComponent/>
            {/*<p>&nbsp;&nbsp;&nbsp;* Heureux comme un QOKKA</p>*/}
          </div>
        </div>
                <div id="hide-junction1"></div>
        <div className="qokka-bg-block">
          <div className="qokka-photos"></div>
          {/*<p>Tout ce dont <span className="medium">vous avez besoin pour maitriser</span> le risque de fraude en un point d&rsquo;entr&eacute;e unique&hellip;</p>*/}
          <p style={{paddingBottom:"3rem"}}><span className="medium">RESOCOM</span>, la référence en matière d'analyse du 
          risque et de la fraude identitaire auprès des établissements financiers, innove avec&nbsp; 
          <span className="medium">QOKKA</span> pour s'adapter à tous les secteurs de services, dont 
          l'assurance, la mutuelle, les institutions, le E-commerce, la santé, l'hôtellerie, le tourisme,
          les ressources humaines...
          </p>
        </div>
        <div className="discover-block">
          <h1>D&eacute;couvrez QOKKA</h1>
          {/***/}
          <p className="blue-medium">Simple et efficace&nbsp;!</p>          
          <div className="horizontal-block">
            <img alt="Image bouclier valide" src={ValidShield}></img>
            <p className="description"><span className="medium">En quelques secondes, QOKKA s&eacute;curise l&rsquo;entr&eacute;e en relation et assure la confiance</span></p>
          </div>
          {/*<p className="blue-bold-big">Une solution Multi-services cl&eacute; en main&hellip;</p>*/}
          <p className="blue-bold-big"></p>{/*Keeping the p tag with the class because*/}
          <Button variant="contained" className="btn" href={baseRouteUrl + "/packages"}>Testez QOKKA</Button>
        </div>
        <div id="hide-junction2"></div>
        <div className="advantages-block">
          <div className="advantages-curve2">
          <div className="advantages-curve">
          <AdvantageTile image={<img alt="Avantage expérience" src={AdvantageExp} />}
            title="Expérience"
            children={<p>QOKKA met à disposition toute l'expérience de RESOCOM. Cette expérience est le fruit de 25 ans de collaboration avec les établissements bancaires.</p>} />
          <AdvantageTile image={<img alt="Avantage expertise" src={AdvantageOperationel} />}
            title="Opérationnel"
            children={<p>QOKKA est une web application 100% française. QOKKA est disponible H24 et permet d'obtenir une instantanéité des résultats. QOKKA vérifie les documents (CNI, passeports et titres de séjour) français et européens.</p>}/>          
          <AdvantageTile image={<img alt="Avantage opérationnel" src={AdvantageSafety} />}
            title="Sécurité"
            children={<p>QOKKA sécurise l'entrée en relation et lutte contre la fraude (blanchiment d'argent, intrusion, travail illégal).</p>}/>   
          <AdvantageTile image={<img alt="Avantage légal" src={AdvantageLegal} />}
            title="Règlementaire"
            children={<p>QOKKA protège les données personnelles et est conforme aux dispositions RGPD et aux préconisations de la CNIL.</p>}/>   
          <AdvantageTile image={<img alt="Avantage innovant" src={AdvantageInnovative} />}
            title="Innovant"
            children={<p>QOKKA est une application Agile qui permet une performance évolutive permanente en temps réel.</p>}/>   
            </div>
          </div>
        </div>
        
        {/*<KycQokkaPubComponent button_title="Testez QOKKA" link_button="/packages"></KycQokkaPubComponent>*/}
        
        <WhyQokkaComponent>

          
        </WhyQokkaComponent>
        <div className="who-block">
          {/*
          <h1>QOKKA<br/>pour qui&nbsp;?</h1>
          <div className="who-tiles">
          <WhoTile image={<img alt="IMMOTECH" src={WhoImmotech}/>}
            title="IMMOTECH"
            points={[ <li>Conformit&eacute; des dossiers</li>,
            <li>Gain de temps</li>,
            <li>Base riche de documents frauduleux et signal&eacute;s</li>,
            <li>Baisse significative des dossiers d&rsquo;usurpation</li>,
             ]} />
          <WhoTile image={<img alt="ASSURTECH" src={WhoAssurtech}/>}
            title="ASSURTECH"
            points={[
              <li>Economies sur les indemnit&eacute;s</li>,
              <li>Normalisation du temps</li>,
              <li>Acc&eacute;l&eacute;ration des &eacute;tapes de v&eacute;rification</li>,
              <li>Gain de temps</li>]} />
          <WhoTile image={<img alt="HEALTHTECH" src={WhoHealthtech}/>}
            title="HEALTHTECH"
            points={[
              <li>Diminution des coûts</li>,
              <li>Gain de temps</li>,
              <li>Maitrise des risques</li>,
              <li>Politique sociale interne en coh&eacute;rence avec l&rsquo;activit&eacute;</li>,
              ]} />
          <WhoTile image={<img alt="FINTECH" src={WhoFintech}/>}
            title="FINTECH"
            points={[
              <li>Normalisation du processus</li>,
              <li>Meilleure image de marque</li>,
              <li>Responsabilit&eacute; soci&eacute;tale am&eacute;lior&eacute;e</li>,
              <li>Maitrise des risques financiers</li>,
              ]} />
          </div> */}
          <Button variant="contained" className="btn" href={baseRouteUrl + "/packages"}>Voir nos formules</Button>
        </div>
        <div className="iso-block">
          <img alt="ISO" src={Iso} />
          <h1 >QOKKA<br/>et les normes</h1>
          <div className="isoNumbers">
            <IsoTile isoNumber="ISO 31000" index={31000} title="Maitriser les risques">
              <p>QOKKA vous accompagne dans la maitrise des risques afin de cerner les opportunit&eacute;s et les menaces pour allouer et utiliser efficacement les ressources pour le management des risques.</p>
            </IsoTile>
            <IsoTile isoNumber="ISO 26000" index={26000} title="Responsabilité sociétale">
              <p>QOKKA vous accompagne dans votre engagement en faveur du d&eacute;veloppement durable. &Agrave; ce titre, QOKKA entre parfaitement dans le principe des actes concrets en faveur de votre responsabilit&eacute; soci&eacute;tale.</p>
            </IsoTile>
            <IsoTile isoNumber="ISO 19600-2014" index={19600} title="Gestion de la compliance">
              <p>QOKKA va parfaitement compl&eacute;ter votre système de management de la compliance pour le rendre efficace et r&eacute;actif dans le cadre de la maitrise des risques li&eacute;s aux relations d&rsquo;affaires.</p>
            </IsoTile>
          </div>
        </div>
      </div>
    );
  }
}
