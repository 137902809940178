import i18n from "../i18n";
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import AuthService from "../services/auth.service";
import QokkaPasswordInput from "./atoms/QokkaPasswordInput.component";
import { Button } from "@mui/material";
import QokkaTextInput from "./atoms/QokkaTextInput.component";
import "./24QokkaReinitialisation.component.css"


const baseRouteUrl = "/" + i18n.language;

const validationSchema = Yup.object().shape({

    email: Yup.string()
    .email("Cette adresse email n'est pas valide.")
    .required("Ce champ est requis !"),
  
  });

interface PostData {

    email:string,
    messageFromServer:string,
    successful:boolean

}

const GetMailAndChangePasswordComponent: React.FunctionComponent = () => {
    const [formValues, setChangePasswordComponentValues] = useState<PostData>({
        email:"", 
        messageFromServer:"",
        successful:false
    });
    const formik = useFormik({
        initialValues: {
            email:""
        },
        validationSchema: validationSchema,
        
        onSubmit: (values) => {
          const { email} = values;
          console.log(values)
          setChangePasswordComponentValues({
            ...formValues
          });
          AuthService.reinitialisationPassword(
            email
          ).then(
            response => {
              
                setChangePasswordComponentValues({
                ...formValues,
                messageFromServer: response.data.message,
                successful: true,
              });
              window.location.href = baseRouteUrl +"/new-password";
    //          window.location.href = baseRouteUrl +"/payment"; // après l'inscription, redirection vers le paiement.
            },
            
            error => {
              if (error.response === undefined){
                // cas rencontré lorsque le back ne tourne pas. 
                // 
                let resMessage;
                resMessage = "Erreur de connection au serveur.\n Veuillez réessayer, si le problème persiste, vous pouvez contacter le support."
                setChangePasswordComponentValues({
                  ...formValues,
                  successful: false,
                  messageFromServer: resMessage,
                });
                return;
    
              }
              //console.log(error.response.data.toString())
              let resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log(resMessage)
              if (error.response.data.toString() === "Email already exists"){
                resMessage = "Email already exists"
              }
              console.log(resMessage)
              setChangePasswordComponentValues({
                ...formValues,
                successful: false,
                messageFromServer: resMessage,
              });
            }
          );      
        }
      });

    return < div className="not_connected_change_password">

        <div className="background_div"> 
            <h2>Réinitialisez mon <br/>mot de passe </h2>
        </div>

        

        <div className="utils_desktop_mode">

        <h4 style={{color: "#0061d1", paddingLeft: "10%", paddingRight: "10%", textAlign: "center", marginBottom: "3rem"}}>Indiquez l&rsquo;email lié à votre compte QOKKA</h4>
        <div className="container_utils">

        <form onSubmit={formik.handleSubmit}>
        
            <QokkaTextInput name="email" label="Email" placeholder="ex&nbsp;: paul.dupont@mail.com" requiredDisplay={true} formik={formik} />
            <div style={{ height: "1.5rem" }}></div>
            {formValues.messageFromServer ?  <div className="error-message">{formValues.messageFromServer} </div> : null}
            <Button className="pay btn" color="primary" variant="contained" type="submit" style={{width: "100%"}}>Envoyer</Button>
            <div style={{ height: "1rem" }}></div>
        </form>
        
        <a href={baseRouteUrl+ "/my-account"} style={{ textDecoration: "underline"}}>Annuler</a>

        </div>

        </div>
        
    </div>
}

export default GetMailAndChangePasswordComponent;