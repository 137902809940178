
import React, { useEffect, useState } from "react";
import i18n from "../i18n";
import { donnees_bruts, exemple_list_from_server } from "../utils/constants";
import UserService from "../services/user.service";
import "./20QokkaFacturation.component.css"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import download_pdf_icon from "../icons/download_pdf.svg";
const baseRouteUrl = "/" + i18n.language;

const date = ""
//

export const HistoriqueFacturationComponent: React.FunctionComponent = () => {

    const [stateFetch1Light4, setStateFetch1Light4] = useState({
        user : {
            firstname: "err_connect",
            lastname: "err_connect",
            listInvoices: [{"date": "err_connect","status": "err_connect", "total": "err_connect", "formule": "err_connect", "pdf_link": "err_connect"}],
        }
    })

    useEffect(() => {

        UserService.fetch_1_light4().then((response: any) => {
            console.log("reponse fetch_1_light4", response)
            setStateFetch1Light4({
                user : {
                    firstname: response.data.user.firstname,
                    lastname: response.data.user.lastname,
                    listInvoices: response.data.user.listInvoices,
                }
            })
        }).catch(
            error => console.log(error)
        )


    },[])
    

    const [timeFilter, setTimeFilter] = React.useState('12');

    const handleTimeChange = (event: SelectChangeEvent) => {
        setTimeFilter(event.target.value as string);
        console.log("timeFilter", timeFilter)
      };

    return <div className="facturation">


        <h5 className="bonjour_user">Bonjour {stateFetch1Light4.user.firstname} {stateFetch1Light4.user.lastname}</h5>
        
        <div className="background_div"> 
        <h2>Historique de vos facturations</h2>
        </div>

        <div className="backgound_bleu_gradiant">
            <FormControl className="date_selector">
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timeFilter}
                    label="Age"
                    onChange={handleTimeChange}
                >
                    
                    <MenuItem value={24}>Les 24 derniers mois</MenuItem>
                    <MenuItem value={12}>Les 12 derniers mois</MenuItem>
                    <MenuItem value={6}>Les 6 derniers mois</MenuItem>
                    <MenuItem value={1}>Le dernier mois</MenuItem>
                    
                </Select>
            </FormControl>


            <div className="background_white" style={{marginTop:"4rem"}}>
                {/* TODO REMOVEDFORPROD 
                <p style={{padding: '1rem'}}>Page disponible bientôt. Merci de nous contacter pour obtenir vos factures en attendant.</p>
                */}
                
                {
                 // using number of months to filter the list
                    

                 stateFetch1Light4.user.listInvoices.map(el =>{ 
                    let date_of_today = new Date()

                    // Compare the date of the invoice with the date of today to get the number of milliseconds between the two
                    // depending on the number of months selected, we will filter the list of invoices
                    if (((date_of_today.getTime() - parseInt(el.date)*1000) > parseInt(timeFilter) * 30 *  24 * 60 * 60 * 1000)) {

                        return<>
                        {
                            /**
                             * 
                             * 
                             *  debug filtre code
                             today milliseconds: {date_of_today.getTime()} <br></br>
                        {date_of_today.getTime() - parseInt(el.date)*1000 } est plus grand que {parseInt(timeFilter) * 30 *  24 * 60 * 60 * 1000}
                        
                        <br></br>
                             */
                        }
                        

                        </>;
                    
                }


                    var myDate = new Date( parseInt(el.date) *1000); 
                    const local_date_string = myDate.toLocaleDateString("fr-FR") // conversion en date lisible
                    
                    return <div className="achat_individuel">

                        <div className="date">
                            
                        <p>
                            <span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}>Date</span>
                            
                            </p>
                            <p style={{color: "#0061d1"}}>{local_date_string}</p>
                        
                        </div>

                        <div className="formule">
                            
                        <p>
                            <span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}> Formule</span>
                        </p>
                        
                        {el.formule}
                        
                        {el.status === "paid" ? <p style={{backgroundColor: "#9af7d0", display: "inline-block"}}>&nbsp;Payé&nbsp;</p> : <p style={{backgroundColor: "#dadee8", display: "inline-block"}}>&nbsp;En attente&nbsp;</p>}
                        
                        </div>

                        <div className="montant">

                        <p>
                            <span style={{textTransform: "uppercase", color: "#8390b3" , fontWeight: "bold"}}>Montant</span>
                        </p>
                        
                        <p style={{fontWeight: "bold" }}>{parseInt(el.total)/100} &euro; TTC</p>
                        
                        </div>
                        
                        <img alt="img pdf button download" src={download_pdf_icon} onClick={() => { 

                            window.open(el.pdf_link , "_blank")

                        }}>

                        </img>
                        
                        
            
                    </div>
                    
                })}

            </div>

        </div>
    
    </div>
    
}