
import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import i18n from "../i18n";
import { donnees_bruts } from "../utils/constants";
import "./16QokkaConfirmResiliation.component.css"
import UserService from "../services/user.service";
import queryString from "query-string"
const baseRouteUrl = "/" + i18n.language;

type ConfirmResiliationComponentPropsType = {
  location: Location
}

export const ConfirmResiliationComponent: React.FunctionComponent<ConfirmResiliationComponentPropsType> = (props) => {
    const queryStringParams = queryString.parse(props.location.search)
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [fetchingData, setFetchingData] = useState({
        user : {
            firstname: "",
            lastname: "",
            forfait_actuel: "",
            credit_mensuel_du_forfait: 0,
            credit_utilises_du_mois: 0,
            Date_de_renouvellement: "",
            extra_credits_used: 0,
            extra_credits_available: 0,
            backgroundColor: "",
            Cancel_at_period_end: false,
        }
    })

    useEffect(()=>{
        UserService.fetch_1_light3().then((response: any) => {
            setFetchingData({
                user : {
                    firstname: response.data.user.firstname,
                    lastname: response.data.user.lastname,
                    forfait_actuel: response.data.user.forfait_actuel,
                    credit_mensuel_du_forfait: response.data.user.credit_mensuel_du_forfait,
                    credit_utilises_du_mois: response.data.user.credit_utilises_du_mois,
                    Date_de_renouvellement: response.data.user.Date_de_renouvellement,
                    extra_credits_used: response.data.user.extra_credits_used,
                    extra_credits_available: response.data.user.extra_credits_available,
                    backgroundColor: response.data.user.backgroundColor,
                    Cancel_at_period_end: response.data.user.Cancel_at_period_end,
                }
            })
            setLoading(false)
        }).catch(
            (error) => {
                console.log(error)
                setMessage(error.toString())
                setLoading(false)
            }
        )
    
    }
    ,
    [])

    if (loading)
    {
        return <div className="confirm_resiliation"><div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div></div>
    }
    if (message !== "")
    {
        return <div className="confirm_resiliation"><p className="ErrMessage Mui-error" dangerouslySetInnerHTML={{__html: message }} /></div>
    }

    let subscription = queryStringParams["subscription"] as string
    let planRemaining = fetchingData.user.credit_mensuel_du_forfait ? fetchingData.user.credit_mensuel_du_forfait - fetchingData.user.credit_utilises_du_mois : undefined
    let plan=<></>

    if (fetchingData.user.forfait_actuel)
    {
        if (planRemaining == 1)
        {
            plan = <div className="used_credit" style={{backgroundColor: fetchingData.user.backgroundColor}}>
                {planRemaining} consultation restante
            </div>
        }
        else
        {
            plan = <div className="used_credit" style={{backgroundColor: fetchingData.user.backgroundColor}}>
                {planRemaining} consultations restantes
            </div>
        }
    }
    else
    {
        plan = <div className="used_credit">
                Aucune consultation restante<p style={{margin: 0, fontWeight: 'normal', textTransform: "none"}}></p>
            </div>
    }

    return <div className="confirm_resiliation">
        <div className="background_div"> 
            <h1>Résilier mon abonnement</h1>
        </div>

        <div className="main_background">
            <h2 className="medium">Votre demande de résiliation du forfait</h2>
            <span className="subscription medium" style={{textTransform:"uppercase"}}>{subscription}</span>
            {plan}
            <p className="txt medium">a bien été prise en compte&nbsp;!</p>
            <p className="txt">Vous allez recevoir une confirmation par email.</p>
            { fetchingData.user.forfait_actuel ? <p className="txt">La résiliation sera effective le {fetchingData.user.Date_de_renouvellement}, vous pouvez utiliser votre solde avant cette date.</p> :
            <p className="txt">La résiliation est effective maintenant.</p> }
            
            <Button variant="contained" className="btn medium" href={baseRouteUrl + "/upload"}>Ajouter un document</Button>
        </div>
    </div>
}