
import i18n from "../i18n";
import * as Yup from "yup";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import QokkaTextInput from "./atoms/QokkaTextInput.component";
import QokkaCheckboxInput from "./atoms/QokkaCheckboxInput.component";
import UserService from "../services/user.service";
import { donnees_bruts } from "../utils/constants";

import "./18QokkaCorrigerCoordonnees.component.css";
import { Button, CircularProgress } from "@mui/material";

const baseRouteUrl = "/" + i18n.language;
const validationSchema = Yup.object().shape({
  /*
    email: Yup.string()
      .email("Cette adresse email n'est pas valide.")
      .required("Ce champ est requis !"),*/

    company_name: Yup.string().required("Ce champ est requis !"),
    
    country: Yup.string().required("Ce champ est requis !"),
    city: Yup.string().required("Ce champ est requis !"),
    zipCode: Yup.string().required("Ce champ est requis !"),
    subject_to_VAT: Yup.boolean().required("Ce champ est requis !"),
    //intra_community_VAT_number: Yup.string().required("Ce champ est requis !"),
    lastname: Yup.string().required("Ce champ est requis !"),
    firstname: Yup.string().required("Ce champ est requis !"),
  });

interface PostData {
    email: string,
    lastname: string,
    firstname: string,
    company_name: string,
    siren_number: string,
    subject_to_VAT: boolean,
    company_address: string,
    country: string,
    city: string,
    zipCode: string,
  }

export const EditProfileComponent : React.FC = () => {
  const [fetchingData, setFetchingData] = useState({
    user : {
        firstname: "err_connect",
        lastname: "err_connect",
        email: "err_connect",
        adresse: "err_connect",
        forfait_actuel: "err_connect",
        Ouverture_du_compte: "err_connect",
        Date_de_renouvellement: "err_connect",
        entreprise: "err_connect",
        siren_number: "err_connect",
        paiement_method: "err_connect",
        
        country: "err_connect",
        city: "err_connect",
        zipCode: "err_connect",
        subject_to_VAT: false,

    }
  })
  
  useLayoutEffect(()=>{

    UserService.fetch_1().then((response: any) => {
        console.log("reponse fetch1 EditProfileComponent", response)
        setFetchingData({
            user : {

                firstname: response.data.user.firstname,
                lastname: response.data.user.lastname,
                email: response.data.user.email,
                adresse: response.data.user.adresse,
                forfait_actuel: response.data.user.forfait_actuel,
                Ouverture_du_compte: response.data.user.Ouverture_du_compte,
                Date_de_renouvellement: response.data.user.Date_de_renouvellement,
                entreprise: response.data.user.entreprise,
                siren_number: response.data.user.siren_number,
                paiement_method: response.data.user.paiement_method,
                country: response.data.user.country,
                city: response.data.user.city,
                zipCode: response.data.user.zipCode,
                subject_to_VAT: response.data.user.subject_to_VAT,

            }
  
        })
  
    }).catch(

        error => console.log(error)

    )
  
    }
    ,
    [])
    
      return <>

      { fetchingData.user.firstname === "err_connect" ?  <div className="correct-coordonnees"><div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div></div> : // if there is not this comparison, the err_connect values are displayed.
      
      <UtilsEditProfileComponent 

      firstname={fetchingData.user.firstname}
      lastname={fetchingData.user.lastname}
      email={fetchingData.user.email}
      addresse={fetchingData.user.adresse}
      forfait_actuel={fetchingData.user.forfait_actuel}
      Ouverture_du_compte={fetchingData.user.Ouverture_du_compte}
      Date_de_renouvellement={fetchingData.user.Date_de_renouvellement}
      entreprise={fetchingData.user.entreprise}
      siren_number={fetchingData.user.siren_number}
      paiement_method={fetchingData.user.paiement_method}
      country={fetchingData.user.country}
      city={fetchingData.user.city}
      zipCode={fetchingData.user.zipCode}
      subject_to_VAT={fetchingData.user.subject_to_VAT}

      >

      </UtilsEditProfileComponent>
      }
      
      </>
}

type UtilsEditProfileComponentProps = {
  firstname: string,
  lastname: string,
  email: string,
  addresse: string,
  forfait_actuel:string,
  Ouverture_du_compte: string,
  Date_de_renouvellement: string,
  entreprise: string,
  siren_number: string,
  paiement_method: string,
  country: string,
  city: string,
  zipCode: string,
  subject_to_VAT: boolean,
}
  

const UtilsEditProfileComponent: React.FunctionComponent<UtilsEditProfileComponentProps> = (props: UtilsEditProfileComponentProps) => {

  const [fetchingData, setFetchingData] = useState({
    user : {
        firstname: props.firstname,
        lastname: props.lastname,
        email: props.email,
        adresse: props.addresse,
        forfait_actuel: props.forfait_actuel,
        Ouverture_du_compte: props.Ouverture_du_compte,
        Date_de_renouvellement: props.Date_de_renouvellement,
        entreprise: props.entreprise,
        siren_number: props.siren_number,
        paiement_method: props.paiement_method,
        country: props.country,
        city: props.city,
        zipCode: props.zipCode,
        subject_to_VAT: props.subject_to_VAT,

    }
  })

  useEffect(()=>{

  UserService.fetch_1().then((response: any) => {
      console.log("reponse fetch1", response)
      setFetchingData({
          user : {
              firstname: response.data.user.firstname,
              lastname: response.data.user.lastname,
              email: response.data.user.email,
              adresse: response.data.user.adresse,
              forfait_actuel: response.data.user.forfait_actuel,
              Ouverture_du_compte: response.data.user.Ouverture_du_compte,
              Date_de_renouvellement: response.data.user.Date_de_renouvellement,
              entreprise: response.data.user.entreprise,
              siren_number: response.data.user.siren_number,
              paiement_method: response.data.user.paiement_method,
              country: response.data.user.country,
              city: response.data.user.city,
              zipCode: response.data.user.zipCode,
              subject_to_VAT: response.data.user.subject_to_VAT,
          }

      })

  }).catch(
      error => console.log(error)
  )

  }
  ,
  [])

    const [formValues, setFormValues] = useState<PostData>({
        email: "",
        lastname: "",
        firstname: "",
        company_name: "",
        siren_number: "",
        subject_to_VAT: false,
        company_address: "",
        country:"",
        city:"",
        zipCode:"",
    });

    /*
    const formikRef = useRef(
      {
      email: fetchingData.user.email, // on initialise le formulaire avec les infos du user que l'on fetch en base
            lastname: fetchingData.user.lastname,
            firstname: fetchingData.user.firstname,
            company_name: fetchingData.user.entreprise,
            siren_number: fetchingData.user.siren_number,
            subject_to_VAT: donnees_bruts.user.TVA, 
            company_address: fetchingData.user.adresse
    });
    */

    const formik = useFormik({

        //innerRef: {formikRef},
        
        initialValues: {
            email: props.email, // on initialise le formulaire avec les infos du user que l'on fetch en base
            lastname: props.lastname,
            firstname: props.firstname,
            company_name: props.entreprise,
            siren_number: props.siren_number,
            subject_to_VAT: props.subject_to_VAT,  // boolean
            company_address: props.addresse,
            country: props.country,
            city: props.city,
            zipCode: props.zipCode,
        },
        
        validationSchema: validationSchema,
        
        onSubmit: (values) => {
          
          const { email, lastname, firstname, company_name, siren_number, subject_to_VAT, country, city, zipCode  } = values;
          console.log("submit here",values)
          setFormValues({
            ...formValues
          });
          UserService.modify_info(
            email, 
            lastname, 
            firstname, 
            company_name, 
            siren_number, 
            subject_to_VAT,
            country, 
            city, 
            zipCode,
            
          ).then(
            response => {
                
              setFormValues({
                ...formValues
              });


              window.alert("Vos coordonnées ont bien été modifiées.")
              window.location.href = baseRouteUrl + "/my-account";
            // window.location.href = baseRouteUrl + "/payment"; // après l'inscription, redirection vers le paiement.
            },
            
            error => {
              if (error.response === undefined){
                // cas rencontré lorsque le back ne tourne pas. 
                // 
                let resMessage;
                resMessage = "Erreur de connection au serveur.\n Veuillez réessayer, si le problème persiste, vous pouvez contacter le support."
                setFormValues({
                  ...formValues
                });
                return;
    
              }
              //console.log(error.response.data.toString())
              let resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log(resMessage)
              if (error.response.data.toString() === "Email already exists"){
                resMessage = "Email already exists"
              }
              console.log(resMessage)
              setFormValues({
                ...formValues
              });
    
            }
          );      
        }
      });

    return <div className="correct-coordonnees">

        <div className="background_div">

        <h1>Corriger mes<br/>coordonnées</h1>

        </div>

        <div className="utils_div">

        <h2 className="medium">Vos informations</h2>
        <div className="form">
          {/** <h2>Indiquez vos&nbsp;informations</h2>  */}
          
          <form onSubmit={formik.handleSubmit}>
            {/* <QokkaTextInput name="email" label="Email" placeholder="" requiredDisplay={true} formik={formik} /> */}
            
            <QokkaTextInput name="lastname" label="Nom de l&rsquo;utilisateur" placeholder="" requiredDisplay={true} formik={formik} />
            <QokkaTextInput name="firstname" label="Prénom de l&rsquo;utilisateur" placeholder="" requiredDisplay={true} formik={formik} />
            <QokkaTextInput name="company_name" label="Nom de votre entreprise" placeholder="" requiredDisplay={true} formik={formik} />
            <QokkaTextInput name="siren_number" label={(<>Numéro de SIREN <i>(optionnel)</i></>)} placeholder="" requiredDisplay={false} formik={formik} />
            <QokkaCheckboxInput name="subject_to_VAT" label="Assujetti à la TVA" formik={formik} />
            <QokkaTextInput name="country" label="Pays de votre entreprise" placeholder="ex&nbsp;: France" requiredDisplay={true} formik={formik} />
            <QokkaTextInput name="city" label="Ville de votre entreprise" placeholder="ex&nbsp;: Paris" requiredDisplay={true} formik={formik}/>
            <QokkaTextInput name="zipCode" label="Code postal de votre entreprise" placeholder="ex&nbsp;: 75000" requiredDisplay={true} formik={formik} />

            <Button className="modify btn medium" color="primary" variant="contained" type="submit">Modifier</Button>
            <a className="cancel medium" href={baseRouteUrl + "/my-account"}>Annuler</a> 
          </form>     

        </div>
       


      </div>
      
    </div>

}