
import { Button, CircularProgress } from "@mui/material";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import config from "../config/config";
import i18n from "../i18n";
import userService from "../services/user.service";
import { routes } from "../utils/routes";
import "./19QokkaModifierPaiement.component.css";

const baseRouteUrl = "/" + i18n.language;

const stripePubKey = config.StripePublicKey()
const stripePromise = loadStripe(stripePubKey);

type SetupFormPropsType = {
    setError: (error:string) => void
}

const SetupForm: React.FunctionComponent<SetupFormPropsType> = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event:any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        
        stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: window.location.origin + baseRouteUrl + routes['myAccount'].path,
            },
            })
            .then((result) => {
                if (result.error) {
                    result.error.message ? props.setError(result.error.message) : props.setError("Erreur "+ result.error.code)
                }
            });
    }

    return <form onSubmit={handleSubmit}>
        <PaymentElement /> 
        <Button className="modify btn medium" color="primary" variant="contained" type="submit">Modifier</Button>
        <a className="cancel medium" href={baseRouteUrl + "/my-account"}>Annuler</a> 
    </form>
}


export const ChangePaiementInfosComponent: React.FunctionComponent = () => {
    const [fetchingData, setFetchingData] = useState({
        clientSecretKey: "",
        message: "",
        loading: true
    })

    useEffect(()=>{
        userService.getSetupIntent().then((response: any) => {
            setFetchingData({
                    clientSecretKey: response.data.client_secret,
                    message: "",
                    loading: false
                })
        }).catch(
            (error) => {
                setFetchingData({
                    clientSecretKey: "",
                    message: error.toString(),
                    loading: false
                })
            }
        )    
    }, [])

    const setError = (error:string) => {
        setFetchingData({ ...fetchingData, message: error})
    }

    return <div className="change_payment_method">
      <div className="background_div">
        <h1>Modifier votre<br/>mode de paiement</h1>
      </div>
      <div className="utils_div">
        <h2 className="medium">Indiquez vos nouvelles coordonnées de paiement</h2>
        { fetchingData.loading && <div className="spinnerContainer"><CircularProgress sx={{width: "100px!important", height: "100px!important"}} /></div>}
        <p className="ErrMessage Mui-error" dangerouslySetInnerHTML={{__html: fetchingData.message }} />
        { (fetchingData.clientSecretKey !== "") && <div className="form">
            <Elements options={{clientSecret: fetchingData.clientSecretKey}} stripe={stripePromise}>
                <SetupForm setError={setError}/>                    
            </Elements>
        </div>}
      </div>
    </div>   
}
