export function docu_correspondance(document_type: string){

    let string_to_return = "";

    switch(document_type){
        case "id_document":
            string_to_return = "carte d'identité"
            break;

        case "passeport":
            string_to_return = "passeport"
            break;
        
        case "titre_de_sejour":
            string_to_return = "titre de sejour"
            break;

        default:
            console.log("probleme fonction docu_correspondance ")
        
    }
        
    return string_to_return;

}