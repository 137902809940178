import axios, { AxiosResponse } from "axios";
import { resolveTripleslashReference } from "typescript";
import config from "../config/config";
import authHeader, { QokkaCallGet, QokkaCallPost } from "./auth-header";

const API_URL = config.ApiUrl();

interface ProductBackType {
  id: string,
  name: string,
}

interface ProductBackOptionSubscriptionType extends ProductBackType
{
  consultations: number,
  color: {
    background: string,
    link: string,
    'link-hover': string,
    'gray-price': string
  },
  'description-txt1': string,
  'description-txt2'?: string,
  price: number,
  promo?: number,
  'promo-type'?: 'percent' | 'amount'
  'wave-position': "after-button" | "before-button" | "in-text",
  'wave-design': string,
  isCurrent: boolean
}

export interface ProductBackOptionType extends ProductBackOptionSubscriptionType
{
  type: "option"
}

export interface ProductBackSubscriptionType extends ProductBackOptionSubscriptionType
{
  type: "subscription"
}

export interface ProductBackExtraType extends ProductBackType
{
  type: "extra"
  consultations?: number,
  color: {
    background: string,
  },
  'description': string,
  price?: number,
}

class ProductService {

  payProduct(productId:string)
  {
    return QokkaCallPost(API_URL + '/pay/product', { productId: productId }, { headers: authHeader() })
  }

  payProductConfirm(orderId:string)
  {
    return QokkaCallPost(API_URL + '/pay/product_confirm', { orderId: orderId }, { headers: authHeader() })
  }

  getPaymentMethod()
  {
    return QokkaCallGet(API_URL + '/pay/payment_method', { headers: authHeader() })
  }

  getProducts(): Promise<AxiosResponse<{
    flowId: string,
    products: (ProductBackOptionType | ProductBackSubscriptionType | ProductBackExtraType)[]
  }>>
  {
    return QokkaCallGet(API_URL + '/product', { headers: authHeader() })
  }

  getPromo() {
    return QokkaCallGet(API_URL + '/product/promo', { headers: authHeader() })
  }

  getProduct(id:string): Promise<AxiosResponse<ProductBackOptionType | ProductBackSubscriptionType>>
  {
    return QokkaCallGet(API_URL + '/product/' + id, { headers: authHeader() })
  }

  equals: (left: ProductBackOptionType | ProductBackSubscriptionType | ProductBackExtraType, right: ProductBackOptionType | ProductBackSubscriptionType | ProductBackExtraType) => boolean =
  (left, right) =>
  {
    if (left.type == right.type)
    {
      if (left.type == 'extra' && right.type == 'extra')
      {
        if ((left.id == right.id) &&
            (left.name == right.name) &&
            (left.consultations == right.consultations) &&
            (left.color.background == right.color.background) &&
            (left.description == right.description) &&
            (left.price == right.price))
        {
          return true;
        }
      }
      else
      {
        let l = left as ProductBackOptionSubscriptionType;
        let r = right as ProductBackOptionSubscriptionType;

        if ((l.id == r.id) &&
              (l.name == r.name) &&          
              (l.consultations == r.consultations) &&
              (l.color.background == r.color.background) &&
              (l.color.link == r.color.link) &&
              (l.color["link-hover"] == r.color["link-hover"]) &&
              (l.color["gray-price"] == r.color["gray-price"]) &&
              (l["description-txt1"] == r["description-txt1"]) &&
              (l["description-txt2"] == r["description-txt2"]) &&
              (l.price == r.price) &&
              (l.promo == r.promo) &&
              (l["promo-type"] == r["promo-type"]))
        {
          return true;
        }
      }
    }
    return false;
  };

  arrayEquals: (left: (ProductBackOptionType | ProductBackSubscriptionType | ProductBackExtraType)[], right: (ProductBackOptionType | ProductBackSubscriptionType | ProductBackExtraType)[]) => boolean =
  (left, right) =>
  {
    if (left.length == right.length)
    {
      let i;
      for (i = 0; i < left.length; i++)
      {
        if (!this.equals(left[i], right[i]))
          return false;
      }
      return true;
    }
    return false;
  }
}

export default new ProductService();
