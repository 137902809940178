
import React from "react";
import i18n from "../i18n";
import { donnees_bruts } from "../utils/constants";

const baseRouteUrl = "/" + i18n.language;

export const MentionsLegales: React.FunctionComponent = () => {

    return < div className="cgu" style= {{ display: "flex", flexDirection: "column", alignItems: "center"}}>

    <div style= {{
    width: "70%",
    backgroundColor: "#e3f0ff", 
    borderRadius: "3rem",
    marginTop: "4rem", 
    marginBottom: "4rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    textAlign: "center",
    paddingTop: "3rem",
    paddingBottom: "3rem",
    display: "flex", 
    flexDirection: "column", 

    }}>
    

    <h1><span style={{fontWeight: "bold"}}>Mentions Légales du Logiciel QOKKA</span></h1>
    <div style= {{height: "1rem"}}></div>


    <p>Pour toute question merci de bien vouloir nous contacter.</p>
    <div style= {{height: "0.1rem", backgroundColor: "black", width : "100%"}}></div>
    <div style= {{height: "1rem"}}></div>

    
    

    <div style= {{height: "1rem"}}></div>
    <h4><span style={{fontWeight: "bold"}}>Présentation du site</span></h4>
    <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., 
        nous portons à la connaissance des utilisateurs et visiteurs du site : <a href="http://www.qokka.fr">www.qokka.fr</a> les informations suivantes :
    </p>

    <h4><span style={{fontWeight: "bold"}}>Informations légales </span></h4>
    <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        <p>Forme juridique : SAS</p>
        <p>Nom de la société: RESOCOM</p>
        <p>Capital: 100 000, 00 &euro;</p>
        <p>SIRET: 4249 3005 500 100</p>
        <p>Numéro de TVA intracommunautaire : FR75 424 930 055</p>
        <p>Siège social : 6 rue Neuve Saint-Pierre, 75004 Paris</p>
        <p>Tél. : +33 (0)1 79 35 04 15 </p>
        <p>Email : <a href="mailto:support@qokka.fr"> support@qokka.fr</a> </p>
        <p>Développement : Ce site a été réalisé par <a href="https://www.adcis.net">Adcis</a></p>
        <p>Directeur de publication : Marie Azevedo</p>
        <p>Le Site est hébergé par : SAFEO – 191-195 Avenue Charles de Gaulle 92200 Neuilly-sur-Seine – Téléphone : 01 84 20 41 28</p>
    </p>

    <h4><span style={{fontWeight: "bold"}}>Contenus du Site et responsabilité</span></h4>
    <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Les informations fournies sur ce site sont fournies à titre indicatif. Nous nous efforçons d’en contrôler l’exactitude, l’exhaustivité et l’actualisation. 
        Cependant, nous ne sommes pas en mesure d’en assurer la pleine garantie. Par conséquent, à l’exception d’une faute lourde ou intentionnelle, 
        Qokka/Resocom décline toute responsabilité pour tout dommage résultant notamment d’une imprécision ou d’une inexactitude de ces informations
        ou pour toute atteinte liée à une intrusion frauduleuse d’un tiers sur le site avec l’intention de nuire aux intérêts ou à l’image de la société. 
        La responsabilité de Qokka/Resocom  ne saurait être retenue en cas de dommage ou si un virus endommage ou rend inutilisable votre équipement 
        informatique à la suite de la visite de qokka.fr malgré les mesures de sécurité mises en œuvre.
    </p>

    <h4><span style={{fontWeight: "bold"}}>Propriété Intellectuelle </span></h4>
    <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>
        Toutes les images, marques, logos, noms de domaine et informations sous formes de textes ou 
        d’images présents sur le site sont la propriété de Resocom ou font l’objet d’une demande d’autorisation
        d’utilisation. Toute utilisation par reproduction, modification, téléchargement, transmission ou autre 
        procédé existant présent ou futur est soumise à autorisation écrite préalable. Seules les copies à usage
        privé et non-commercial sont autorisées sous réserve des droits de propriété intellectuelle dont il est fait mention. 
    </p>

    </div>
    
</div>
    
    
}
