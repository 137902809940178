export const TRANSLATIONS_FR = {
    menu_welcome:"La solution QOKKA",
    menu_products: "Nos formules",
    menu_contact_us: "Contactez-nous",
    menu_contact_us_connected: "Contact",
    menu_files: "Analyser un document",
    menu_admin: "Accès Admin",
    menu_user: "Utilisateur",
    menu_sign_up: "S'inscrire à Qokka",
    menu_modify_package: "Modifier mon forfait",
    menu_log_out: "Me déconnecter",
    menu_log_in: "Se connecter",
    menu_upload: "Ajouter un document",
    menu_history: "Mes consultations",
    menu_my_account: "Mon compte",
    menu_bill_history: "Mes factures",
    register_infos: "Indiquez vos informations",
    register_email: "Email",
    register_password: "Mot de passe",
    register_company_name: "Nom de votre entreprise",
    register_company_address: "Adresse de votre entreprise",
    register_siren_number: "Numéro de SIREN (optionnel)",
    register_subject_to_VAT: "Assujetti à la TVA",
    register_intra_community_VAT_number: "Numéro de TVA intracommunautaire",
    register_lastname: "Nom",
    register_firstname: "Prénom",
    register_receive1: "Oui, je souhaite recevoir des informations de la part de Qokka. Pour en savoir plus sur nos politiques de confidentialité, consultez notre ",
    register_receive2: "déclaration",
    register_receive3: " en rapport avec les cookies.",
    register_sign_up: "Payer"
   };