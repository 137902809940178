
import React from "react";
import i18n from "../i18n";
import { donnees_bruts } from "../utils/constants";
import './CGV.css';
const baseRouteUrl = "/" + i18n.language;

export const CGV: React.FunctionComponent = () => {

    return < div className="cgv" style= {{ display: "flex", flexDirection: "column", alignItems: "center"}}>

        <div className="cgvDiv">
        
        <h2><span style={{fontWeight: "bold"}}>Conditions Générales de vente</span></h2>

        <p>Applicables à compter du 21 octobre 2022</p>
        <div style= {{height: "0.1rem", backgroundColor: "black", width: "100%"}}></div>
        
        <div style= {{height: "1rem"}}></div>
        <h3><span style={{fontWeight: "bold", textDecoration: "underline"}}>Préambule</span></h3>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}>QOKKA est une marque et un site exploités par la société RESOCOM.</p>
        <p style= {{textAlign: "left"}}>RESOCOM est une société spécialisée dans la connaissance client ainsi que la lutte contre la fraude documentaire et l'usurpation d'identité.</p>
        <p style= {{fontWeight: "bold" ,textAlign: "left"}}>QOKKA est un terme faisant référence au Site QOKKA et que définit dans les CGU mais également à RESOCOM, société qui exploite ce Site et
        ce service.</p>
        <p style= {{textAlign: "left"}}>L'Utilisateur confirme avoir lu, compris et accepté les présentes CGV. L’utilisation
        du Site implique également l’acceptation des Conditions Générales d’Utilisation
        (CGU).</p>
        <p style= {{textAlign: "left"}}>Ces Conditions Générales de Vente prévalent sur les CGU. QOKKA se réserve le
        droit   de   modifier   ou   d’adapter   les   présentes   CGV   à   tout   moment,   en   notifiant
        toute nouvelle version à l’Utilisateur, qui s’engage à prendre connaissance de ces
        modifications.</p>


        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 1 — Objet et champ d'application</span></h4>
        <div style= {{height: "1rem"}}></div>
            <p style= {{textAlign: "left"}}>
            Ces Conditions Générales de Vente prévalent sur les CGU. QOKKA se réserve le
            droit   de   modifier   ou   d’adapter   les   présentes   CGV   à   tout   moment,   en   notifiant
            toute nouvelle version à l’Utilisateur, qui s’engage à prendre connaissance de ces
            modifications.
            </p>


        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 2 — Prix</span></h4>
        <div style= {{height: "1rem"}}></div>
 
        <p style= {{textAlign: "left"}}>
        Les prix des services fournis par RESOCOM grâce au Site  QOKKA sont ceux en
        vigueur au jour de la prise de commande. </p>
        <p style= {{textAlign: "left"}}>Ils sont libellés en euros.</p>
        <p style= {{textAlign: "left"}}>La société RESOCOM s'accorde le droit de modifier ses tarifs à tout moment pour
        l’utilisation   de   ses   services   à   travers  le   Site   QOKKA.  Toutefois,   elle   s'engage   à
        facturer les services commandés aux prix indiqués lors de l'enregistrement de la
        commande.
        </p>

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 3 — Consultations et Formules</span></h4>
        <div style= {{height: "1rem"}}></div>

        <p style= {{textAlign: "left"}}>La   Consultation   est   valable   pour   une   unique   utilisation   des   services   du   Site
QOKKA et pour un unique document d’identité.</p>
            
        <p style= {{textAlign: "left"}}>Elle permet d’obtenir de la part du Site QOKKA un unique Rapport édité à la date
de la commande. </p>

        <p style= {{textAlign: "left"}}> Les Formules ne sont accessibles qu’aux professionnels.</p>

        <p style= {{textAlign: "left"}}> L’abonnement   est   renouvelé   tacitement   chaque   mois   au   jour   correspondant   à
celui   de   la   commande.   (S’il   s’agit   du   31,   il   sera   renouvelé   au   dernier   jour   de
chaque mois et le paiement sera demandé dans les jours suivants)</p>

        <p style= {{textAlign: "left"}}>La   Formule   peut   être   résiliée   par   l’Utilisateur   à   tout   moment   à   condition   d’en
informer le Site QOKKA en se désinscrivant du service sur le Site ou sur le site
internet du prestataire de paiement. </p>
            
        <p style= {{textAlign: "left"}}> L’Utilisateur   qui   résilie   la   Formule   ne   pourra   plus   accéder   aux   services   de   la
formule   QOKKA   souscrite   pour   la   prochaine   mensualité   sauf   à   souscrire   à
nouveau   à   une   offre   payante   (Consultation   ou   nouvelle   Formule)   mais   pourra
toujours accéder à son espace personnel.</p>

        <p style= {{textAlign: "left"}}> Le prix payé pour un mois d’abonnement à la Formule est acquis définitivement à
RESOCOM même si une résiliation intervient pendant une période d’abonnement
et même si l’Utilisateur ne consomme pas les consultations commandées.</p>

        <p style= {{textAlign: "left"}}> Si l’Utilisateur n’utilise pas les consultations prévues dans sa formule, celles-ci ne
sont pas reportables sur une deuxième mensualité.</p>

<div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 4 — Modalités de paiement</span></h4>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}> Sauf   convention   particulière   souscrite   entre   l’Utilisateur   et   RESOCOM,   le
règlement   des   commandes   s'effectue   par   la   plateforme   de   paiement   du   site
QOKKA ou sur le site du prestaire de paiement de QOKKA.</p>

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 5 — Incident de paiement</span></h4>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}> Dans l’hypothèse où, pour n’importe quel motif, le paiement de la Formule n’a
        pas lieu à la date de la commande, l’abonnement sur le site QOKKA est résilié de
        plein   droit   et   les   services   compris   dans   la   formule   ne   sont   plus   accessibles
        l’Utilisateur.</p>


        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 6 — Force majeure</span></h4>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}> La responsabilité de la société RESOCOM ne pourra pas être mise en œuvre si la
        non-exécution ou le retard dans l'exécution de l'une de ses obligations décrites
        dans   les   présentes   conditions   générales   de   vente   découle   d'un   cas   de   force
        majeure.   À   ce   titre,   la   force   majeure   s'entend   de   tout   événement   extérieur,
        imprévisible et irrésistible au sens de l'article 1218 du Code civil.</p>


        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 7 — Responsabilités de RESOCOM</span></h4>
        <div style= {{height: "1rem"}}></div>

        <p style= {{textAlign: "left"}}> En   aucun   cas,   RESOCOM   ne   saurait   voir   sa   responsabilité   engagée   pour   une
        information qu’elle communique à un Utilisateur par le Site QOKKA.</p>
        <p style= {{textAlign: "left"}}> Aucune indemnité d’aucune sorte ne saurait être demandée à RESOCOM à raison
        des informations données à des Utilisateurs de QOKKA.</p>
        <p style= {{textAlign: "left"}}> Par ailleurs, QOKKA a été conçu pour répondre à une demande humaine et non
        algorithmique   ou   automatique.   Aucun   Utilisateur   utilisant   une   solution
        automatique ou technique ne pourrait reprocher à RESOCOM de manquer à une
        quelconque obligation contractuelle si elle ne répond pas à toutes les demandes.</p>

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 8 — Informations contenues dans le Rapport</span></h4>
        <div style= {{height: "1rem"}}></div>

        <p style= {{textAlign: "left"}}> QOKKA fait une analyse dite d’authenticité et dite de conformité. QOKKA permet
        une   vérification   automatique   de   la   cohérence   de   données   de   documents d’identité   munis   d’une   zone   de   lecture   optique   (bande   MRZ)   numérisés.   Une
        interrogation   de   bases   de   données   est   effectuée   automatiquement   à   chaque
        saisie.
        </p>
        <p style= {{textAlign: "left"}}> 
        Les   contrôles   effectués   sont   complémentaires   à   un   contrôle   des
        sécurités physiques du document.
        </p>

        <p style= {{textAlign: "left"}}> 
        Les fonctionnalités de QOKKA permettent&nbsp;
        </p>
        <ul style= {{textAlign: "left"}}>
            <li>Un   contrôle   automatique   de   cohérence   d’informations   présentes   sur   des
            documents   d'identité   comportant   une   zone   de   lecture   optique   (bande   MRZ)
            respectant la norme de l’Organisation de l’Aviation Civile Internationale (OACI) et
            les normes européennes&nbsp;:</li>
            <ul className='ul1'>
                <li>Cartes nationales d’identité Françaises et européennes (UE)</li>
                <li>Titres de séjours Français</li>
                <li>Passeports Français et Etrangers</li>
                <li>Interrogation des services RESOCOM pour les documents:</li>
                    <ul className='ul1bis'>
                        <li>Français&nbsp;:   numéros   de   documents   (passeports,
                        titres   de   séjour)   vierges   volés,   tels   que
                        communiqués à RESOCOM</li>
                        <li>Autres   pays&nbsp;:   numéros   de   documents   déclarés
                        volés   /   perdus,   tels   que   communiqués   à
                        RESOCOM</li>
                        <li>Base   RESOCOM   des   documents   alertés,
                        constituée par ResoCom</li>
                        <li>Possibilité de traitement d’images de documents
                        originaux   ou   photocopiés,   recto   seul   ou   recto-
                        verso.</li>
                    </ul>
            </ul>
            <li>Une édition de RAPPORTS reprenant le résultat des différents contrôles et interrogations réalisés.</li>
        </ul>


        
        <p style= {{textAlign: "left"}}> 
        Ces fonctionnalités permettent d’exploiter des documents parfaitement lisibles et
        d’une qualité suffisante. RESOCOM n’est pas responsable si un document n’est
        pas analysé faute d’une qualité suffisante.</p>
        <p style= {{textAlign: "left"}}> 
        RESOCOM est une société possédant des informations sur un grand nombre de
        documents. L’Utilisateur ne peut reprocher à RESOCOM qui utilise le site QOKKA
        de   ne   fournir   aucune   information   ou   trop   peu   d’informations   après   une
        consultation d’un document d’identité.</p>
        <p style= {{ textAlign: "left"}}> 
        En effet, RESOCOM s’engage uniquement à fournir à l’Utilisateur les informations
        qu’elle détient dans ses bases de données en éditant le Rapport.</p>
        <p style= {{ textAlign: "left"}}> 
        Le Rapport n’a qu’une valeur indicative et non certaine. Le Rapport n’est que la
        transcription des informations que RESOCOM détient dans ses bases de données.</p>
        <p style= {{ textAlign: "left"}}> 
        RESOCOM  n’est  en   aucun   cas   responsable   des   informations  contenues   dans   le
        Rapport.</p>
        <p style= {{textAlign: "left"}}> 
        RESOCOM n’est pas garante de la véracité des informations contenues dans le
        Rapport et il ne peut lui être reproché de transmettre une information imparfaite
        ou fausse.</p>
        <p style= {{textAlign: "left"}}> 
        RESOCOM   ne   peut   être   tenue   responsable   de   l’usage   que   l’Utilisateur   fait   du
        Rapport.</p>

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 9 — Prérequis des supports analysés</span></h4>
        <div style= {{height: "1rem"}}></div>
        <p style= {{textAlign: "left"}}> 
        Pour   un   fonctionnement   normal   de   QOKKA,   les   documents   analysés   doivent
        remplir les conditions minimales suivantes&nbsp;:</p>
        <p style= {{textAlign: "left"}}> 
        Pour   un   fonctionnement   normal   de   QOKKA,   les   documents   analysés   doivent
        remplir les conditions minimales suivantes&nbsp;:</p>
        <p style= {{fontWeight:"bold", textAlign: "left", textDecoration:"underline"}}> 
        L'image peut provenir de&nbsp;:</p>               
        <ul style= {{textAlign: "left"}}>
            <li>d’un scanner à plat en lumière blanche (pour les scanners traitant la bande
            InfraRouge et/ou UltraViolet, nous consulter)</li>
            <li>d’une photo prise par un Smartphone ou une Tablette.</li>
        </ul>
        <p style= {{textAlign: "left"}}> 
        Mais dans tous les cas un certain nombre de prérequis sont à respecter.</p> 
        <p style= {{textAlign: "left"}}> 
        Le scanner peut-être indépendant ou piloté par l’application. Dans ce dernier cas,
        il convient&nbsp;:</p> 
        <ul style= {{textAlign: "left"}}>
                <li>de disposer d’un pilote TWAIN 2.0</li>
                <li>de disposer d’une Machine Java Virtuelle à jour</li>
                <li>d’accepter les applets Java</li>
        </ul>            
    
        <p style= {{fontWeight:"bold", textAlign: "left", textDecoration:"underline"}}> 
        PREREQUIS POUR UN SCAN A PLAT</p>
        <ul style= {{textAlign: "left"}}>
            <li>Image acquise directement à partir du document original,</li>
            <li>Un seul document par image (recto verso accepté ),</li>
            <li>Document non tronqué ou masqué (doigt, règle...),</li>
            <li>Document scanné à plat (les faibles déformations sont redressées),</li>
            <li>Image en couleur (minimum&nbsp;: 256 niveaux de gris),</li>
            <li>Densité de scan&nbsp;: 300 ppp recommandé (minimum&nbsp;: 200, maximum&nbsp;: 600) ,</li>
            <li>Caractères (chiffres et lettres) sur l’image non retraités par un OCR ,</li>
            <li>Pas de compression destructive (ou au minimum),</li>
            <li>Ne pas utiliser de filigrane lors du scan,</li>
            <li>Format de fichier&nbsp;: pdf , png, jpeg ou tiff,</li>
            <li>Éclairage   uniforme   sur   l’image   (capot   de   l’imprimante   ou   du   scanner
            fermé),</li>
            <li>Image sans surexposition ni sous exposition.</li>
        </ul>
        <p style= {{fontWeight:"bold", textAlign: "left", textDecoration:"underline"}}> 
        PREREQUIS POUR UNE PHOTO</p>
            <ul style= {{textAlign: "left"}}>
                <li>Image acquise directement à partir du document original,</li>
                <li>Un seul document par image,</li>
                <li>Document non tronqué ou masqué (doigt, règle...),</li>
                <li>Document à plat (sans déformation courbe prononcée),</li>
                <li>Image en couleur,</li>
                <li>Capteur de 6 Mpx ou plus,</li>
                <li>Dimensions à approcher pour correspondre aux 300 ppp&nbsp;:</li>
                    <ul className='ul2'>
                        <li>Pour le format carte de crédit&nbsp;: 1&nbsp;011 pixels x 638 pixels</li>
                        <li>Pour le format A7 (CNI française par exemple)&nbsp;: 1&nbsp;240 pixels x 874 pixels</li>
                        <li>Pour le format B7 (passeport par exemple)&nbsp;: 1&nbsp;475 pixels x 1&nbsp;039 pixels</li>
                    </ul>

                <li>Autofocus</li>
                <li>Pas de compression destructive (ou au minimum),</li>
                <li>Format de fichier: pdf, png, jpeg ou tiff,</li>
                <li>Éclairage uniforme sur l’image (flash désactivé, reflet sur les supports
                plastiques),</li>
                <li>Image sans surexposition ni sous exposition (contraste normal).</li>
            </ul>

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 10 — Assistance&nbsp;:</span></h4>
        <div style= {{height: "1rem"}}></div>

        <p style= {{textAlign: "left"}}> 
        RESOCOM s’engage à mettre en œuvre tous les moyens permettant à
        l’Utilisateur un accès fiable et rapide au Site.</p> 

        <p style= {{textAlign: "left"}}> 
        Un   service   d’assistance,   limité   à   l’utilisation   du   Site   dans   le   cadre   d’un   accès
        payant   (Consultation   ou   Formule),   est   disponible   par   courriel   à   l’adresse
        suivante&nbsp;: support@qokka.com.</p> 

        <div style= {{height: "1rem"}}></div>
        <h4><span style={{fontWeight: "bold"}}>Article 11 — Compétence légale et juridictionnele</span></h4>
        <div style= {{height: "1rem"}}></div>

        <p style= {{textAlign: "left"}}> 
        Tout   litige   relatif   à   l'interprétation   et   à   l'exécution   des   présentes   conditions
        générales de vente est soumis au droit français.</p> 
        <p style= {{textAlign: "left"}}> 
        À défaut de résolution amiable, le litige sera porté devant le Tribunal de Paris.</p> 
                    
        <div style= {{height: "2rem"}}></div>
        <div style= {{height: "0.1rem", backgroundColor: "black", width: "100%"}}></div>
            <div style= {{height: "1rem"}}></div>
            <p>QOKKA – support@qokka.fr – 6 rue Neuve Saint-Pierre 75004 Paris – France</p>
            <p>Siret&nbsp; 42493005500100 – Naf. 5829C – RCS MARSEILLE RESOCOM SAS au capital de 100’000 €</p>
            <p>N°TVA intracommunautaire&nbsp; FR75424930055</p>
        </div>
        
       
        
    </div>
    
}